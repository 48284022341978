.cs_baner_kramp {
  background-image: url("./img/baner.png");
  background-position: center;
  width: 100%;
  min-height: calc(100vh - 125px);
  display: flex;
  background-size: cover;
}
@media screen and (max-width: 600px) {
  .cs_baner_kramp {
    background-image: url("./img/kramp_mobile.jpg");
    min-height: 82vh;
  }
}
.cs_baner_kramp .cs_baner_container {
  display: flex;
  width: 100%;
  max-width: 1150px;
  margin: auto;
  flex-direction: column;
  align-items: center;
  color: #fff;
  text-align: center;
}
@media screen and (max-width: 600px) {
  .cs_baner_kramp .cs_baner_container {
    padding: 25px;
    margin: 0;
    justify-content: flex-end;
    align-items: flex-start;
  }
}
@media screen and (max-width: 600px) {
  .cs_baner_kramp .cs_baner_container {
    padding: 25px 25px 50px 25px;
    margin: 0;
    justify-content: flex-end;
    align-items: flex-start;
  }
}
@media screen and (max-width: 1400px) {
  .cs_baner_kramp .cs_baner_container img {
    width: 100%;
    max-width: 400px;
  }
}
@media screen and (max-width: 600px) {
  .cs_baner_kramp .cs_baner_container img {
    max-width: 65vw;
  }
}
.cs_baner_kramp .cs_baner_container h2 {
  font-size: 85px;
  margin: 50px 0 0 0;
  text-transform: uppercase;
  line-height: 1.16;
}
@media screen and (max-width: 1400px) {
  .cs_baner_kramp .cs_baner_container h2 {
    font-size: clamp(55px, 8vw, 70px);
  }
}
@media screen and (max-width: 600px) {
  .cs_baner_kramp .cs_baner_container h2 {
    text-align: left;
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: clamp(50px, 8vw, 70px);
  }
}
.cs_baner_kramp .cs_baner_container h5 {
  font-size: 31px;
  margin: 50px 0 0 0;
  font-weight: 400;
}
@media screen and (max-width: 1400px) {
  .cs_baner_kramp .cs_baner_container h5 {
    font-size: clamp(25px, 3vw, 31px);
  }
}
@media screen and (max-width: 600px) {
  .cs_baner_kramp .cs_baner_container h5 {
    margin-top: 0px;
    font-size: clamp(19px, 3vw, 31px);
  }
}

.cs_black_space {
  background-color: black;
  height: 56px;
  width: 100%;
}

.cs_container_1150_kramp {
  width: 100%;
  max-width: 1150px;
  margin: 0 auto;
  padding: 50px 0;
}
@media screen and (max-width: 1366px) {
  .cs_container_1150_kramp {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.cs_container_1150_kramp .challenge {
  margin-top: 50px;
}
@media screen and (max-width: 600px) {
  .cs_container_1150_kramp .challenge {
    margin-top: 0px;
  }
}
.cs_container_1150_kramp .challenge h2.title {
  font-size: 90px;
  margin: 0;
  margin-top: 20px;
}
@media screen and (max-width: 850px) {
  .cs_container_1150_kramp .challenge h2.title {
    text-align: left;
    font-size: 60px;
  }
}
.cs_container_1150_kramp .challenge .cs_poranek_row {
  display: grid;
  grid-template-columns: 600px 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: ". .";
  gap: 50px;
  position: relative;
  align-items: start;
  justify-items: end;
}
@media screen and (max-width: 1170px) {
  .cs_container_1150_kramp .challenge .cs_poranek_row {
    grid-template-columns: 1fr;
    grid-template-areas: ".";
    justify-items: center;
  }
}
.cs_container_1150_kramp .challenge .cs_poranek_row .cs_bullets {
  display: flex;
  gap: 50px;
  width: 100%;
  flex-direction: column;
}
@media screen and (max-width: 1110px) {
  .cs_container_1150_kramp .challenge .cs_poranek_row .cs_bullets {
    flex-direction: column;
  }
}
@media screen and (max-width: 600px) {
  .cs_container_1150_kramp .challenge .cs_poranek_row .cs_bullets {
    gap: 20px;
  }
}
.cs_container_1150_kramp .challenge .cs_poranek_row .cs_bullets .cs_bullets_box {
  padding: 40px 40px 40px 52px;
  border-radius: 25px;
  box-shadow: inset 10px 10px 18px rgba(0, 0, 0, 0.24);
  position: relative;
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
}
@media screen and (max-width: 600px) {
  .cs_container_1150_kramp .challenge .cs_poranek_row .cs_bullets .cs_bullets_box {
    padding: 25px 25px 25px 33px;
    font-size: 15px;
    line-height: 1.4;
  }
  .cs_container_1150_kramp .challenge .cs_poranek_row .cs_bullets .cs_bullets_box br {
    display: none;
  }
}
.cs_container_1150_kramp .challenge .cs_poranek_row .cs_bullets .cs_bullets_box img {
  position: absolute;
  left: -21px;
  right: auto;
  margin: auto;
  top: 0;
  bottom: 0;
  max-width: 45px;
}
.cs_container_1150_kramp .challenge .cs_poranek_row .cs_poranki_img {
  display: flex;
  gap: 10px;
  position: relative;
}
@media screen and (max-width: 1170px) {
  .cs_container_1150_kramp .challenge .cs_poranek_row .cs_poranki_img {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.cs_container_1150_kramp .challenge .cs_poranek_row .cs_poranki_img img {
  width: 100%;
  height: auto;
  max-width: 317px;
}
@media screen and (max-width: 1666px) {
  .cs_container_1150_kramp .challenge .cs_poranek_row .cs_poranki_img img {
    max-width: 270px;
  }
}
@media screen and (max-width: 1422px) {
  .cs_container_1150_kramp .challenge .cs_poranek_row .cs_poranki_img img {
    max-width: 250px;
  }
}
@media screen and (max-width: 1322px) {
  .cs_container_1150_kramp .challenge .cs_poranek_row .cs_poranki_img img {
    max-width: 200px;
  }
}
@media screen and (max-width: 600px) {
  .cs_container_1150_kramp .challenge .cs_poranek_row .cs_poranki_img img {
    width: 100%;
    max-width: 309px;
  }
}
.cs_container_1150_kramp .realization h2.title {
  font-size: 90px;
  color: #990000;
  padding-top: 0;
}
@media screen and (max-width: 850px) {
  .cs_container_1150_kramp .realization h2.title {
    text-align: left;
    font-size: 60px;
  }
}
.cs_container_1150_kramp .realization .realization-points {
  display: flex;
  gap: 50px;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
}
@media screen and (max-width: 600px) {
  .cs_container_1150_kramp .realization .realization-points {
    gap: 30px;
  }
}
.cs_container_1150_kramp .realization .realization-points .simple-flex {
  display: flex;
  flex-direction: column;
  gap: 50px;
}
@media screen and (max-width: 600px) {
  .cs_container_1150_kramp .realization .realization-points .simple-flex {
    gap: 30px;
  }
}
.cs_container_1150_kramp .realization .realization-points .baner_event img {
  width: 100%;
}
.cs_container_1150_kramp .realization .realization-points .points_column {
  display: flex;
  gap: 35px;
  flex-direction: column;
}
.cs_container_1150_kramp .realization .realization-points .points_column .point {
  display: flex;
  gap: 20px;
  align-items: flex-start;
}
@media screen and (max-width: 600px) {
  .cs_container_1150_kramp .realization .realization-points .points_column .point {
    gap: 5px;
    font-size: 14px;
  }
}
@media screen and (max-width: 600px) {
  .cs_container_1150_kramp .realization .realization-points .points_column .point .point-bullet {
    width: 52px;
    position: relative;
    left: -3px;
  }
}
.cs_container_1150_kramp .realization .realization-points .points_column .point .points-rows {
  display: flex;
  flex-direction: column;
}
.cs_container_1150_kramp .realization .realization-points .points_column .point .points-rows .hero {
  font-size: 20px;
  padding: 20px;
}
@media screen and (max-width: 600px) {
  .cs_container_1150_kramp .realization .realization-points .points_column .point .points-rows .hero {
    padding: 0px;
  }
}
.cs_container_1150_kramp .realization .realization-points .points_column .point .realization-points-points {
  display: flex;
  flex-direction: column;
  position: relative;
}
.cs_container_1150_kramp .realization .realization-points .points_column .point .realization-points-points .subpoints {
  display: flex;
  flex-direction: column;
  padding: 20px;
}
@media screen and (max-width: 600px) {
  .cs_container_1150_kramp .realization .realization-points .points_column .point .realization-points-points .subpoints {
    padding: 10px 0px;
  }
}
.cs_container_1150_kramp .realization .realization-points .points_column .point .realization-points-points .subpoints p {
  padding: 0;
  margin: 0 0 20px 0;
}
.cs_container_1150_kramp .realization .realization-points .points_column .point .cs_points_box {
  padding: 40px;
  border-radius: 25px;
  box-shadow: inset 10px 10px 18px rgba(0, 0, 0, 0.24);
  position: relative;
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  margin-top: 50px;
  display: flex;
  gap: 20px;
  flex-direction: column;
}
@media screen and (max-width: 850px) {
  .cs_container_1150_kramp .realization .realization-points .points_column .point .cs_points_box {
    gap: 30px;
  }
}
@media screen and (max-width: 550px) {
  .cs_container_1150_kramp .realization .realization-points .points_column .point .cs_points_box {
    padding: 0px;
    border-radius: 0px;
    box-shadow: none;
  }
}
.cs_container_1150_kramp .realization .realization-points .points_column .point .cs_points_box .num-point {
  display: flex;
  gap: 25px;
  align-items: flex-end;
}
@media screen and (max-width: 850px) {
  .cs_container_1150_kramp .realization .realization-points .points_column .point .cs_points_box .num-point {
    align-items: center;
  }
}
@media screen and (max-width: 550px) {
  .cs_container_1150_kramp .realization .realization-points .points_column .point .cs_points_box .num-point {
    align-items: flex-start;
  }
}
.cs_container_1150_kramp .realization .realization-points .points_column .point .cs_points_box .num-point .num-box {
  font-family: "Antonio", sans-serif;
  font-weight: bold;
  font-style: normal;
  font-size: 40px;
}
.cs_container_1150_kramp .realization .realization-points .points_column .point .cs_points_box .num-point .text-box {
  font-size: 20px;
  font-weight: normal;
}
@media screen and (max-width: 600px) {
  .cs_container_1150_kramp .realization .realization-points .points_column .point .cs_points_box .num-point .text-box {
    font-size: 16px;
  }
}
.cs_container_1150_kramp .realization .realization-baner {
  margin-top: 100px;
  margin-bottom: 75px;
  display: flex;
  justify-content: center;
}
.cs_container_1150_kramp .realization .realization-baner img {
  width: 100%;
  height: auto;
  max-width: 1150px;
}/*# sourceMappingURL=styles.css.map */