.cs_baner_tarczynski {
  background-image: url("./img/baner.png");
  background-position: center;
  width: 100%;
  min-height: calc(100vh - 125px);
  display: flex;
  background-size: cover;
}
@media screen and (max-width: 1100px) {
  .cs_baner_tarczynski {
    background-position: 24% !important;
  }
}
@media screen and (max-width: 1000px) {
  .cs_baner_tarczynski {
    background-position: 36% !important;
  }
}
@media screen and (max-width: 860px) {
  .cs_baner_tarczynski {
    background-position: 40% !important;
  }
}
@media screen and (max-width: 800px) {
  .cs_baner_tarczynski {
    background-image: url("./img/baner_mobile_2.jpg");
  }
}
@media screen and (max-width: 800px) {
  .cs_baner_tarczynski {
    background-position: 0px -57px !important;
    min-height: 82vh;
  }
}
@media screen and (max-width: 600px) {
  .cs_baner_tarczynski {
    background-position: top !important;
  }
}
.cs_baner_tarczynski img {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto 0 23.3vw;
  z-index: 1;
}
@media screen and (max-width: 1400px) {
  .cs_baner_tarczynski img {
    margin: 0 auto;
  }
}
@media screen and (max-width: 800px) {
  .cs_baner_tarczynski img {
    position: absolute;
    top: 0;
    margin: 0;
    left: 25px;
    right: 0;
    max-width: 120px;
  }
}
.cs_baner_tarczynski .cs_baner_container {
  display: flex;
  width: 100%;
  max-width: 1150px;
  margin: 0 auto;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
@media screen and (max-width: 1400px) {
  .cs_baner_tarczynski .cs_baner_container {
    width: 100%;
    padding: 0px 25px 0 25px;
  }
}
@media screen and (max-width: 800px) {
  .cs_baner_tarczynski .cs_baner_container {
    margin: 0 auto;
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 25px 15px 25px !important;
    display: flex;
    gap: 10px;
  }
}
.cs_baner_tarczynski .cs_baner_container h2 {
  font-size: 75px;
  text-transform: uppercase;
  line-height: 1.18;
  margin: 0 0 30px 0;
}
@media screen and (max-width: 800px) {
  .cs_baner_tarczynski .cs_baner_container h2 {
    font-size: clamp(45px, 5vw, 63px);
    color: #0C2C65;
  }
}
.cs_baner_tarczynski .cs_baner_container h5 {
  font-size: 27px;
  margin: 18px 0 0 0;
  font-weight: 500;
  background-color: #fff;
  padding: 5px 20px;
}
@media screen and (max-width: 800px) {
  .cs_baner_tarczynski .cs_baner_container h5 {
    font-size: clamp(15px, 3vw, 27px);
  }
}

.cs_black_space {
  background-color: black;
  height: 56px;
  width: 100%;
}

.cs_container_1150_tarczynski {
  width: 100%;
  max-width: 1150px;
  margin: 0 auto;
  padding: 50px 0;
}
@media screen and (max-width: 1366px) {
  .cs_container_1150_tarczynski {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.cs_container_1150_tarczynski .challenge h2.title {
  font-size: 90px;
  margin: 20px 0 50px 0;
  padding: 0;
}
@media screen and (max-width: 850px) {
  .cs_container_1150_tarczynski .challenge h2.title {
    text-align: left;
    font-size: 60px;
  }
}
.cs_container_1150_tarczynski .challenge .cs_poranek_row {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: ".";
  gap: 50px;
  position: relative;
  align-items: start;
  max-width: 70%;
  padding-left: 20px;
}
@media screen and (max-width: 850px) {
  .cs_container_1150_tarczynski .challenge .cs_poranek_row {
    max-width: unset;
  }
}
.cs_container_1150_tarczynski .challenge .cs_poranek_row .cs_bullets {
  display: flex;
  gap: 50px;
  width: 100%;
}
@media screen and (max-width: 1110px) {
  .cs_container_1150_tarczynski .challenge .cs_poranek_row .cs_bullets {
    flex-direction: column;
  }
}
@media screen and (max-width: 600px) {
  .cs_container_1150_tarczynski .challenge .cs_poranek_row .cs_bullets {
    gap: 20px;
  }
}
.cs_container_1150_tarczynski .challenge .cs_poranek_row .cs_bullets .cs_bullets_box {
  padding: 40px 40px 40px 52px;
  border-radius: 25px;
  box-shadow: inset 10px 10px 18px rgba(0, 0, 0, 0.24);
  position: relative;
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
}
@media screen and (max-width: 600px) {
  .cs_container_1150_tarczynski .challenge .cs_poranek_row .cs_bullets .cs_bullets_box {
    padding: 25px 25px 25px 33px;
    font-size: 15px;
    line-height: 1.4;
  }
  .cs_container_1150_tarczynski .challenge .cs_poranek_row .cs_bullets .cs_bullets_box br {
    display: none;
  }
}
.cs_container_1150_tarczynski .challenge .cs_poranek_row .cs_bullets .cs_bullets_box img {
  position: absolute;
  left: -21px;
  right: auto;
  margin: auto;
  top: 0;
  bottom: 0;
  max-width: 45px;
}
.cs_container_1150_tarczynski .realization {
  margin-top: 75px;
}
.cs_container_1150_tarczynski .realization h2.title {
  font-size: 90px;
  color: #EC7102;
  padding: 25px 0 25px 0;
  margin: 0;
}
@media screen and (max-width: 850px) {
  .cs_container_1150_tarczynski .realization h2.title {
    text-align: left;
    font-size: 60px;
  }
}
.cs_container_1150_tarczynski .realization .realization-points {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0;
  grid-template-areas: ". .";
  font-family: "Poppins", sans-serif;
}
@media screen and (max-width: 966px) {
  .cs_container_1150_tarczynski .realization .realization-points {
    grid-template-columns: 1fr;
    grid-template-areas: ".";
  }
}
.cs_container_1150_tarczynski .realization .realization-points .simple-flex {
  display: flex;
  flex-direction: column;
  gap: 50px;
}
@media screen and (max-width: 600px) {
  .cs_container_1150_tarczynski .realization .realization-points .simple-flex {
    gap: 30px;
  }
}
.cs_container_1150_tarczynski .realization .realization-points .baner_event img {
  width: 100%;
}
@media screen and (max-width: 850px) {
  .cs_container_1150_tarczynski .realization .realization-points .baner_event img {
    margin-bottom: 10px;
  }
}
.cs_container_1150_tarczynski .realization .realization-points .points_column {
  display: flex;
  gap: 35px;
  flex-direction: column;
  padding: 0px 80px 40px 0px;
  font-size: 18px;
}
@media screen and (max-width: 850px) {
  .cs_container_1150_tarczynski .realization .realization-points .points_column {
    padding: 0px 40px 40px 0px;
  }
}
.cs_container_1150_tarczynski .realization .realization-points .points_column .point {
  display: flex;
  gap: 20px;
  align-items: flex-start;
}
@media screen and (max-width: 600px) {
  .cs_container_1150_tarczynski .realization .realization-points .points_column .point {
    gap: 5px;
    font-size: 14px;
  }
}
@media screen and (max-width: 600px) {
  .cs_container_1150_tarczynski .realization .realization-points .points_column .point .point-bullet {
    width: 52px;
    position: relative;
    left: -3px;
  }
}
.cs_container_1150_tarczynski .realization .realization-points .points_column .point .points-rows {
  display: flex;
  flex-direction: column;
}
.cs_container_1150_tarczynski .realization .realization-points .points_column .point .points-rows .hero {
  font-size: 20px;
  padding: 20px;
}
@media screen and (max-width: 600px) {
  .cs_container_1150_tarczynski .realization .realization-points .points_column .point .points-rows .hero {
    padding: 0px;
  }
}
.cs_container_1150_tarczynski .realization .realization-points .points_column .point .realization-points-points {
  display: flex;
  flex-direction: column;
  position: relative;
}
.cs_container_1150_tarczynski .realization .realization-points .points_column .point .realization-points-points .subpoints {
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.cs_container_1150_tarczynski .realization .realization-points .points_column .point .realization-points-points .subpoints p {
  padding: 0;
  margin: 0 0 20px 0;
}
.cs_container_1150_tarczynski .realization .realization-points .points_column.right {
  padding: 80px 80px 40px 40px;
}
@media screen and (max-width: 850px) {
  .cs_container_1150_tarczynski .realization .realization-points .points_column.right {
    padding: 40px 40px 40px 0px;
  }
}/*# sourceMappingURL=styles.css.map */