.mask {
  background-image: url("./img/mask.png");
  height: 477px;
  width: 100%;
  position: absolute;
  top: 0;
  background-repeat: no-repeat;
}
@media screen and (max-width: 600px) {
  .mask {
    height: 100% !important;
    max-height: 170px !important;
  }
}

.footer {
  width: 100%;
  height: auto;
  min-height: 477px;
  max-height: 900px;
  background-image: url("../../components/img/bg.jpg");
  margin-bottom: 150px;
  background-size: cover;
  display: flex;
  padding: 80px 0 20px 0;
  position: relative;
  -webkit-clip-path: path("M1920,1531.9H-.7S-1.7,158.9-1.7,84.2C113.2,59.4,537.9,19.7,959,16.9c502.5-3.4,961,68.9,961,68.9v1446.1Z");
          clip-path: path("M1920,1531.9H-.7S-1.7,158.9-1.7,84.2C113.2,59.4,537.9,19.7,959,16.9c502.5-3.4,961,68.9,961,68.9v1446.1Z");
}
@media screen and (max-width: 600px) {
  .footer {
    max-height: unset;
    min-height: auto;
    padding-top: 60px;
  }
}
.footer .mask {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 2;
}
@media screen and (max-width: 600px) {
  .footer .mask {
    height: 100% !important;
    max-height: 170px !important;
  }
}
.footer .footer_container {
  position: relative;
  max-width: 1150px;
  width: 100%;
  margin: 0 auto;
  padding: 50px 0;
}
@media screen and (max-width: 1366px) {
  .footer .footer_container {
    padding: 50px 25px;
  }
}
@media screen and (max-width: 600px) {
  .footer .footer_container {
    padding: 50px 25px 0px 25px;
  }
}
.footer .footer_container .footer_title-row {
  display: flex;
  gap: 15px;
}
.footer .footer_container .footer_title-row .footer-contact-logo_mobile {
  display: inline;
  position: relative;
  top: -20px;
}
.footer .footer_container .footer_title-row .footer-contact-logo_mobile img {
  max-width: 90px;
  margin-bottom: 0;
}
@media screen and (max-width: 600px) {
  .footer .footer_container .footer_title-row .footer-contact-logo_mobile img {
    max-width: 61px;
  }
}
.footer .footer_container .footer_title-row .footer_title {
  display: flex;
  flex-direction: row;
  font-family: "Antonio", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-size: 50px;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 30px;
  color: #fff;
  gap: 13px;
}
.footer .footer_container .footer_title-row .footer_title .green {
  color: #00FFC4;
}
@media screen and (max-width: 600px) {
  .footer .footer_container .footer_title-row .footer_title {
    font-size: 25px;
    flex-direction: row;
    margin-bottom: 40px;
    gap: 5px;
  }
}
.footer .footer_container .footer-grid {
  display: flex;
  justify-content: space-between;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}
@media screen and (max-width: 600px) {
  .footer .footer_container .footer-grid {
    flex-direction: column;
  }
}
.footer .footer_container .footer-grid .footer-contact {
  color: #fff;
}
.footer .footer_container .footer-grid .footer-contact .footer-contact-grid {
  display: flex;
  gap: 30px;
}
@media screen and (max-width: 600px) {
  .footer .footer_container .footer-grid .footer-contact .footer-contact-grid {
    flex-direction: column;
  }
}
.footer .footer_container .footer-grid .footer-contact .footer-contact-grid .footer-contact-logo {
  display: none;
}
.footer .footer_container .footer-grid .footer-contact .footer-contact-grid .footer-contact-logo img {
  max-width: 100px;
}
.footer .footer_container .footer-grid .footer-contact .footer-contact-grid .footer-contact-info {
  font-size: 16px;
}
@media screen and (max-width: 600px) {
  .footer .footer_container .footer-grid .footer-contact .footer-contact-grid .footer-contact-info {
    font-size: 12px;
  }
}
.footer .footer_container .footer-grid .footer-contact .footer-contact-grid .footer-contact-info .other-logos {
  margin-top: 36px;
}
@media screen and (max-width: 600px) {
  .footer .footer_container .footer-grid .footer-contact .footer-contact-grid .footer-contact-info .other-logos {
    margin-top: 10px;
  }
}
@media screen and (max-width: 600px) {
  .footer .footer_container .footer-grid .footer-contact .footer-contact-grid .footer-contact-info .other-logos img {
    max-width: 60vw;
  }
}
.footer .footer_container .footer-grid .footer-contact .footer-contact-grid .footer-contact-info .footer-contact-flex {
  display: flex;
  gap: 30px;
  margin-top: 10px;
}
@media screen and (max-width: 600px) {
  .footer .footer_container .footer-grid .footer-contact .footer-contact-grid .footer-contact-info .footer-contact-flex {
    margin-top: 3px;
    gap: 10px;
    line-height: 0.5;
  }
}
.footer .footer_container .footer-grid .footer-menu ul {
  border-left: 3px solid #00FFC4;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px 0 15px 40px;
}
@media screen and (max-width: 600px) {
  .footer .footer_container .footer-grid .footer-menu ul {
    margin: 2px 0 10px 0;
    padding: 0px 0 0 0;
    border: 0;
    border-left: 0;
    flex-direction: row;
    font-size: 11px;
    list-style: none;
  }
}
.footer .footer_container .footer-grid .footer-menu ul li a {
  color: #fff;
  text-decoration: none;
}
.footer .footer_container .copyright {
  color: #7E7E7E;
  font-family: "Antonio", sans-serif;
  font-size: 12px;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
@media screen and (max-width: 600px) {
  .footer .footer_container .copyright {
    margin-top: -5px;
    justify-content: flex-start;
  }
}

@media screen and (max-width: 600px) {
  .mobileFooterRow {
    display: flex;
    gap: 20px;
  }
}/*# sourceMappingURL=style.css.map */