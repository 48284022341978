.content1150 {
  width: 100%;
  max-width: 1150px;
  margin: auto;
  font-family: "Poppins", sans-serif;
}
@media screen and (max-width: 1111px) {
  .content1150 {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 600px) {
  .content1150 {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 25px;
  }
}
.content1150 .awards {
  padding-bottom: 70px;
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: flex-end;
}
@media screen and (max-width: 1150px) {
  .content1150 .awards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas: ". .";
  }
}
@media screen and (max-width: 1150px) {
  .content1150 .awards {
    padding-bottom: 70px;
    gap: 50px 0px;
  }
}
@media screen and (max-width: 600px) {
  .content1150 .awards {
    padding-bottom: 0px;
    gap: 6px 0px;
    max-width: 93%;
    margin: auto;
  }
}
.content1150 .awards .award-item {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 30px;
  gap: 0px 0px;
  grid-template-areas: "." ".";
  justify-content: center;
  justify-items: center;
  align-items: end;
  font-family: "Antonio", sans-serif;
  font-weight: 200;
  font-style: normal;
}
.content1150 .awards .award-item img {
  width: 80%;
  max-width: 200px;
}
@media screen and (max-width: 600px) {
  .content1150 .awards .award-item img {
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .content1150 .awards .award-item span {
    opacity: 0.75;
    position: relative;
    top: -14px;
    font-size: 13px;
  }
}/*# sourceMappingURL=style.css.map */