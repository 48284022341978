.content1150{
  width: 100%;
  max-width: 1150px;
  margin: auto;
  font-family: "Poppins", sans-serif;
  padding: 0;

  @media screen and (max-width: 1250px) {
    padding: 0 25px !important;
  }

  .needs{
    margin-top: 70px;
    padding-bottom: 30px;
    display: grid; 
    grid-template-columns: 30% 1fr; 
    grid-template-rows: 1fr; 
    gap: 50px; 
    grid-template-areas: 
      ". ."; 
     
    @media screen and (max-width: 1248px) {
      gap: 40px;
      grid-template-columns: 1fr; 
      grid-template-areas: 
        "."; 
    }

    @media screen and (max-width: 600px) {
      padding-bottom: 50px;
      margin-top: 30px;
      gap: 20px;
    }

    .title{
      display: flex;
      flex-direction: column;
      font-family: "Antonio", sans-serif;
      font-optical-sizing: auto;
      font-weight: 700;
      font-style: normal;
      font-size: 90px;
      text-transform: uppercase;
      line-height: 1.1;

      position: relative;

      .green{
        color: #00FFC4;
      }
      @media screen and (max-width: 1500px) {
        // font-size: clamp(50px, 6vw, 80px);
      }
      @media screen and (max-width: 600px) {
        font-size: 50px;
        top: 0;
        margin-bottom: 0;
      }
    }  
    .bullets{
      margin-top: 70px;
      display: grid; 
      grid-template-columns: 1fr 1fr; 
      grid-template-rows: auto auto; 
      gap: 85px 20px; 
      grid-template-areas: 
        ". ."
        ". ."; 

      @media screen and (max-width: 600px) {
        grid-template-columns: 1fr; 
        grid-template-areas: 
            "."; 
        gap: 10px; 
        border-radius: 25px;
        margin-left: 0;
        margin-top: 0;
      }

      .bullets_box{
        padding: 50px 10px 10px 10px;
        border-radius: 25px;
        position: relative;

        @media screen and (max-width: 600px) {
          box-shadow: none;
          padding: 0;
        }

        .mobile_row{
          display: flex;
          align-items: flex-end;
          margin-bottom: 8px;
        }

        img{
          position: absolute;
          top: -60px;
          left: 2px;
          @media screen and (max-width: 600px) {
            width: 66px;
            top: 8px;
            left: -2px;
            position: relative;
          }
        }
      
        
        .bullets_box_content_b{
          @media screen and (max-width: 600px) {
            font-weight: 700;
            font-size: 20px;
            line-height: 23px;
            margin-bottom: 5px;              
          }
          @media screen and (max-width: 390px) {
            font-weight: 700;
            font-size: 15px;
            line-height: 20px;
            margin-bottom: 6px;
          }
        }
        


        .bullets_box_content{
          .bullets_box_content_b{
            font-weight: 700;
            font-size: 25px;
            line-height: 1.15;
            margin-bottom: 10px;
            @media screen and (max-width: 600px) {
              font-size: 20px;
              line-height: 23px;
              margin-bottom: 5px;
            }
            @media screen and (max-width: 390px) {
              font-weight: 700;
              font-size: 15px;
              line-height: 20px;
              margin-bottom: 6px;
            }
          }
          .bullets_box_content_p{
            font-family: "Open Sans", sans-serif;
            font-weight: 400;
            position: relative;
            color: #737373;
            font-size: 13px;
            @media screen and (max-width: 600px) {
              font-size: 12px;
              font-weight: 400;
              line-height: 15px;
            }

            b{
              font-weight: 600;
            }
          }          
        }        
      }  
    }    
  }
}