.cs_baner_tarczynski{
  background-image: url('./img/baner.png');
  background-position: center;
  width: 100%;
  min-height: calc(100vh - 125px);
  display: flex;
  background-size: cover;

  @media screen and (max-width: 1100px) {
    background-position: 24% !important;
  }
  @media screen and (max-width: 1000px) {
    background-position: 36% !important;
  }
  @media screen and (max-width: 860px) {
    background-position: 40% !important;
  }

  @media screen and (max-width: 800px) {
    background-image: url('./img/baner_mobile_2.jpg');
  }

  @media screen and (max-width: 800px) {
    background-position: 0px -57px !important;
    min-height: 82vh;
  }
  @media screen and (max-width: 600px) {
    background-position: top !important;
  }

  img{
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto 0 23.3vw;
    z-index: 1;

    @media screen and (max-width: 1400px) {
      margin: 0 auto;
    }
    @media screen and (max-width: 800px) {
      position: absolute;
      top: 0;
      margin: 0;
      left: 25px;
      right: 0;
      max-width: 120px;
    }
  }

  .cs_baner_container{
    display: flex;
    width: 100%;
    max-width: 1150px;
    margin: 0 auto;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    @media screen and (max-width: 1750px) {

    }

    @media screen and (max-width: 1400px) {
      width: 100%;
      padding: 0px 25px 0 25px;
    }

    @media screen and (max-width: 800px) {
      margin: 0 auto;
      flex-direction: column-reverse;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 0 25px 15px 25px !important;
      display: flex;
      gap: 10px;
    }

    h2{
      font-size: 75px;
      text-transform: uppercase;
      line-height: 1.18;
      margin: 0 0 30px 0;

      @media screen and (max-width: 800px) {
        font-size: clamp(45px, 5vw, 63px);
        color: #0C2C65;
      }
    }
    h5{
      font-size: 27px;
      margin: 18px 0 0 0;
      font-weight: 500;
      background-color: #fff;
      padding: 5px 20px;

      @media screen and (max-width: 800px) {
        font-size: clamp(15px, 3vw, 27px);
      }
    }
  }
}  
.cs_black_space{
  background-color: black;
  height: 56px;
  width: 100%;
}

.cs_container_1150_tarczynski{
  width: 100%;
  max-width: 1150px;
  margin: 0 auto;
  padding: 50px 0;
  @media screen and (max-width: 1366px) {
    padding-left: 25px;
    padding-right: 25px;
  }

  .challenge{
    h2.title{
      font-size: 90px;
      margin: 20px 0 50px 0;
      padding: 0;
      @media screen and (max-width: 850px) {
        text-align: left;
        font-size: 60px;
      }
    }
    .cs_poranek_row{
      display: grid; 
      grid-template-columns: 1fr; 
      grid-template-rows: 1fr; 
      grid-template-areas: 
        "."; 
      gap: 50px;
      position: relative;
      align-items: start;
      max-width: 70%;
      padding-left: 20px;
      @media screen and (max-width: 850px) {
        max-width: unset;
      }
      .cs_bullets{
        display: flex;
        gap: 50px;
        width: 100%;
        @media screen and (max-width: 1110px) {
          flex-direction: column;
        }
        @media screen and (max-width: 600px) {
          gap: 20px;
        }
        .cs_bullets_box {
          padding: 40px 40px 40px 52px;
          border-radius: 25px;
          box-shadow: inset 10px 10px 18px rgba(0, 0, 0, 0.24);
          position: relative;
          width: 100%;
          font-size: 18px;
          font-weight: bold;
          font-family: "Poppins", sans-serif;
          @media screen and (max-width: 600px) {
            padding: 25px 25px 25px 33px;
            font-size: 15px;
            line-height: 1.4;
  
            br{
              display: none;
            }
          }
          img {
            position: absolute;
            left: -21px;
            right: auto;
            margin: auto;
            top: 0;
            bottom: 0;
            max-width: 45px;
          }
        }    
      }

    }
    
  }

  .realization{
    margin-top: 75px;
    h2.title{
      font-size: 90px;
      color: #EC7102;
      padding: 25px 0 25px 0;
      margin: 0;
      @media screen and (max-width: 850px) {
        text-align: left;
        font-size: 60px;
      }
    }

    .realization-points{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
      gap: 0;
      grid-template-areas: ". .";
      font-family: "Poppins", sans-serif;

      @media screen and (max-width: 966px) {
        grid-template-columns: 1fr;
        grid-template-areas: ".";
      }

      .simple-flex{
        display: flex;
        flex-direction: column;
        gap: 50px;
        @media screen and (max-width: 600px) {
          gap: 30px;
        }
      }

      .baner_event{
        img{
          width: 100%;
          @media screen and (max-width: 850px) {
            margin-bottom: 10px;
          }
        }
      }

      
      .points_column{
        display: flex;
        gap: 35px;
        flex-direction: column;
        padding: 0px 80px 40px 0px;
        font-size: 18px;

        @media screen and (max-width: 850px) {
          padding: 0px 40px 40px 0px;
        }

        .point{
          display: flex;
          gap: 20px;
          align-items: flex-start;
          @media screen and (max-width: 600px) {
            gap: 5px;
            font-size: 14px;
          }
          .point-bullet{
            @media screen and (max-width: 600px) {
              width: 52px;
              position: relative;
              left: -3px;
            }
          }
          .points-rows{
            display: flex;
            flex-direction: column;
            .hero{
              font-size: 20px;
              padding: 20px;
              @media screen and (max-width: 600px) {
                padding: 0px;
              }
            }          
          }

          .realization-points-points{
            display: flex;
            flex-direction: column;
            position: relative;
            
            .subpoints{
              display: flex;
              flex-direction: column;
              padding: 20px;
              p{
                padding: 0;
                margin: 0 0 20px 0;
              }            
            }

          }
        }
      }

      .points_column.right{
        padding: 80px 80px 40px 40px;

        @media screen and (max-width: 850px) {
          padding: 40px 40px 40px 0px;
        }
      }

    }
  }

}