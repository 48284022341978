.content1150_working{
  width: 100%;
  max-width: 1150px;
  margin: auto;
  font-family: "Poppins", sans-serif;

  @media screen and (max-width: 1470px) {
    width: 100%;
    padding: 0 25px;
  }
  
  .working{
    padding-bottom: 50px;
    @media screen and (max-width: 625px) {
      padding-bottom: 0px;
    }
    .working_title{
      display: flex;
      flex-direction: column;
      font-family: "Antonio", sans-serif;
      font-optical-sizing: auto;
      font-weight: 700;
      font-style: normal;
      font-size: 90px;
      text-transform: uppercase;
      line-height: 1.1;
      position: relative;
      margin-bottom: 35px;

      @media screen and (max-width: 625px) {
        font-size: 50px;
      }

      .green{
        color: #00FFC4;
        @media screen and (max-width: 625px) {
          font-size: 45px;
        }
      }
    }
    .working_lead{
      font-family: "Poppins", sans-serif;
      font-weight: 400;
      font-size: 25px;
      @media screen and (max-width: 625px) {
        line-height: 26px;
        font-size: 23px;
      }
    }
    .working_box{
      padding: 70px 40px 60px 40px;
      border-radius: 25px;
      box-shadow: inset 10px 10px 18px rgba(0, 0, 0, 0.24);
      margin: 20px 0;
      display: grid;
      grid-template-columns: repeat(auto-fill, 200px);
      grid-gap: 40px 10px;
      justify-content: space-between;


      @media screen and (max-width: 625px) {
        padding: 20px 0px 40px 0px;
        box-shadow: none;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        gap: 25px;
      }

      div{
        display: flex;
        justify-content: center;
        align-items: center;

        img{
          width: 100%;
          @media screen and (max-width: 625px) {
            width: 100%;
            max-width: 38vw;
            height: auto;
          }
        }
      }
    }
    .working_bullets{
      position: relative;
      .working_bullets_main{
        position: relative;
        z-index: 0;
        background-color: #000;
        border-radius: 13px;
        min-height: 74px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 40px;
        @media screen and (max-width: 625px) {
          padding: 0 20px;
        }
        .blink {
          background-image: url('./img/reflec.png');
          clip-path: polygon(0 0, 100% 0, 100% 50%, 0% 100%);
          width: 62px;
          height: 67px;
          position: absolute;
          left: 0;
          background-repeat: no-repeat;
          top: 0;
        }
        .working_bullets_main_content{
          display: flex;
          gap: 30px;
          justify-content: space-between;
          width: 100%;

          @media screen and (max-width: 1024px) {
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;
            justify-content: flex-start;
            padding: 50px 0;
            gap: 50px;
          }

          @media screen and (max-width: 625px) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr;
            gap: 25px 10px;
            grid-template-areas: ". .";
            padding: 25px 0;
          }

          .working_bullets_main_points{
            position: relative;
            z-index: 2;
            display: flex;
            gap: 10px;
            align-items: center;
            font-family: "Open Sans", sans-serif;
            font-size: 16px;
            font-weight: bold;

            @media screen and (max-width: 1024px) {
              width: 100%;
              max-width: 250px;
            }
            @media screen and (max-width: 625px) {
              max-width: unset;
            }

            img{
              @media screen and (max-width: 625px) {
                max-width: 20px;
              }
            }

            .bullet_title{
              line-height: 0.85;
              display: flex;
              flex-direction: column;
              gap: 5px;
              font-family: "Poppins", sans-serif;
              @media screen and (max-width: 625px) {
                line-height: 1.1;
              }
              small{
                font-size: 8px;
                display: inline-block;
                width: 100%;
                font-weight: 500;
              }              
            }
          }
        }

      }
      .working_bullets_shadow{
        position: absolute;
        background: rgb(122, 34, 154);
        background: linear-gradient(0deg, rgb(122, 34, 154) 0%, rgb(255, 0, 93) 100%);
        width: 100%;
        min-height: 74px;
        border-radius: 13px;
        top: 7px;
        left: 9px;
        z-index: -1;
        box-shadow: 4px 9px 13px rgb(0 0 0 / 20%);
      }
    }
  }
}