.language-switch{
  border: 2px solid #00ffc4;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px 12px;
  font-family: "Poppins", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-size: 12px;
  width: 118px;
  justify-content: space-between; 
  position: relative;
  top: 4px;
  transition: all ease-in-out .1s;

  @media screen and (min-width: 650px) {
    &:hover{
      border: 2px solid #fff;
      background-color: #fff;
      color: #000;
  
      .react-switch-handle {
        background: #000 !important;
      }
    }
  }




}



.small{
  .language-switch{ 
    border: 2px solid #00ffc4;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 9px;
    font-family: "Poppins", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 11px;
    width: 110px;
    justify-content: space-between; 
    position: relative;
    top: -4px;

    @media screen and (max-width: 600px) {
      display: none !important;
    }
  }
}

.sticky-navbar-nav{
  .language-switch{
    top: 0px;
  }
}