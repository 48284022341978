.cs_baner_lirene{
  background-image: url('./img/baner.png');
  background-position: center;
  width: 100%;
  min-height: calc(100vh - 125px);
  display: flex;
  background-size: cover;

  @media screen and (max-width: 750px) {
    background-image: url('./img/lirene_mobile.jpg');
    min-height: 82vh;
  }

  .cs_baner_container{
    display: flex;
    width: 100%;
    max-width: 1150px;
    margin: auto;
    flex-direction: column;
    align-items: flex-start;

    @media screen and (max-width: 750px) {
      margin: 50px auto 0 auto;
    }

    @media screen and (max-width: 1400px) {
      width: 100%;
      padding: 0 25px 50px 25px;
      justify-content: flex-end;
    }

    img{
      position: relative;
      left: 4px;
      @media screen and (max-width: 750px) {
        width: 130px;
      }
    }

    h2{
      font-size: clamp(50px, 6vw, 85px);
      margin: 10px 0 0 0;
      text-transform: uppercase;
      line-height: 1.16;
      text-shadow: 3px 3px #f8bbca;

      @media screen and (max-width: 600px) {
        font-size: clamp(40px, 10vw, 85px)
      }
    }
    h5{
      font-size: 31px;
      margin: 10px 0 0 0;
      font-weight: 400;
      font-size: clamp(25px, 2vw, 34px);

      @media screen and (max-width: 600px) {
        font-size: clamp(20px, 5vw, 31px)
      }
    }
  }
}  
.cs_black_space{
  background-color: black;
  height: 56px;
  width: 100%;
}

.cs_container_1150{
  width: 100%;
  max-width: 1150px;
  margin: 0 auto;
  padding: 50px 0;
  @media screen and (max-width: 1366px) {
    padding-left: 25px;
    padding-right: 25px;
  }

  .challenge{
    h2.title{
      font-size: 90px;
      margin-top: 20px;
      @media screen and (max-width: 850px) {
        text-align: left;
        font-size: 60px;
        margin-top: 0;
      }
    }
    .cs_bullets{
      display: flex;
      gap: 50px;
      width: 100%;
      @media screen and (max-width: 1110px) {
        flex-direction: column;
      }
      @media screen and (max-width: 600px) {
        gap: 20px;
      }
      .cs_bullets_box {
        padding: 40px 40px 40px 52px;
        border-radius: 25px;
        box-shadow: inset 10px 10px 18px rgba(0, 0, 0, 0.24);
        position: relative;
        width: 100%;
        font-size: 18px;
        font-weight: bold;
        font-family: "Poppins", sans-serif;
        @media screen and (max-width: 600px) {
          padding: 25px 25px 25px 33px;
          font-size: 15px;
          line-height: 1.4;

          br{
            display: none;
          }
        }
        img {
          position: absolute;
          left: -21px;
          right: auto;
          margin: auto;
          top: 0;
          bottom: 0;
          max-width: 45px;
        }
      }    
    }    
  }

  .realization{
    h2.title{
      font-size: 90px;
      color: #C73D5F;
      padding-top: 50px;
      @media screen and (max-width: 850px) {
        text-align: left;
        font-size: 60px;
        padding-top: 30px;
      }
    }
    .realization-points{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
      gap: 50px;
      grid-template-areas: ". .";
      font-family: "Poppins", sans-serif;

      @media screen and (max-width: 966px) {
        grid-template-columns: 1fr;
        grid-template-areas: ".";
      }
      @media screen and (max-width: 600px) {
        gap: 30px;
      }
      .simple-flex{
        display: flex;
        flex-direction: column;
        gap: 50px;
        @media screen and (max-width: 600px) {
          gap: 30px;
        }
      }

      .point{
        display: flex;
        gap: 20px;
        align-items: flex-start;
        @media screen and (max-width: 600px) {
          gap: 5px;
          font-size: 14px;
        }
        .point-bullet{
          @media screen and (max-width: 600px) {
            width: 52px;
            position: relative;
            left: -3px;
          }
        }
        .points-rows{
          display: flex;
          flex-direction: column;
          .hero{
            font-size: 20px;
            padding: 20px;
            @media screen and (max-width: 600px) {
              padding: 0px;
            }
          }          
        }

        .realization-points-points{
          display: flex;
          flex-direction: column;
          position: relative;
          
          .subpoints{
            display: flex;
            flex-direction: column;
            padding: 20px;
            @media screen and (max-width: 600px) {
              padding: 10px 0px;
            }
            p{
              padding: 0;
              margin: 0 0 20px 0;
              font-family: "Poppins", sans-serif !important;
            }            
          }

        }
      }

    }
  }
}
