@charset "UTF-8";
.sticky-navbar-nav {
  width: 100%;
  height: 60px;
  padding: 0;
  background-color: chocolate;
}
@media screen and (max-width: 600px) {
  .sticky-navbar-nav {
    pointer-events: none;
  }
}
@media screen and (max-width: 600px) {
  .sticky-navbar-nav .navigation-box-small .navigation {
    padding: 50px 50px 0px 50px;
  }
}
.sticky-navbar-nav .navigation-box-small .navigation .dropdown-list {
  position: absolute;
  top: -50px;
  right: 0;
  left: auto;
  background-color: rgba(195, 130, 130, 0.94);
  background-image: url("../img/bg.jpg");
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  z-index: 0;
  padding: 20px 30px;
  list-style-type: none;
  margin: 0 0 0 0;
  border-radius: 0;
  opacity: 0;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: flex-start;
  width: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  justify-content: center;
  transition: all 500ms ease-in-out; /* custom */
}
.sticky-navbar-nav .navigation-box-small .navigation .dropdown-list .mobile-logo {
  position: absolute;
  top: 20px;
  right: auto;
  left: 25px;
}
@media screen and (min-width: 1000px) {
  .sticky-navbar-nav .navigation-box-small .navigation .dropdown-list .mobile-logo {
    top: 10px;
  }
}
.sticky-navbar-nav .navigation-box-small .navigation .dropdown-list .mobile-logo img {
  max-width: 75px;
}
@media screen and (min-width: 1000px) {
  .sticky-navbar-nav .navigation-box-small .navigation .dropdown-list .mobile-logo img {
    max-width: 40px;
    top: 3px;
    position: relative;
  }
}
.sticky-navbar-nav .navigation-box-small .navigation .dropdown-list a {
  text-decoration: none;
  color: #ffffff !important;
  font-size: 15px;
  font-weight: 300;
}
.sticky-navbar-nav .navigation-box-small .navigation .dropdown-list a:hover {
  color: #dd08aa;
}
@media screen and (min-width: 600px) {
  .sticky-navbar-nav .navigation-box-small .navigation .dropdown-list .dropdown-list {
    position: absolute;
    top: 35px !important;
    left: -250px !important;
    background-color: #f9f9f9;
    background-image: url("../img/bg.jpg");
    background-position: left;
    box-shadow: 0 0px 0px 7px rgba(255, 255, 255, 0.13);
    z-index: 99;
    padding: 30px 30px 20px 30px;
    list-style-type: none;
    margin: 0 0 0 0;
    border-radius: 12px !important;
    opacity: 0;
    transition: all 300ms cubic-bezier(0.145, 0.585, 0.415, 0.9); /* custom */
    transition-timing-function: cubic-bezier(0.145, 0.585, 0.415, 0.9); /* custom */
    cursor: pointer;
    display: flex !important;
    gap: 30px 15px !important;
    align-items: flex-start !important;
    width: 600px !important;
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
  }
  .sticky-navbar-nav .navigation-box-small .navigation .dropdown-list .dropdown-list li button {
    border: 0;
    background: transparent;
    text-decoration: none;
    color: #ffffff;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    cursor: pointer;
    transition: all ease 0.2s;
  }
  .sticky-navbar-nav .navigation-box-small .navigation .dropdown-list .dropdown-list li button img {
    max-width: unset;
    border-radius: 15px;
    transition: all ease 0.2s;
    box-shadow: 0 0 0px 4px rgba(0, 255, 195, 0);
  }
  .sticky-navbar-nav .navigation-box-small .navigation .dropdown-list .dropdown-list li button img:hover {
    box-shadow: 0 0 0px 4px rgba(0, 255, 195, 0.8);
  }
  .sticky-navbar-nav .navigation-box-small .navigation .dropdown-list .dropdown-list li button:hover {
    color: #00ffc4;
  }
  .sticky-navbar-nav .navigation-box-small .navigation .dropdown-list .dropdown-list li .active {
    color: #00ffc4;
  }
  .sticky-navbar-nav .navigation-box-small .navigation .dropdown-list .dropdown-list li .active img {
    box-shadow: 0 0 0px 4px rgba(0, 255, 195, 0.8);
  }
  .sticky-navbar-nav .navigation-box-small .navigation .dropdown-list .dropdown-list span.sign {
    position: absolute;
    top: -15px !important;
    opacity: 0;
    left: 0 !important;
    right: 0 !important;
    margin: auto !important;
    padding: 14px 0 0 15px;
    z-index: -2;
    width: 178px;
    background-color: transparent;
    min-height: 50px;
  }
}
@media screen and (max-width: 600px) {
  .sticky-navbar-nav .navigation-box-small .navigation .dropdown-list {
    box-shadow: none !important;
    background-color: transparent !important;
    background-image: url("../img/bg.jpg");
    background-size: cover;
    background-position: center -20vh;
    background-repeat: no-repeat;
    width: 100vw !important;
    left: 0 !important;
    top: -50px !important;
    z-index: -1 !important;
    height: 100vh;
    padding-top: 120px;
    padding-left: 25px !important;
    line-height: 6vh !important;
    border-radius: 0 !important;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 1px !important;
  }
  .sticky-navbar-nav .navigation-box-small .navigation .dropdown-list .mobile-logo {
    position: absolute;
    top: 20px;
    right: auto;
    left: 25px;
  }
  .sticky-navbar-nav .navigation-box-small .navigation .dropdown-list .mobile-logo img {
    max-width: 75px;
  }
}
@media screen and (max-width: 600px) and (min-width: 600px) {
  .sticky-navbar-nav .navigation-box-small .navigation .dropdown-list .mobile-logo {
    display: none;
  }
}
@media screen and (max-width: 600px) and (max-width: 1000px) {
  .sticky-navbar-nav .navigation-box-small .navigation .dropdown-list .sublink a {
    color: #dd08aa !important;
    font-weight: 100;
  }
}
@media screen and (max-width: 600px) {
  .sticky-navbar-nav .navigation-box-small .navigation .dropdown-list a {
    font-size: 18px !important;
    padding: 0 !important;
  }
  .sticky-navbar-nav .navigation-box-small .navigation .dropdown-list a {
    text-decoration: none;
    color: #ffffff;
    font-size: 15px;
    font-weight: 300;
  }
}
@media screen and (max-width: 600px) and (max-width: 1000px) {
  .sticky-navbar-nav .navigation-box-small .navigation .dropdown-list a {
    font-family: "Antonio", sans-serif;
    font-weight: bold;
    font-size: clamp(20px, 5vh, 45px) !important;
  }
}
@media screen and (max-width: 600px) {
  .sticky-navbar-nav .navigation-box-small .navigation .dropdown-list a:hover {
    color: #dd08aa;
  }
}
.sticky-navbar-nav .navigation-box-small .navigation .dropdown-list.visible {
  z-index: -1;
  top: 0;
  opacity: 1;
  pointer-events: auto;
}
@media screen and (max-width: 600px) {
  .sticky-navbar-nav .navigation-box-small .navigation .dropdown-list.visible {
    box-shadow: none !important;
    background-color: transparent !important;
    background-image: url("../img/bg.jpg");
    background-size: cover;
    background-position: center 0vh;
    background-repeat: no-repeat;
    width: 100vw !important;
    left: 0 !important;
    top: 0 !important;
    z-index: -1 !important;
    height: 100vh !important;
    padding-top: 120px !important;
    padding-left: 25px !important;
    line-height: 6vh !important;
    border-radius: 0 !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}
@media screen and (max-width: 600px) and (max-width: 1000px) {
  .sticky-navbar-nav .navigation-box-small .navigation .dropdown-list.visible {
    flex-direction: column;
    padding-top: 120px !important;
    gap: 2px !important;
    justify-content: flex-start;
  }
}
@media screen and (max-width: 600px) {
  .sticky-navbar-nav .navigation-box-small .navigation .dropdown-list.visible .mobile-logo {
    position: absolute;
    top: 20px;
    right: auto;
    left: 25px;
  }
  .sticky-navbar-nav .navigation-box-small .navigation .dropdown-list.visible .mobile-logo img {
    max-width: 75px;
  }
}
@media screen and (max-width: 600px) and (min-width: 600px) {
  .sticky-navbar-nav .navigation-box-small .navigation .dropdown-list.visible .mobile-logo {
    display: none;
  }
}
@media screen and (max-width: 600px) and (max-width: 1000px) {
  .sticky-navbar-nav .navigation-box-small .navigation .dropdown-list.visible .sublink a {
    color: #dd08aa !important;
    font-weight: 100;
  }
}
@media screen and (max-width: 600px) {
  .sticky-navbar-nav .navigation-box-small .navigation .dropdown-list.visible a {
    text-decoration: none;
    color: #ffffff;
    font-size: 15px;
    font-weight: 300;
  }
}
@media screen and (max-width: 600px) and (max-width: 1000px) {
  .sticky-navbar-nav .navigation-box-small .navigation .dropdown-list.visible a {
    font-family: "Antonio", sans-serif;
    font-weight: bold;
    font-size: clamp(20px, 5vh, 45px) !important;
  }
}
@media screen and (max-width: 600px) {
  .sticky-navbar-nav .navigation-box-small .navigation .dropdown-list.visible a:hover {
    color: #dd08aa;
  }
}

.sticky-navbar-nav.sticky-navbar-middle {
  height: 80px;
}

.navigation-box.isPage {
  height: 90px;
}
@media screen and (max-width: 600px) {
  .navigation-box.isPage {
    height: 80px;
  }
}
.navigation-box.isPage .navigation {
  height: 90px;
}
@media screen and (max-width: 600px) {
  .navigation-box.isPage .navigation {
    height: 80px;
  }
}
.navigation-box.isPage .navigation img {
  max-width: 92px;
  transition: all ease-in-out 0.2s;
  cursor: pointer;
}
.navigation-box.isPage .navigation img:hover {
  opacity: 0.8;
}
@media screen and (max-width: 600px) {
  .navigation-box.isPage .navigation img {
    max-width: 45px;
  }
}

.navigation-box {
  width: 100%;
  max-width: 1905px;
  height: 125px;
  position: absolute;
  left: 0;
  transition: all ease 1s;
  z-index: 9;
  background: transparent !important;
}
.navigation-box .navigation {
  width: 100%;
  max-width: 1150px;
  margin: auto;
  display: flex;
  color: #fff;
  height: 125px;
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: auto 452px 1fr auto;
  gap: 0px 0px;
  align-items: center;
}
@media screen and (max-width: 600px) {
  .navigation-box .navigation .language-switch {
    display: flex;
    position: absolute;
    right: 85px;
    top: 22px;
    width: auto;
    padding: 2px 7px;
    font-size: 10px;
    border: 0;
  }
}
@media screen and (max-width: 600px) {
  .navigation-box .navigation .language-switch label {
    margin-right: 10px !important;
  }
}
@media screen and (max-width: 600px) {
  .navigation-box .navigation .language-switch img {
    position: relative;
    top: 3px;
    width: 22px;
  }
}
@media screen and (max-width: 800px) {
  .navigation-box .navigation {
    grid-template-columns: auto auto 1fr auto;
  }
}
@media screen and (max-width: 1200px) {
  .navigation-box .navigation {
    width: 100%;
    padding-right: 25px;
    padding-left: 25px;
  }
}
@media screen and (max-width: 600px) {
  .navigation-box .navigation {
    display: flex;
    justify-content: space-between;
  }
}
.navigation-box .navigation img {
  max-width: 92px;
}
@media screen and (max-width: 600px) {
  .navigation-box .navigation img {
    max-width: 94px;
    top: 7px;
    position: relative;
  }
}
.navigation-box .navigation ul {
  display: flex;
  gap: 40px;
  list-style: none;
  margin-left: 10px;
  align-items: center;
  position: relative;
}
@media screen and (max-width: 675px) {
  .navigation-box .navigation ul {
    gap: 20px;
  }
}
@media screen and (max-width: 640px) {
  .navigation-box .navigation ul {
    margin-left: -10px;
  }
}
@media screen and (max-width: 600px) {
  .navigation-box .navigation ul {
    display: none;
  }
}
.navigation-box .navigation ul li {
  font-family: "Antonio", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-size: 25px;
  cursor: pointer;
}
@media screen and (max-width: 800px) {
  .navigation-box .navigation ul li {
    font-size: 20px;
  }
}
@media screen and (max-width: 720px) {
  .navigation-box .navigation ul li {
    font-size: 17px;
    white-space: nowrap;
  }
}
.navigation-box .navigation ul li a {
  color: #FFF;
  text-decoration: none;
}
.navigation-box .navigation ul li a:hover {
  color: #00FFC4;
}
.navigation-box .navigation ul li span {
  color: #FFF;
  text-decoration: none;
}
.navigation-box .navigation ul li span:hover {
  color: #00FFC4;
}
.navigation-box .navigation ul li > * {
  transition: all 500ms cubic-bezier(0.145, 0.585, 0.415, 0.9); /* custom */
  transition-timing-function: cubic-bezier(0.145, 0.585, 0.415, 0.9); /* custom */
}
.navigation-box .navigation ul li .dropdown-list {
  position: absolute;
  top: 60px;
  left: 0;
  background-color: #f9f9f9;
  background-image: url("../img/bg.jpg");
  background-position: left;
  box-shadow: 0 0px 0px 7px rgba(255, 255, 255, 0.13);
  z-index: 99;
  padding: 30px 30px 20px 30px;
  list-style-type: none;
  margin: 0 0 0 0;
  border-radius: 12px;
  opacity: 0;
  transition: all 300ms cubic-bezier(0.145, 0.585, 0.415, 0.9); /* custom */
  transition-timing-function: cubic-bezier(0.145, 0.585, 0.415, 0.9); /* custom */
  cursor: pointer;
  display: flex;
  gap: 30px 15px;
  align-items: flex-start;
  width: 600px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.navigation-box .navigation ul li .dropdown-list span.sign {
  position: absolute;
  top: -26px;
  opacity: 0.7;
  left: 0px;
  background: transparent;
  width: 180px;
  padding: 14px 0 0 15px;
  opacity: 0;
  z-index: -2;
}
.navigation-box .navigation ul li .dropdown-list li button {
  border: 0;
  background: transparent;
  text-decoration: none;
  color: #ffffff;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  cursor: pointer;
  transition: all ease 0.2s;
}
.navigation-box .navigation ul li .dropdown-list li button img {
  max-width: unset;
  border-radius: 15px;
  transition: all ease 0.2s;
  box-shadow: 0 0 0px 4px rgba(0, 255, 195, 0);
}
.navigation-box .navigation ul li .dropdown-list li button img:hover {
  box-shadow: 0 0 0px 4px rgba(0, 255, 195, 0.8);
}
.navigation-box .navigation ul li .dropdown-list li button:hover {
  color: #00ffc4;
}
.navigation-box .navigation ul li .dropdown-list li .active {
  color: #00ffc4;
}
.navigation-box .navigation ul li .dropdown-list li .active img {
  box-shadow: 0 0 0px 4px rgba(0, 255, 195, 0.8);
}
.navigation-box .navigation ul li .dropdown-list a {
  text-decoration: none;
  color: #ffffff;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  font-weight: 400;
}
.navigation-box .navigation ul li .dropdown-list a img {
  max-width: unset;
  border-radius: 15px;
}
.navigation-box .navigation ul li .dropdown-list a:hover {
  color: #dd08aa;
}
.navigation-box .navigation ul li .dropdown-list.visible {
  z-index: 9;
  top: 50px;
  opacity: 1;
  pointer-events: auto;
}
.navigation-box .navigation ul li .dropdown-list.visible span.sign {
  position: absolute;
  top: -26px;
  opacity: 0 !important;
  background: transparent;
  width: 180px;
  left: 0px;
  padding: 14px 0 0 15px;
}
.navigation-box .navigation .social-position {
  position: relative;
  top: 3px;
  margin: auto 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media screen and (max-width: 600px) {
  .navigation-box .navigation .social-position .social {
    display: flex;
  }
}
.navigation-box .navigation .social-position ul {
  margin-left: 30px;
}

.sticky-navbar-nav.sticky-navbar-hidden .navigation-box-small .navigation .dropdown-list {
  position: absolute;
  top: -50px;
  right: 0;
  left: auto;
  background-color: rgba(249, 249, 249, 0.94);
  z-index: 99;
  padding: 20px 30px;
  list-style-type: none;
  margin: 0 0 0 0;
  border-radius: 0;
  opacity: 0;
  transition: all 500ms ease-in-out; /* custom */
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 20px;
  justify-content: center;
}
@media screen and (max-width: 1000px) {
  .sticky-navbar-nav.sticky-navbar-hidden .navigation-box-small .navigation .dropdown-list {
    padding-top: 1vh !important;
    flex-direction: column;
    padding-top: 120px !important;
    gap: 2px !important;
    justify-content: flex-start;
  }
}
@media screen and (max-width: 1000px) {
  .sticky-navbar-nav.sticky-navbar-hidden .navigation-box-small .navigation .dropdown-list .sublink a {
    color: #dd08aa !important;
    font-weight: 100;
  }
}
.sticky-navbar-nav.sticky-navbar-hidden .navigation-box-small .navigation .dropdown-list a {
  text-decoration: none;
  color: #000;
  font-size: 15px;
  font-weight: 300;
}
@media screen and (max-width: 1000px) {
  .sticky-navbar-nav.sticky-navbar-hidden .navigation-box-small .navigation .dropdown-list a {
    font-family: "Antonio", sans-serif;
    font-weight: bold;
    font-size: clamp(20px, 5vh, 45px) !important;
  }
}
.sticky-navbar-nav.sticky-navbar-hidden .navigation-box-small .navigation .dropdown-list a:hover {
  color: #dd08aa;
}
.sticky-navbar-nav.sticky-navbar-hidden .navigation-box-small .navigation .dropdown-list.visible {
  z-index: 9;
  top: 0px;
  opacity: 0;
  pointer-events: auto;
  padding-bottom: 30px;
}

.sticky-navbar-nav.sticky-navbar-middle .navigation-box-small .navigation .dropdown-list {
  position: absolute;
  top: -50px;
  right: 0;
  left: auto;
  background-image: url("../img/bg.jpg");
  z-index: 99;
  padding: 20px 30px;
  list-style-type: none;
  margin: 0 0 0 0;
  border-radius: 0;
  opacity: 0;
  transition: all 500ms ease-in-out; /* custom */
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: flex-start;
  width: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  padding-bottom: 20px;
  justify-content: center;
}
@media screen and (max-width: 1000px) {
  .sticky-navbar-nav.sticky-navbar-middle .navigation-box-small .navigation .dropdown-list {
    padding-top: 1vh !important;
    flex-direction: column;
    padding-top: 120px !important;
    gap: 2px !important;
    justify-content: flex-start;
  }
}
.sticky-navbar-nav.sticky-navbar-middle .navigation-box-small .navigation .dropdown-list a {
  text-decoration: none;
  color: #ffffff;
  font-size: 15px;
  font-weight: 300;
}
@media screen and (max-width: 1000px) {
  .sticky-navbar-nav.sticky-navbar-middle .navigation-box-small .navigation .dropdown-list a {
    font-family: "Antonio", sans-serif;
    font-weight: bold;
    font-size: clamp(20px, 5vh, 45px) !important;
  }
}
.sticky-navbar-nav.sticky-navbar-middle .navigation-box-small .navigation .dropdown-list a:hover {
  color: #dd08aa;
}
@media screen and (max-width: 1000px) {
  .sticky-navbar-nav.sticky-navbar-middle .navigation-box-small .navigation .dropdown-list .sublink a {
    color: #dd08aa !important;
    font-weight: 100;
  }
}
.sticky-navbar-nav.sticky-navbar-middle .navigation-box-small .navigation .dropdown-list.visible {
  z-index: 0;
  top: 0px;
  opacity: 1;
  pointer-events: auto;
}

.navigation-box-small {
  height: 62px;
  margin-top: 0px;
  transition: all ease 1s;
  width: 100%;
  z-index: 99;
}
.navigation-box-small .navigation {
  width: 100%;
  margin: auto;
  display: flex;
  color: #fff;
}
.navigation-box-small .navigation ul {
  gap: 2vw !important;
}
@media screen and (min-width: 1000px) {
  .navigation-box-small .navigation ul li {
    padding-top: 0 !important;
  }
}
@media screen and (min-width: 1000px) {
  .navigation-box-small .navigation ul li a {
    font-weight: bold !important;
    font-size: 15px;
  }
}
.navigation-box-small .navigation .burger {
  pointer-events: all;
  width: 40px;
  height: 40px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 16px !important;
  bottom: 0;
  left: auto;
  right: 20px;
  margin: auto;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  z-index: 999;
  background: transparent;
  border: 0;
}
@media screen and (min-width: 1000px) {
  .navigation-box-small .navigation .burger {
    top: 10px;
    right: 20px;
    margin: 0;
  }
}
.navigation-box-small .navigation .burger .stick {
  width: 40px;
  height: 4px; /* Zmieniłem również wysokość kija na 4px zamiast 8px */
  border-radius: 2px; /* Zmieniłem promień zaokrąglenia na 2px zamiast 4px */
  margin-bottom: 5px; /* Zmniejszyłem odstęp do 8px zamiast 15px */
  background-color: #00FFC4;
  display: inline-block;
}
.navigation-box-small .navigation .burger .stick:last-child {
  margin-bottom: 0px;
}
.navigation-box-small .navigation .burger .stick.open {
  margin-bottom: 8px;
}
.navigation-box-small .navigation .burger .stick.open.stick-1 {
  animation: stick-1-open 0.6s ease-out forwards;
}
.navigation-box-small .navigation .burger .stick.open.stick-2 {
  animation: stick-2-open 0.6s linear forwards;
}
.navigation-box-small .navigation .burger .stick.open.stick-3 {
  animation: stick-3-open 0.6s linear forwards;
}
.navigation-box-small .navigation .burger .stick.close.stick-1 {
  animation: stick-1-close 0.6s ease-out forwards;
}
.navigation-box-small .navigation .burger .stick.close.stick-2 {
  animation: stick-2-close 0.6s ease-out forwards;
}
.navigation-box-small .navigation .burger .stick.close.stick-3 {
  animation: stick-3-close 0.6s ease-out forwards;
}
@keyframes stick-1-open {
  0% {
    width: 40px;
  }
  40% {
    background-color: #dd08aa;
    width: 4px; /* Zmniejszyłem szerokość do 4px */
    transform: translate(20px, 0px); /* Przesunąłem o połowę zmniejszonej szerokości */
  }
  75%, 80% {
    width: 4px;
    transform: translate(20px, -20px); /* Przesunąłem o połowę zmniejszonej szerokości */
    animation-timing-function: cubic-bezier(0, 1, 1, 1);
  }
  100% {
    background-color: #dd08aa;
    width: 4px;
    transform: translate(17px, 24.5px); /* Dostosowałem przesunięcie do zmniejszonej szerokości */
  }
}
@keyframes stick-2-open {
  80% {
    background-color: #00FFC4;
    transform: translate(0px, 0px) rotate(0deg);
  }
  100% {
    background-color: #dd08aa;
    transform: translate(4px, 0px) rotate(45deg); /* Przesunąłem o połowę zmniejszonej szerokości */
  }
}
@keyframes stick-3-open {
  80% {
    background-color: #00FFC4;
    transform: translate(0px, 0px) rotate(0deg);
  }
  100% {
    background-color: #dd08aa;
    transform: translate(4px, -12px) rotate(-45deg); /* Przesunąłem o połowę zmniejszonej szerokości */
  }
}
@keyframes stick-1-close {
  0%, 70% {
    width: 0px;
  }
  100% {
    width: 40px;
    transform: translate(0, 0);
  }
}
@keyframes stick-2-close {
  0% {
    background-color: #dd08aa;
    width: 40px;
  }
  20% {
    background-color: #dd08aa;
    width: 4px;
    transform: translate(0, 0px) rotate(45deg);
  }
  40% {
    background-color: #00FFC4;
    width: 0px;
  }
  65% {
    transform: translate(0, -35px);
    animation-timing-function: cubic-bezier(0, 1, 1, 1);
  }
  80% {
    width: 0px;
  }
  100% {
    width: 40px;
    transform: translate(0, 0px);
  }
}
@keyframes stick-3-close {
  0% {
    background-color: #dd08aa;
    width: 40px;
  }
  20% {
    background-color: #dd08aa;
    width: 4px;
    transform: translate(0, -12px) rotate(-45deg); /* Przesunąłem o połowę zmniejszonej szerokości */
  }
  40% {
    background-color: #00FFC4;
  }
  65% {
    transform: translate(0, -47px); /* Przesunąłem o połowę zmniejszonej szerokości */
    animation-timing-function: cubic-bezier(0, 1, 1, 1);
  }
  90% {
    width: 4px; /* Przywróciłem szerokość do 4px zamiast 8px */
  }
  100% {
    width: 40px;
    transform: translate(0, 0px);
  }
}

.navigation .logo {
  cursor: pointer;
  transition: all ease 0.2s;
}
.navigation .logo:hover {
  opacity: 0.75;
}

.navigation-box.isPage {
  background-image: url("../img/bg.jpg") !important;
  max-width: unset;
}

.sticky-navbar-nav .navigation-box-small .navigation ul li a {
  transition: color 0.2s ease;
}
.sticky-navbar-nav .navigation-box-small .navigation ul li a:hover {
  color: #00ffc4 !important;
}

@media screen and (min-width: 600px) {
  .sticky-navbar-nav .navigation-box-small .navigation ul .sublink {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  .sticky-navbar-nav .navigation-box-small .navigation ul .nav-social {
    display: none !important;
  }
}
.sticky-navbar-nav .navigation-box-small .navigation ul .nav-social ul {
  gap: 5px !important;
}

.submenu {
  position: relative;
}
@media screen and (max-width: 950px) {
  .submenu {
    position: unset;
  }
}
@media screen and (max-width: 950px) {
  .submenu .dropdown-list {
    left: 0 !important;
    right: 0 !important;
    margin: auto !important;
  }
}

@media screen and (max-width: 950px) {
  .navigation-box .navigation ul {
    position: unset;
  }
}

.navigation-box .navigation {
  height: 90px;
}/*# sourceMappingURL=style.css.map */