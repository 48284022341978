.content1150{
  width: 100%;
  max-width: 1150px;
  margin: auto;
  font-family: "Poppins", sans-serif;

  @media screen and (max-width: 1111px) {
    flex-wrap: wrap;
  }
  @media screen and (max-width: 600px) {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 25px;;
  }
  .awards{
    padding-bottom: 70px;
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: flex-end;

    @media screen and (max-width: 1150px) {
      display: grid; 
      grid-template-columns: 1fr 1fr; 
      grid-template-rows: 1fr; 
      gap: 0px 0px; 
      grid-template-areas: 
        ". ."; 
    }
    @media screen and (max-width: 1150px) {
      padding-bottom: 70px;
      gap: 50px 0px; 
    }
    @media screen and (max-width: 600px) {
      padding-bottom: 0px;
      gap: 6px 0px; 
      max-width: 93%;
      margin: auto;
    }
    .award-item{
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 30px;
      gap: 0px 0px;
      grid-template-areas:
          "."
          ".";
      justify-content: center;
      justify-items: center;
      align-items: end;
      font-family: "Antonio", sans-serif;
      font-weight: 200;
      font-style: normal;
      img{
        width: 80%;
        max-width: 200px;
        @media screen and (max-width: 600px) {
          width: 100%;
        }
      } 
      span{
        @media screen and (max-width: 600px) {
          opacity: 0.75;
          position: relative;
          top: -14px;
          font-size: 13px;
        }
      }     
    }

  }
}