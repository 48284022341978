.content1150_casestudies.mobile{
  @media screen and (max-width: 600px) {
    padding: 0;
  } 
  .casestudies{
    padding-bottom: 0;
    .casestudies_lead{
      @media screen and (max-width: 600px) {
        padding: 0 25px;
        font-size: 28px;
        font-weight: 500;
      } 
    }
    .casestudies_bullets{
      .casestudies_bullets_main{
        @media screen and (max-width: 600px) {
          background-color: #000;
          border-radius: 0;
          padding: 10px 25px 20px 25px;
          margin-bottom: 15px;
        }        
        .blink{
          @media screen and (max-width: 600px) {
            display: none;
          } 
        }
        .casestudies_bullets_main_points{
          align-items: flex-start !important;
          .bullet_title{
            font-size: 28px;
            line-height: 1.2;
            padding: 0;
          }          
        }

      }
      .casestudies_bullets_shadow{
        @media screen and (max-width: 600px) {
          display: none;
        } 
      }
    }
  }
}

.content1150_casestudies{
  width: 100%;
  max-width: 1150px;
  margin: auto;
  font-family: "Poppins", sans-serif;

  @media screen and (max-width: 1470px) {
    width: 100%;
    padding: 0 25px;
  }
  
  .casestudies{
    padding-bottom: 80px;
    position: relative;

    .casestudies_title{
      font-family: "Antonio", sans-serif;
      font-optical-sizing: auto;
      font-weight: 700;
      font-style: normal;
      font-size: 90px;
      text-transform: uppercase;
      margin-bottom: 5px;
      display: flex;
      margin-top: 2.5cm;
      gap: 5px;
      
      @media screen and (max-width: 600px) {
        font-size: 50px;
        gap: 0px;
        margin-top: 15px;
      }

      .cs_holder{
        width: 10px;
        height: 10px;
        position: absolute;
        background: transparent;
        top: 70px;
      }

      img{
        @media screen and (max-width: 600px) {
          max-width: 64px;
          position: relative;
          top: 3px;
        }
      }

      .casestudies_icon{
        max-width: 104px;
        position: relative;
        top: 14px;
        @media screen and (max-width: 600px) {
          max-width: 62px;
          top: 6px;
          left: -1px;
        }
      }
    }

    .info{
      font-size: 25px;
      margin-top: -10px;
      @media screen and (max-width: 600px) {
        font-size: 20px;
        line-height: 25px;
        margin-top: 5px;
      }
    }

    .casestudies_lead{
      font-family: "Poppins", sans-serif;
      font-weight: 300;
      font-size: 50px;
      display: flex;
      flex-direction: column;
      line-height: 1.3;
      margin-bottom: 15px;
      @media screen and (max-width: 1024px) {
        font-size: 30px;
      }  
    }

    .samolot{
      top: 0px;
      right: 0;
      position: absolute;
      pointer-events: none;

      img{
        width: 95px;
        @media screen and (max-width: 600px) {
          max-width: 120px;
        }
      }

      @media screen and (max-width: 600px) {
        top: -126px;
        right: 4px;
        left: auto;
        position: absolute;
        pointer-events: none;
      }
    }

    .casestudies_bullets{
      position: relative;
      .casestudies_bullets_main{
        position: relative;
        z-index: 0;
        background-color: #000;
        border-radius: 13px;
        min-height: 74px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 20px;

        .blink {
          background-image: url('./img/reflec.png');
          clip-path: polygon(0 0, 100% 0, 100% 50%, 0% 100%);
          width: 62px;
          height: 67px;
          position: absolute;
          left: 0;
          background-repeat: no-repeat;
          top: 0;
        }
        .casestudies_bullets_main_content{
          display: flex;
          gap: 30px;
          justify-content: space-between;
          width: 100%;
          text-decoration: none;
          color: #fff;

          @media screen and (max-width: 1024px) {
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;
            justify-content: flex-start;
            padding: 20px 0;
            gap: 50px;
          }

          .casestudies_bullets_main_points{
            position: relative;
            z-index: 2;
            display: flex;
            gap: 10px;
            font-family: "Open Sans", sans-serif;
            font-size: 16px;
            font-weight: bold;
            align-items: center !important;
            justify-content: space-between;
            width: 100%;

            @media screen and (max-width: 1024px) {
              width: 100%;
            }
            @media screen and (max-width: 600px) {
              flex-direction: column;
              align-items: flex-end !important;
              gap: 15px;
            }

            .casestudies_bullets_main_points_row{
              display: flex;
              align-items: center;
              gap: 17px;
              @media screen and (max-width: 600px) {
                width: 100%;
              }  
            }

            .bullet_title{
              line-height: 0.85;
              font-size: clamp(20px, 3vw, 41px); 
              font-family: "Poppins", sans-serif;
              
              .green{
                color: #00ffc4;
              }
              
              @media screen and (max-width: 1024px) {
                font-size: 28px !important; 
                line-height: 1.2;
              }         
            }
          }
        }
      }
      .casestudies_bullets_shadow{
        position: absolute;
        background: rgb(122, 34, 154);
        background: linear-gradient(0deg, rgb(122, 34, 154) 0%, rgb(255, 0, 93) 100%);
        width: 100%;
        min-height: 74px;
        border-radius: 13px;
        top: 7px;
        left: 9px;
        z-index: -1;
        box-shadow: 4px 9px 13px rgb(0 0 0 / 20%);
      }
    }
    .casestudies_grid{
      margin-top: 10px;
      display: grid; 
      grid-template-columns: 1fr 1fr 1fr; 
      grid-template-rows: 1fr 1fr; 
      gap: 10px; 
      grid-template-areas: 
        ". . ."
        ". . ."; 
      @media screen and (max-width: 1110px) {
        grid-template-columns: 1fr 1fr; 
        grid-template-areas: 
          ". ."; 
      }  
      @media screen and (max-width: 600px) {
        grid-template-columns: 1fr; 
        grid-template-areas: 
          "."; 
      }  

      .casesturies_item:hover .item_content{
        background-color: #d7d7d7;
      }

      .casesturies_item{
        cursor: pointer;
        transition: all ease-in-out .25s;
        &:active {
          @media screen and (max-width: 600px) {
            opacity: .5;
          }
        }

        @media screen and (max-width:600px) {
          padding: 0px;
          display: flex;
          gap: 5px;
          max-height: 115px;
        }


        .item_img{
          img{
            position: relative;
            width: 100%;
            height: auto;
            cursor: pointer;
            transition: all ease-in-out .25s;
            &:hover{
              opacity: .7;

            }
            @media screen and (max-width:600px) {
              width: auto;
              height: 115px;
            }
          }
        }
        .item_content{
          background-color: #EDEDED;
          padding: 10px 20px 15px 20px;
          position: relative;
          transition: all ease-in-out .25s;

          &:hover{
            background-color: #d7d7d7;
          }

          @media screen and (max-width:600px) {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            padding: 10px 10px 10px 14px;
          }
          .item_content_title{
            font-family: "Antonio", sans-serif;
            font-weight: 700;
            font-size: 35px;
            text-transform: uppercase;
            margin-bottom: 5px;
            @media screen and (max-width:600px) {
              font-size: 31px;
              text-transform: none;
              letter-spacing: -.8px;
            }
          }
          .item_content_txt{
            font-family: "Open Sans", sans-serif;
            font-size: 14px;
            font-weight: 400;
            white-space: pre-wrap;
            color: #737373;
            @media screen and (max-width: 750px) {
              font-size: 12px;
              min-height: 34px;
            }
            @media screen and (max-width:600px) {
              display: none;
            }
          }
          .item_content_btn{
            position: absolute;
            top: 12px;
            right: 14px;
            @media screen and (max-width:600px) {
              top: 0;
              right: 0;
              position: relative;
            }
            button{
              background-color: transparent;
              border: 0;
              padding: 0;
              transition: all ease .2s;
              cursor: pointer;

              img{
                @media screen and (max-width:600px) {
                  width: 30px;
                }
              }

              &:hover{
                opacity: .7;
              }
            }
          } 
        }
      }
    }
  }
}