.content1150{
  width: 100%;
  max-width: 1150px;
  margin: auto;
  font-family: "Poppins", sans-serif;


  .recommend{
    padding-bottom: 75px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 50px;
    grid-template-areas: ".";
    font-size: 16px;
    font-family: "Open Sans", sans-serif;


    @media screen and (max-width: 1248px) {
      grid-template-columns: 1fr; 
      grid-template-areas: 
        "."; 
    }
    @media screen and (max-width: 1150px) {
      justify-items: center;
    }
    @media screen and (max-width: 600px) {
      padding-bottom: 50px;
      gap: 30px;
    }
    .title{
      display: flex;
      font-family: "Antonio", sans-serif;
      font-optical-sizing: auto;
      font-weight: 700;
      font-style: normal;
      font-size: 90px;
      text-transform: uppercase;
      span{
        white-space: nowrap;
        @media screen and (max-width: 600px) {
          font-size: 50px;
          white-space: unset;
          text-align: center;
          display: flex;
          gap: 15px;
          flex-direction: column;
          align-items: flex-start;
          line-height: 1;
          
        }
      }
      .green{
        color: #00FFC4;
      }
      @media screen and (max-width: 1248px) {
        margin-bottom: 50px;
      }
      @media screen and (max-width: 600px) {
        font-size: 50px;
        width: 100%;
        margin-bottom: 0;
      }
    }  
    .recommend_items {
      display: flex;
      gap: 120px;
      @media screen and (max-width: 1150px) {
        gap: 20px;
      }
      @media screen and (max-width: 600px) {
        flex-direction: column;
        gap: 30px;
      }
      hr.mobile-seperator {
        margin: 0;
        border-top: 2px solid #00ffc4;
        display: none;
        @media screen and (max-width: 600px) {
          display: block;
        }
      }
      .recommend_item{
        display: flex;
        flex-direction: column;
        gap: 30px;
        width: 100%;
        @media screen and (max-width: 1150px) {
          max-width: 400px;
        }
        @media screen and (max-width: 600px) {
          max-width: unset;
          width: 100%;
        }
        .item_title{
          font-family: "Antonio", sans-serif;
          font-weight: 700;
          font-size: 20px;
          display: flex;
          align-items: center;
          gap: 15px;

          img{
            max-width: 86px;
          }

          .item_name{
            display: flex;
            flex-direction: column;
            small{
              text-transform: uppercase;
              font-size: 14px;
              font-family: "Poppins", sans-serif;
              font-weight: 400;
              line-height: 1.1;
              margin-top: 5px;
            }          
          }
  
        }
        .item_txt{
          font-size: 14px;
          position: relative;
          @media screen and (max-width: 600px) {
            font-size: 13px;
            line-height: 1.25;
          }
          img{
            position: absolute;
            left: -80px;

            @media screen and (max-width: 1330px) {
              display: none;
            }
          }
        }
        .item_logo{
          opacity: .7;
          img{
            position: relative;
            left: -24px;
          }
        }      
      }
    }
    .recommend_stars{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
      @media screen and (max-width: 600px) {
        margin-top: 20px;
        align-items: center;
      }
      .score{
        display: flex;
        align-items: flex-end;
  
        @media screen and (max-width: 600px) {
          display: flex;
          align-items: flex-end;
          padding-left: 5px;
        }
        .score_label{
          font-family: "Antonio", sans-serif;
          font-size: 33px;
          font-weight: 300;
          position: relative;
          top: -11px;
          @media screen and (max-width: 600px) {
            font-size: 17px;
          }
        }
        .score_value{
          font-family: "Antonio", sans-serif;
          font-size: 50px;
          padding-left: 10px;
          font-weight: bold;
          position: relative;
          top: -8px;
          @media screen and (max-width: 600px) {
            font-size: 40px;
            top: -5px;
          }
        }
        .score_scope{
          font-family: "Antonio", sans-serif;
          font-size: 40px;
          font-weight: 100;
          position: relative;
          top: -10px;
          opacity: .5;

          @media screen and (max-width: 600px) {
            font-size: 20px;
            position: relative;
            top: -8px;
          }
        }        
      }

      .score_stars{
        margin-top: 10px;
        display: flex;
        justify-content: center;
        @media screen and (max-width: 600px) {
          margin-top: -10px;
        }
        img{
          width: 100%;
          max-width: 300px;
          @media screen and (max-width: 600px) {
            width: 80%;
          }
        }
      }
    }

  }
}