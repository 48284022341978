.mask1 {
  background: transparent;
  width: 11vw;
  height: 85vh;
  position: absolute;
  top: 1%;
  left: 0%;
  z-index: 2;
}
@media screen and (max-width: 1770px) {
  .mask1 {
    width: 9vw;
  }
}
@media screen and (max-width: 1680px) {
  .mask1 {
    width: 7vw;
  }
}
@media screen and (max-width: 1590px) {
  .mask1 {
    width: 5vw;
  }
}
@media screen and (max-width: 1521px) {
  .mask1 {
    width: 3vw;
  }
}
@media screen and (max-width: 1460px) {
  .mask1 {
    width: 1vw;
  }
}
@media screen and (max-width: 1390px) {
  .mask1 {
    display: none !important;
  }
}

.mask2 {
  background: transparent;
  width: 735px;
  height: 12vh;
  position: absolute;
  top: 10%;
  left: 15%;
  z-index: 2;
}
@media screen and (max-width: 1150px) {
  .mask2 {
    width: 100%;
    left: 0;
  }
}

.mask3 {
  background: transparent;
  width: 210px;
  height: 70vh;
  position: absolute;
  top: 1.4%;
  left: auto;
  right: 0%;
  z-index: 2;
}
@media screen and (max-width: 1770px) {
  .mask3 {
    width: 10vw;
  }
}
@media screen and (max-width: 1660px) {
  .mask3 {
    width: 7vw;
  }
}
@media screen and (max-width: 1550px) {
  .mask3 {
    width: 5vw;
  }
}
@media screen and (max-width: 1480px) {
  .mask3 {
    width: 3vw;
  }
}
@media screen and (max-width: 1420px) {
  .mask3 {
    width: 1vw;
  }
}
@media screen and (max-width: 1150px) {
  .mask3 {
    display: none !important;
  }
}

.header {
  width: 100%;
  height: auto;
  position: relative;
  background-image: url("./img/bg.jpg");
  background-size: 1920px auto;
  background-repeat: no-repeat;
  background-position: center bottom;
  padding: 200px 0;
}
@media screen and (max-width: 1135px) {
  .header {
    padding-top: 180px;
    padding-bottom: 180px;
  }
}
@media screen and (max-width: 930px) {
  .header {
    padding-top: 160px;
    padding-bottom: 160px;
  }
}
@media screen and (max-width: 750px) {
  .header {
    padding-top: 130px;
    padding-bottom: 130px;
  }
}
@media screen and (max-width: 650px) {
  .header {
    padding: 90px 0;
  }
}
@media screen and (max-width: 650px) {
  .header {
    background-image: url("./img/mobile-bg.png");
    min-height: unset;
    background-size: cover;
    background-position: center bottom;
  }
}
.header .layer1 {
  position: absolute;
  left: 0;
  top: -250px;
  width: 957px;
  height: 874px;
  transition: all 500ms cubic-bezier(0.145, 0.585, 0.415, 0.9); /* custom */
  transition-timing-function: cubic-bezier(0.145, 0.585, 0.415, 0.9); /* custom */
}
@media screen and (max-width: 1024px) {
  .header .layer1 {
    display: none;
  }
}
.header .layer2 {
  position: absolute;
  top: -248px;
  left: 543px;
  width: 293px;
  height: 361px;
  transition: all 500ms cubic-bezier(0.145, 0.585, 0.415, 0.9);
  transition-timing-function: cubic-bezier(0.145, 0.585, 0.415, 0.9);
}
@media screen and (max-width: 1024px) {
  .header .layer2 {
    display: none;
  }
}
.header .layer3 {
  position: absolute;
  top: -268px;
  right: 60px;
  width: 810px;
  height: 598px;
  transition: all 500ms cubic-bezier(0.145, 0.585, 0.415, 0.9); /* custom */
  transition-timing-function: cubic-bezier(0.145, 0.585, 0.415, 0.9); /* custom */
  margin-left: 0px;
}
@media screen and (max-width: 1024px) {
  .header .layer3 {
    display: none !important;
  }
}
.header .topLayer {
  position: absolute;
  top: 404px;
  right: 0;
  width: 876px;
  height: 589px;
  transition: all ease 1s;
  margin-left: 0px;
}
@media screen and (max-width: 650px) {
  .header .topLayer {
    display: none;
  }
}
.header .container {
  width: 100%;
  max-width: 1150px;
  margin: auto;
  box-sizing: border-box;
  z-index: 1;
  position: relative;
  height: 98%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media screen and (max-width: 1200px) {
  .header .container {
    padding: 0 25px;
  }
}
@media screen and (max-width: 650px) {
  .header .container {
    margin-top: -20px;
  }
}
.header .container .banner {
  display: grid;
  grid-template-columns: 1fr 50%;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: "text .";
  align-items: start;
  height: auto;
  width: 100%;
  position: relative;
}
@media screen and (max-width: 650px) {
  .header .container .banner {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: "empty" "text";
  }
}
.header .container .banner .empty {
  grid-area: empty;
}
@media screen and (max-width: 650px) {
  .header .container .banner .empty .empty-box {
    min-height: 250px;
  }
}
.header .container .banner .text {
  grid-area: text;
}
.header .container .banner .banner_txt h2 {
  font-size: 120px;
  margin: 0;
  line-height: 1;
  color: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
  left: -4px;
  text-wrap: nowrap;
}
.header .container .banner .banner_txt h2 .size150 {
  font-size: 150px;
}
@media screen and (max-width: 1024px) {
  .header .container .banner .banner_txt h2 {
    font-size: 100px;
  }
}
@media screen and (max-width: 930px) {
  .header .container .banner .banner_txt h2 {
    font-size: 80px;
  }
}
@media screen and (max-width: 750px) {
  .header .container .banner .banner_txt h2 {
    font-size: 70px;
  }
}
@media screen and (max-width: 650px) {
  .header .container .banner .banner_txt h2 {
    font-size: 110px;
    opacity: 1;
    top: 0 !important;
    transition: none;
  }
}
@media screen and (max-width: 560px) {
  .header .container .banner .banner_txt h2 {
    font-size: clamp(50px, 19vw, 110px);
  }
}
.header .container .banner .banner_txt h2 .green {
  color: #00FFC4;
  font-size: 150px;
}
@media screen and (max-width: 1024px) {
  .header .container .banner .banner_txt h2 .green {
    font-size: 130px;
  }
}
@media screen and (max-width: 930px) {
  .header .container .banner .banner_txt h2 .green {
    font-size: 100px;
  }
}
@media screen and (max-width: 750px) {
  .header .container .banner .banner_txt h2 .green {
    font-size: 85px;
  }
}
@media screen and (max-width: 650px) {
  .header .container .banner .banner_txt h2 .green {
    font-size: 140px;
  }
}
@media screen and (max-width: 560px) {
  .header .container .banner .banner_txt h2 .green {
    font-size: clamp(80px, 24vw, 140px);
  }
}
.header .container .banner .banner_txt h4 {
  font-size: 39.3px;
  margin: 0;
  line-height: 1;
  color: #fff;
  text-transform: uppercase;
}
@media screen and (max-width: 1024px) {
  .header .container .banner .banner_txt h4 {
    font-size: 33px;
  }
}
@media screen and (max-width: 930px) {
  .header .container .banner .banner_txt h4 {
    font-size: 26px;
  }
}
@media screen and (max-width: 750px) {
  .header .container .banner .banner_txt h4 {
    font-size: 22px;
  }
}
@media screen and (max-width: 650px) {
  .header .container .banner .banner_txt h4 {
    font-size: 36px;
    margin-bottom: 4px;
    opacity: 1;
    top: 0;
  }
}
@media screen and (max-width: 560px) {
  .header .container .banner .banner_txt h4 {
    font-size: clamp(19px, 6vw, 36px);
  }
}
.header .container .banner .banner_txt h5 {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
  margin-top: 30px;
  max-width: 95%;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 26px;
}
@media screen and (max-width: 1024px) {
  .header .container .banner .banner_txt h5 {
    font-size: 14px;
  }
}
@media screen and (max-width: 650px) {
  .header .container .banner .banner_txt h5 {
    font-size: 11px !important;
    line-height: 18px;
    margin-top: 15px;
    max-width: 100%;
    opacity: 1;
    top: 0;
  }
}
@media screen and (max-width: 1170px) {
  .header .container .banner .banner_txt h5 br {
    display: none;
  }
}
.header .container .banner .banner_txt h5 span {
  padding-top: 10px;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: ". .";
  width: 100%;
}
.header .container .banner .banner_txt .btn {
  background: transparent;
  border: 2px solid #00FFC4;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 5px 22px;
  transition: all 0.1s ease;
  cursor: pointer;
  position: relative;
  border-radius: 1px;
}
.header .container .banner .banner_txt .btn:hover {
  background: #fff;
  border-radius: 0;
  border: 2px solid #fff;
  color: #000;
}
@media screen and (max-width: 650px) {
  .header .container .banner .banner_txt .btn {
    position: absolute;
    border-radius: 1px;
    bottom: -27px;
    right: 0;
  }
}
.header .container .banner-cameleon {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: ". graph";
  align-items: start;
  height: auto;
  width: 100%;
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  margin: auto;
}
@media screen and (max-width: 650px) {
  .header .container .banner-cameleon {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: "graph" ".";
    top: 0px;
    padding: 0 25px;
    height: 100%;
  }
}
.header .container .banner-cameleon .graph {
  grid-area: graph;
}
@media screen and (max-width: 650px) {
  .header .container .banner-cameleon .graph {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
.header .container .banner-cameleon .cameleon {
  opacity: 1;
  width: 100%;
  max-width: 718px;
}
@media screen and (max-width: 650px) {
  .header .container .banner-cameleon .cameleon {
    position: relative;
    left: -25px;
  }
}/*# sourceMappingURL=style.css.map */