.cs_baner_ikea{
  background-image: url('./img/baner.png');
  background-position: center top;
  width: 100%;
  min-height: calc(100vh - 125px);
  display: flex;
  background-size: cover;

  @media screen and (max-width: 1520px) {
    background-position: center top;
  }

  @media screen and (max-width: 1200px) {
    background-position: center top;
  }

  @media screen and (max-width: 600px) {
    background-image: url('./img/baner_mobile_2.jpg');
    background-position: center top;
  }
  @media screen and (max-width: 445px) {
    background-size: 138%;
    background-repeat: no-repeat;
    background-color: #059f87;
  }

  .cs_baner_container{
    display: flex;
    width: 100%;
    max-width: 1150px;
    margin: auto;
    flex-direction: column;
    align-items: flex-start;

    @media screen and (max-width: 1400px) {
      width: 100%;
      padding: 25px;
      justify-content: flex-end;
      margin: 0;
    }

    img{
      @media screen and (max-width: 600px) {
        max-width: 150px;
      }
    }

    h2{
      font-size: 80px;
      margin: 10px 0 0 0;
      text-transform: uppercase;
      line-height: 1.16;
      @media screen and (max-width: 600px) {
        font-size: clamp(55px, 8vw, 70px);
        text-shadow: 4px 2px #21ab9b;
        color: #fff;
      }
    }
    h5{
      font-size: 31px;
      margin: 10px 0 0 0;
      font-weight: 400;
      @media screen and (max-width: 1400px) {
        font-size: clamp(25px, 3vw, 31px);
        color: #fff;
        font-size: 19px;
      }
    }
  }
}  
.cs_black_space{
  background-color: black;
  height: 56px;
  width: 100%;
}

.cs_container_1150_ikea{
  width: 100%;
  max-width: 1150px;
  margin: 0 auto;
  padding: 50px 0;
  @media screen and (max-width: 1366px) {
    padding-left: 25px;
    padding-right: 25px;
  }

  .challenge{
    h2.title{
      font-size: 90px;
      margin-top: 20px;
      @media screen and (max-width: 600px) {
        text-align: left;
        font-size: 60px;
        margin-top: 0;
      }
    }
    .cs_poranek_row{
      display: grid; 
      grid-template-columns:1fr; 
      grid-template-rows: 1fr; 
      grid-template-areas: 
        "."; 
      gap: 50px;
      position: relative;
      align-items: start;
      @media screen and (max-width: 1170px) {
        grid-template-columns: 1fr; 
        grid-template-areas: 
          "."; 
      }

      .cs_bullets{
        display: flex;
        gap: 50px;
        width: 100%;
        @media screen and (max-width: 1110px) {
          flex-direction: column;
        }
        @media screen and (max-width: 600px) {
          gap: 20px;
        }
        .cs_bullets_box {
          padding: 40px 40px 40px 52px;
          border-radius: 25px;
          box-shadow: inset 10px 10px 18px rgba(0, 0, 0, 0.24);
          position: relative;
          width: 100%;
          font-size: 18px;
          font-weight: bold;
          font-family: "Poppins", sans-serif;
          @media screen and (max-width: 600px) {
            padding: 25px 25px 25px 33px;
            font-size: 15px;
            line-height: 1.4;
  
            br{
              display: none;
            }
          }
          img {
            position: absolute;
            left: -21px;
            right: auto;
            margin: auto;
            top: 0;
            bottom: 0;
            max-width: 45px;
          }
        }    
      }
      .cs_poranki_img{
        display: flex;
        gap: 10px;
        position: relative;
        @media screen and (max-width: 1170px) {
          flex-wrap: wrap;
          justify-content: center;
        }
        img{
          width: 100%;
          height: auto;
          @media screen and (max-width: 1666px) {
            max-width: 270px;
          }
          @media screen and (max-width: 1422px) {
            max-width: 250px;
          }
          @media screen and (max-width: 1322px) {
            max-width: 200px;
          }
        };
      }      
    }
    
  }

  .realization{
    margin-top: 50px;
    @media screen and (max-width: 600px) {
      display: flex;
      flex-direction: column-reverse;
      margin-bottom: -30vw;
    }
    .realization-points{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
      gap: 50px;
      grid-template-areas: ". .";
      font-family: "Poppins", sans-serif;
      margin-bottom: -10vw;
      @media screen and (max-width: 966px) {
        display: flex;
        flex-direction: column-reverse;
        margin-bottom: -30vw;
      }
      @media screen and (max-width: 600px) {
        gap: 30px;
      }
      .simple-flex{
        display: flex;
        flex-direction: column;
        gap: 50px;
        @media screen and (max-width: 600px) {
          gap: 30px;
        }
      }

      .baner_event{
        img{
          width: 100%;
          position: relative;
          z-index: -1;
        }
      }

      .points_column{
        display: flex;
        gap: 35px;
        flex-direction: column;
        font-size: 20px;

        h2.title{
          font-size: 90px;
          color: #000;
          padding-top: 100px;
          margin: 0;
          @media screen and (max-width: 600px) {
            text-align: left;
            font-size: 60px;
            padding-top: 30px;
          }
        }

        .point{
          display: flex;
          gap: 20px;
          align-items: flex-start;
          @media screen and (max-width: 600px) {
            gap: 5px;
            font-size: 14px;
          }
          .point-bullet{
            @media screen and (max-width: 600px) {
              width: 52px;
              position: relative;
              left: -3px;
            }
          }
          .points-rows{
            display: flex;
            flex-direction: column;
            .hero{
              font-size: 20px;
              padding: 20px;
              @media screen and (max-width: 600px) {
                padding: 0px;
              }
            }          
          }

          .realization-points-points{
            display: flex;
            flex-direction: column;
            position: relative;
            
            .subpoints{
              display: flex;
              flex-direction: column;
              padding: 20px;
              @media screen and (max-width: 600px) {
                padding: 10px 0px;
              }
              p{
                padding: 0;
                margin: 0 0 20px 0;
              }            
            }

          }
        }
      }



    }
  }

  .carusele_poranki{
    .carouselSlide{
      img{
        width: calc(100% - 5px);
        height: auto;
        padding-right: 5px;
      } 
    }


    .carusele-btns{
      display: flex;
      gap: 10px;
      justify-content: center;
      margin-top: 20px;
      button{
        position: relative;
        color: #000;
        width: 40px;
        height: 40px;
        padding: 0;
        border-radius: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover{
          color: #000;
        }
      }      
    }

   
  }

}