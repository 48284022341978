.mask1{
  background: transparent;
  width: 11vw;
  height: 85vh;
  position: absolute;
  top: 1%;
  left: 0%;
  z-index: 2;
  @media screen and (max-width: 1770px) {
    width: 9vw;
  }
  @media screen and (max-width: 1680px) {
    width: 7vw;
  }
  @media screen and (max-width: 1590px) {
    width: 5vw;
  }
  @media screen and (max-width: 1521px) {
    width: 3vw;
  }
  @media screen and (max-width: 1460px) {
    width: 1vw;
  }
  @media screen and (max-width: 1390px) {
    display: none !important;
  }
}
.mask2{
  background: transparent;
  width: 735px;
  height: 12vh;
  position: absolute;
  top: 10%;
  left: 15%;
  z-index: 2;
  @media screen and (max-width: 1150px) {
    width: 100%;
    left: 0;
  }
}

.mask3{
  background: transparent;
  width: 210px;
  height: 70vh;
  position: absolute;
  top: 1.4%;
  left: auto;
  right: 0%;
  z-index: 2;
  @media screen and (max-width: 1770px) {
    width: 10vw;
  }
  @media screen and (max-width: 1660px) {
    width: 7vw;
  }
  @media screen and (max-width: 1550px) {
    width: 5vw;
  }
  @media screen and (max-width: 1480px) {
    width: 3vw;
  }
  @media screen and (max-width: 1420px) {
    width: 1vw;
  }
  @media screen and (max-width: 1150px) {
    display: none !important;
  }
}

.header{
  width: 100%;
  height: auto;
  position: relative;
  background-image: url('./img/bg.jpg');
  background-size: 1920px auto;
  background-repeat: no-repeat;
  background-position: center bottom;
  padding: 200px 0;

  @media screen and (max-width: 1135px) {
    padding-top: 180px;
    padding-bottom: 180px;
  }
  @media screen and (max-width: 930px) {
    padding-top: 160px;
    padding-bottom: 160px;
  }
  @media screen and (max-width: 750px) {
    padding-top: 130px;
    padding-bottom: 130px;
  }
  @media screen and (max-width: 650px) {
    padding: 90px 0
  }
  @media screen and (max-width: 560px) {

  }

  @media screen and (max-width: 650px) {
    background-image: url('./img/mobile-bg.png');
    min-height: unset;
    background-size: cover;
    background-position: center bottom;
  }

  .layer1{
    position: absolute;
    left: 0;
    top: -250px;
    width: 957px;
    height: 874px;
    // transition: all ease 1s;
    transition: all 500ms cubic-bezier(0.145, 0.585, 0.415, 0.900); /* custom */
    transition-timing-function: cubic-bezier(0.145, 0.585, 0.415, 0.900); /* custom */
    @media screen and (max-width: 1024px) {
      display: none;
    }


  }
  .layer2{
    position: absolute;
    top: -248px;
    left: 543px;
    width: 293px;
    height: 361px;
    transition: all 500ms cubic-bezier(0.145, 0.585, 0.415, 0.9);
    transition-timing-function: cubic-bezier(0.145, 0.585, 0.415, 0.9);
    @media screen and (max-width: 1024px) {
      display: none;
    }

  }
  .layer3{
    position: absolute;
    top: -268px;
    right: 60px;
    width: 810px;
    height: 598px;
    transition: all 500ms cubic-bezier(0.145, 0.585, 0.415, 0.900); /* custom */
    transition-timing-function: cubic-bezier(0.145, 0.585, 0.415, 0.900); /* custom */
    margin-left: 0px;

    @media screen and (max-width: 1024px) {
      display: none !important;
    }


  }

  .topLayer{
    position: absolute;
    top: 404px;
    right: 0;
    width: 876px;
    height: 589px;
    transition: all ease 1s;
    margin-left: 0px;
    @media screen and (max-width: 650px) {
      display: none;
    }
  }


  .container{
    width: 100%;
    max-width: 1150px;
    margin: auto;
    box-sizing: border-box;
    z-index: 1;
    position: relative;
    height: 98%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 1200px) {
      padding: 0 25px;
    }

    @media screen and (max-width: 650px) {
      margin-top: -20px;
    }

    .banner{
      display: grid;
      grid-template-columns: 1fr 50%;
      grid-template-rows: 1fr;
      gap: 0px 0px;
      grid-template-areas: "text .";
      align-items: start;
      height: auto;
      width: 100%;
      position: relative;

      @media screen and (max-width: 650px) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr; 
        grid-template-areas: 
        "empty"
        "text"; 
      }
      .empty {
        grid-area: empty; 
        .empty-box{
          @media screen and (max-width: 650px) {
            min-height: 250px;
          }
        }
      }
      .text { grid-area: text; }

      .banner_txt{
        h2{
          font-size: 120px;
          margin: 0;
          line-height: 1;
          color: #fff;
          display: flex;
          flex-direction: column;
          position: relative;
          left: -4px;
          text-wrap: nowrap;
          .size150{
            font-size: 150px;
          }
          @media screen and (max-width: 1024px) {
            font-size: 100px;
          }
          @media screen and (max-width: 930px) {
            font-size: 80px;
          }
          @media screen and (max-width: 750px) {
            font-size: 70px;
          }
          @media screen and (max-width: 650px) {
            font-size: 110px;
            opacity: 1;
            top: 0 !important;
            transition: none;
          }
          @media screen and (max-width: 560px) {
            font-size: clamp(50px, 19vw, 110px);
          }
          .green{
            color: #00FFC4;
            font-size: 150px;
            @media screen and (max-width: 1024px) {
              font-size: 130px;
            }
            @media screen and (max-width: 930px) {
              font-size: 100px;
            }
            @media screen and (max-width: 750px) {
              font-size: 85px;
            }
            @media screen and (max-width: 650px) {
              font-size: 140px
            }
            @media screen and (max-width: 560px) {
              font-size: clamp(80px, 24vw, 140px)
            }
          }


        }
        h4 {
          font-size: 39.3px;
          margin: 0;
          line-height: 1;
          color: #fff;
          text-transform: uppercase;

          @media screen and (max-width: 1024px) {
            font-size: 33px;
          }
          @media screen and (max-width: 930px) {
            font-size: 26px;
          }
          @media screen and (max-width: 750px) {
            font-size: 22px;
          }
          @media screen and (max-width: 650px) {
            font-size: 36px;
            margin-bottom: 4px;
            opacity: 1;
            top: 0;
          }
          @media screen and (max-width: 560px) {
            font-size: clamp(19px, 6vw, 36px)
          }

        }
        h5 {
          color: #fff;
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 0;
          margin-top: 30px;
          max-width: 95%;
          min-height: 100px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 26px;

          @media screen and (max-width: 1024px) {
            font-size: 14px
          }
          @media screen and (max-width: 650px) {
            font-size: 11px !important;
            line-height: 18px;
            margin-top: 15px;
            max-width: 100%;
            opacity: 1;
            top: 0;
          }
          br{
            @media screen and (max-width: 1170px) {
              display: none;
            }
          }
          span{
            padding-top: 10px;
            display: grid;
            grid-template-columns: 1fr auto;
            grid-template-rows: 1fr;
            gap: 0px 0px;
            grid-template-areas: ". .";
            width: 100%;
          }
        }
        .btn{
          background: transparent;
          border: 2px solid #00FFC4;
          color: #fff;
          font-family: "Poppins", sans-serif;
          font-size: 16px;
          font-weight: 700;
          text-transform: uppercase;
          padding: 5px 22px;
          transition: all 0.1s ease;
          cursor: pointer;
          position: relative;
          border-radius: 1px;

          &:hover{
            background: #fff;
            border-radius: 0;
            border: 2px solid #fff;
            color: #000;
          }
          @media screen and (max-width: 650px) {
            position: absolute;
            border-radius: 1px;
            bottom: -27px;
            right: 0;
          }
        }      
      }

    }

    .banner-cameleon{
      display: grid;
      grid-template-columns: 1fr auto;
      grid-template-rows: 1fr;
      gap: 0px 0px;
      grid-template-areas: ". graph";
      align-items: start;
      height: auto;
      width: 100%;
      position: absolute;
      z-index: -1;
      left: 0;
      right: 0;
      margin: auto;


      @media screen and (max-width: 650px) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        grid-template-areas: 
        "graph"
        "."; 
        top: 0px;
        padding: 0 25px;
        height: 100%;
      }


      .graph { 
        grid-area: graph;
        @media screen and (max-width: 650px) {
          display: flex;
          flex-direction: row;
          justify-content: center;
        }
      }

      .cameleon{
        opacity: 1;
        width: 100%;
        max-width: 718px;

        @media screen and (max-width: 1135px) {

        }
        @media screen and (max-width: 930px) {

        }
        @media screen and (max-width: 750px) {

        }
        @media screen and (max-width: 650px) {
          position: relative;
          left: -25px;
        }
        @media screen and (max-width: 560px) {

        }



      }
    }
  }  
}

 


  // .header{
  //   @media screen and (max-height: 650px) and (max-width: 1050px) {
  //     height: 110vh !important;
  //   }
  //   h4{
  //     @media screen and (max-height: 650px) and (max-width: 1050px) {
  //       font-size: 15px !important;
  //       margin-top: 10px !important;
  //       opacity: 1 !important;
  //     }
  //   }
  //   h2{
  //     @media screen and (max-height: 650px) and (max-width: 1050px) {
  //       font-size: 55px !important;
  //       opacity: 1 !important;
  //     }
  //     span{
  //       @media screen and (max-height: 650px) and (max-width: 1050px) {
  //         font-size: 55px !important;
  //         opacity: 1 !important;
  //       }
  //     }
  //   }
  //   h5{
  //     @media screen and (max-height: 650px) and (max-width: 1050px) {
  //       font-size: 11px !important;
  //       margin-top: 10px !important;
  //       line-height: 1.2;
  //       opacity: 1 !important;
  //     }
  //     br{
  //       @media screen and (max-height: 650px) and (max-width: 1050px) {
  //         display: none;
  //       } 
  //     }
  //   }
  // }
