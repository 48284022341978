.social {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 2px !important;
}
.social a {
  color: #fff;
  font-size: 23px !important;
  transition: all ease 0.55s;
}
.social a:hover {
  color: #00FFC4;
}

.sticky-navbar-nav .social a {
  font-size: 20px !important;
}/*# sourceMappingURL=style.css.map */