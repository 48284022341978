.mask{
  background-image: url('./img/mask.png');
  height: 477px;
  width: 100%;
  position: absolute;
  top: 0;
  background-repeat: no-repeat;
  @media screen and (max-width: 600px) {
    height: 100% !important;
    max-height: 170px !important;
  }
}
.footer{
  width: 100%;
  height: auto;
  min-height: 477px;
  max-height: 900px;
  background-image: url('../../components/img/bg.jpg');
  margin-bottom: 150px;
  background-size: cover;
  display: flex;
  padding: 80px 0 20px 0;
  position: relative;
  clip-path: path("M1920,1531.9H-.7S-1.7,158.9-1.7,84.2C113.2,59.4,537.9,19.7,959,16.9c502.5-3.4,961,68.9,961,68.9v1446.1Z");

  @media screen and (max-width: 600px) {
    max-height: unset;
    min-height: auto;
    padding-top: 60px;
  }

  .mask{
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    z-index: 2;
    @media screen and (max-width: 600px) {
      height: 100% !important;
      max-height: 170px !important;
    }
  }
  // .mask2{
  //   position: absolute;
  //   top: 0;
  //   width: 100%;
  //   left: 0;
  //   z-index: 1;
  // }

  .footer_container{
    position: relative;
    max-width: 1150px;
    width: 100%;
    margin: 0 auto;
    padding: 50px 0;

    @media screen and (max-width: 1366px) {
      padding: 50px 25px;
    }
    @media screen and (max-width: 600px) {
      padding: 50px 25px 0px 25px;
    }

    .footer_title-row{
      display: flex;
      gap: 15px;
      .footer-contact-logo_mobile{
        display: inline;
        position: relative;
        top: -20px;
        img{
          max-width: 90px;
          margin-bottom: 0;
          @media screen and (max-width: 600px) {
            max-width: 61px;
          }
        }
      }
      .footer_title{
        display: flex;
        flex-direction: row;
        font-family: "Antonio", sans-serif;
        font-optical-sizing: auto;
        font-weight: 700;
        font-style: normal;
        font-size: 50px;
        text-transform: uppercase;
        line-height: 1;
        margin-bottom: 30px;
        color: #fff;
        gap: 13px;

        .green{
          color: #00FFC4;
        }

        @media screen and (max-width: 600px) {
          font-size: 25px;
          flex-direction: row;
          margin-bottom: 40px;
          gap: 5px;
        }

      }
    }



    .footer-grid{
      display: flex;
      justify-content: space-between;
      font-family: "Open Sans", sans-serif;
      font-weight: 400;
      @media screen and (max-width: 600px) {
        flex-direction: column;
      }
      .footer-contact{
        color: #fff;
        .footer-contact-grid{
          display: flex;
          gap: 30px;
          @media screen and (max-width: 600px) {
            flex-direction: column;
          }
          .footer-contact-logo{
            display: none;
            img{
              max-width: 100px;
            }
          }
          .footer-contact-info{
            font-size: 16px;
            @media screen and (max-width: 600px) {
              font-size: 12px;
            }

            .other-logos{
              margin-top: 36px;
              @media screen and (max-width: 600px) {
                margin-top: 10px;
              }
             
              img{
                @media screen and (max-width: 600px) {
                 max-width: 60vw;
                }
              }
            }

            .footer-contact-flex{
              display: flex;
              gap: 30px;
              margin-top: 10px;
              @media screen and (max-width: 600px) {
                margin-top: 3px;
                gap: 10px;
                line-height: .5;
               }
            }
          }
        }
      }
      .footer-menu{
        ul{
          border-left: 3px solid #00FFC4;
          display: flex;
          flex-direction: column;
          gap: 10px;
          padding: 15px 0 15px 40px;

          @media screen and (max-width: 600px) {
            margin: 2px 0 10px 0;
            padding: 0px 0 0 0;
            border: 0;
            border-left: 0;
            flex-direction: row;
            font-size: 11px;
            list-style: none;
          }

          li{
            a{
              color: #fff;
              text-decoration: none;
            }
          }
        }
      }   
    }
    .copyright{
      color: #7E7E7E;
      font-family: "Antonio", sans-serif;
      font-size: 12px;
      display: flex;
      justify-content: center;
      margin-top: 50px;
      @media screen and (max-width: 600px) {
        margin-top: -5px;
        justify-content: flex-start;
      }
    }
  }
}

.mobileFooterRow {
  @media screen and (max-width: 600px) {
    display: flex;
    gap: 20px;
  }
}