.cs_baner_vw{
  background-image: url('./img/baner.png');
  background-position: center;
  min-height: calc(100vh - 125px);
  display: flex;
  align-items: flex-end;
  background-size: cover;

  @media screen and (max-width: 600px) {
    background-image: url('./img/vw_mobile.jpg');
    background-position: right;
  }

  .cs_baner_grid{
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas: ". .";
    position: relative;
    min-height: 200px;
    height: auto;
    align-items: center;

    @media screen and (max-width: 600px) {
      display: block;
    }
    &:nth-child(2){
      @media screen and (max-width: 600px) {
        display: none;
      }
    }

    .cs_baner_container{
      color: #fff;
      background: rgba(0, 0, 0, 0.65);
      min-height: 400px;

      @media screen and (max-width: 1115px) {
        background:transparent;
      }
      @media screen and (max-width: 600px) {
        display: inline;
      }


      .cs_baner_content{
        position: absolute;
        width: 100%;
        max-width: 1150px;
        margin: auto;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        gap: 0px 0px;
        grid-template-areas: ". .";

        
        @media screen and (max-width: 1400px) {
          width: 100%;
          padding: 0 25px;
        }

        @media screen and (max-width: 1115px) {
          padding-left: 0;
        }

        @media screen and (max-width: 600px) {
          display: block;
          position: relative;
          padding: 0;
          margin: 0;
        }

        .logo_mobile{
          position: relative;
          left: 25px;
          max-width: 60px;
          top: -10px;
          display: none;
          @media screen and (max-width: 600px) {
            display: block;
          }
        }

        .cs_baner_content_txt{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          @media screen and (max-width: 1115px) {
            background: rgba(0, 0, 0, 0.65);
            padding: 25px;
          }
          @media screen and (max-width: 600px) {
            margin-bottom: 0px;
          }


          img{
            @media screen and (max-width: 800px) {
              width: clamp(200px, 8vw, 500px);
            }
            @media screen and (max-width: 600px) {
              display: none;
            }
          }

          h2{
            font-size: 75px;
            margin: 0 0 30px 0;
            text-transform: uppercase;
            line-height: 1.16;
            @media screen and (max-width: 800px) {
              font-size: clamp(55px, 8vw, 70px);
            }
            @media screen and (max-width: 600px) {
              margin: 0;
              font-size: 50px;
            }
          }
          h5{
            font-size: 19px;
            margin: 10px 0 0 0;
            font-weight: 400;
            display: none;
            @media screen and (max-width: 600px) {
              display: block;
            }
          }
        }
      }
    }    
  }
}  
.cs_black_space{
  background-color: black;
  height: 56px;
  width: 100%;
}

.cs_container_1150_vw{
  width: 100%;
  max-width: 1150px;
  margin: 0 auto;
  padding: 50px 0;
  @media screen and (max-width: 1366px) {
    padding-left: 25px;
    padding-right: 25px;
  }

  .challenge{
    h2.title{
      text-transform: uppercase;
      font-size: 90px;
      margin-top: 20px;
      @media screen and (max-width: 850px) {
        text-align: left;
        font-size: 60px;
        margin-top: 0;
      }
    }
    .cs_bullets{
      display: flex;
      gap: 50px;
      width: 100%;
      @media screen and (max-width: 1110px) {
        flex-direction: column;
      }
      @media screen and (max-width: 600px) {
        gap: 20px;
      }
      .cs_bullets_box {
        padding: 40px 40px 40px 52px;
        border-radius: 25px;
        box-shadow: inset 10px 10px 18px rgba(0, 0, 0, 0.24);
        position: relative;
        width: 100%;
        font-size: 18px;
        font-weight: bold;
        font-family: "Poppins", sans-serif;
        @media screen and (max-width: 600px) {
          padding: 25px 25px 25px 33px;
          font-size: 15px;
          line-height: 1.4;

          br{
            display: none;
          }
        }
        img {
          position: absolute;
          left: -21px;
          right: auto;
          margin: auto;
          top: 0;
          bottom: 0;
          max-width: 45px;
        }
      }    
    }    
  }

  .realization{
    margin-top: 100px;
    padding-bottom: 50px;
    @media screen and (max-width: 600px) {
      margin-top: 0px;
    }
    .realization-points{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
      gap: 50px;
      grid-template-areas: ". .";
      font-family: "Poppins", sans-serif;

      @media screen and (max-width: 1166px) {
        grid-template-columns: 1fr;
        grid-template-areas: ".";
      }
      @media screen and (max-width: 1150px) {
        align-items: end;
      }
      @media screen and (max-width: 600px) {
        gap: 30px;
      }
      .simple-flex{
        display: flex;
        flex-direction: column;
        gap: 50px;
        @media screen and (max-width: 600px) {
          gap: 30px;
        }
        img{
          width: 100%;
          @media screen and (min-width: 1150px) {
            width: 799px;
          }
        }
      }

      .point-column{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 30px;

        h2.title{
          font-size: 90px;
          color: #000;
          padding-top: 0px;
          margin: -25px 0 20px 0;
          text-transform: uppercase;
          @media screen and (max-width: 850px) {
            text-align: left;
            font-size: 60px;
          }
          @media screen and (max-width: 600px) {

          }
        }

        .point{
          display: flex;
          gap: 10px;
          align-items: flex-start;
          font-size: 18px;
          min-width: 380px;
          @media screen and (max-width: 600px) {
            gap: 5px;
            font-size: 14px;
            min-width: unset;
          }
          .point-bullet{
            @media screen and (max-width: 600px) {
              width: 52px;
              position: relative;
              left: -3px;
            }
          }
          .points-rows{
            display: flex;
            flex-direction: column;
            .hero{
              font-size: 20px;
              padding: 20px;
              @media screen and (max-width: 600px) {
                padding: 0px;
              }
            }          
          }

          .realization-points-points{
            display: flex;
            flex-direction: column;
            position: relative;
            
            .subpoints{
              display: flex;
              flex-direction: column;
              padding: 20px;
              @media screen and (max-width: 600px) {
                padding: 10px 0px;
              }
              p{
                padding: 0;
                margin: 0 0 20px 0;
              }            
            }

          }
        }        
      }



    }
  }

}

.strategy{
  width: 100%;
  height: 550px;
  background-image: url('./img/bg.jpg');
  background-size: cover;
  display: flex;
  padding: 50px 0;
  margin-top: 80px;
  position: relative;

  @media screen and (max-width: 1366px) {
    padding: 25px;
    background-position-x: center;
    background-size: auto;
    height: auto;
    min-height: 550px;
  }

  .strategy_container{
    position: relative;
    max-width: 1150px;
    width: 100%;
    margin: auto;
    padding: 50px 0;

    @media screen and (max-width: 1366px) {
      padding: 25px;
    }

    .cube{
      position: absolute;
      right: 0;
      top: -158px !important;

      @media screen and (max-width: 1366px) {
        display: none;
      }

    }

    .strategy_title{
      display: flex;
      flex-direction: column;
      font-family: "Antonio", sans-serif;
      font-optical-sizing: auto;
      font-weight: 700;
      font-style: normal;
      font-size: 90px;
      text-transform: uppercase;
      line-height: 1;
      margin-bottom: 50px;
      color: #fff;

      .green{
        color: #00FFC4;
      }

      @media screen and (max-width: 600px) {
        font-size: 60px;
      }

    }
    .strategy-lead{
      color: #fff;
      font-family: "Poppins", sans-serif;
      font-size: 20px;
      padding: 20px 50px;
      @media screen and (max-width: 600px) {
        font-size: 17px;
        padding: 0 0 20px 0px;
      }
    }

  }
}