.cs_baner_tarczynski_poranki {
  background-image: url("./img/baner.png");
  background-position: center;
  position: relative;
  width: 100%;
  min-height: calc(100vh - 125px);
  background-size: cover;
}
@media screen and (max-width: 600px) {
  .cs_baner_tarczynski_poranki {
    background-image: url("./img/poranki_mobile.jpg");
    min-height: 82vh;
  }
}
.cs_baner_tarczynski_poranki .logo {
  position: absolute;
  top: 0;
  margin: auto;
  left: 0;
  right: 0;
  z-index: 1;
}
@media screen and (max-width: 600px) {
  .cs_baner_tarczynski_poranki .logo {
    position: absolute;
    top: 0;
    margin: 0;
    left: 25px;
    right: 0;
    max-width: 120px;
  }
}
.cs_baner_tarczynski_poranki .cs_baner_container_tarczynski_poranki {
  display: flex;
  width: 100%;
  margin: auto;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  height: 100%;
  justify-content: flex-end;
}
.cs_baner_tarczynski_poranki .cs_baner_container_tarczynski_poranki .text_content {
  color: #fff;
  background-color: #ED7203;
  padding: 0 30px 0 30px;
  bottom: 60px;
  position: relative;
  width: 50vw;
  max-height: 240px;
}
@media screen and (max-width: 960px) {
  .cs_baner_tarczynski_poranki .cs_baner_container_tarczynski_poranki .text_content {
    left: 0;
    right: 0;
    margin: auto;
    bottom: 0;
    min-width: unset;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
    padding-bottom: 10px;
  }
}
@media screen and (max-width: 600px) {
  .cs_baner_tarczynski_poranki .cs_baner_container_tarczynski_poranki .text_content {
    background-color: transparent;
    margin: 0;
    display: flex;
    align-items: flex-start;
    left: 0;
    right: auto;
    width: 100%;
    padding: 0 25px 50px 25px;
  }
}
.cs_baner_tarczynski_poranki .cs_baner_container_tarczynski_poranki .text_content h2 {
  font-size: 85px;
  margin: 10px 0 0 0;
  text-transform: uppercase;
  line-height: 1.16;
  position: relative;
  top: -53px;
}
@media screen and (max-width: 960px) {
  .cs_baner_tarczynski_poranki .cs_baner_container_tarczynski_poranki .text_content h2 {
    top: 0px;
    font-size: clamp(40px, 15vw, 60px);
    color: #0C2C65;
  }
}
.cs_baner_tarczynski_poranki .cs_baner_container_tarczynski_poranki .text_content h5 {
  font-size: 31px;
  margin: 10px 0 0 0;
  font-weight: 400;
  color: #000;
  position: relative;
  top: -49px;
}
@media screen and (max-width: 960px) {
  .cs_baner_tarczynski_poranki .cs_baner_container_tarczynski_poranki .text_content h5 {
    top: 0px;
  }
}
@media screen and (max-width: 600px) {
  .cs_baner_tarczynski_poranki .cs_baner_container_tarczynski_poranki .text_content h5 {
    font-size: clamp(18px, 5vw, 23px);
    margin: 0;
  }
}

.cs_black_space {
  background-color: black;
  height: 56px;
  width: 100%;
}

.cs_container_1150_tarczynski_poranki {
  width: 100%;
  max-width: 1150px;
  margin: 0 auto;
  padding: 50px 0;
}
@media screen and (max-width: 1366px) {
  .cs_container_1150_tarczynski_poranki {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.cs_container_1150_tarczynski_poranki .challenge h2.title {
  font-size: 90px;
  margin-top: 20px;
}
@media screen and (max-width: 850px) {
  .cs_container_1150_tarczynski_poranki .challenge h2.title {
    text-align: left;
    font-size: 60px;
  }
}
@media screen and (max-width: 600px) {
  .cs_container_1150_tarczynski_poranki .challenge h2.title {
    color: #0C2C65;
    margin-top: 0 !important;
  }
}
.cs_container_1150_tarczynski_poranki .challenge .cs_poranek_row {
  display: grid;
  grid-template-columns: 430px 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: ". .";
  gap: 50px;
  position: relative;
  align-items: start;
}
@media screen and (max-width: 1170px) {
  .cs_container_1150_tarczynski_poranki .challenge .cs_poranek_row {
    grid-template-columns: 1fr;
    grid-template-areas: ".";
  }
}
.cs_container_1150_tarczynski_poranki .challenge .cs_poranek_row .cs_bullets {
  display: flex;
  gap: 50px;
  width: 100%;
}
@media screen and (max-width: 1110px) {
  .cs_container_1150_tarczynski_poranki .challenge .cs_poranek_row .cs_bullets {
    flex-direction: column;
  }
}
.cs_container_1150_tarczynski_poranki .challenge .cs_poranek_row .cs_bullets .cs_bullets_box {
  padding: 40px 40px 40px 52px;
  border-radius: 25px;
  box-shadow: inset 10px 10px 18px rgba(0, 0, 0, 0.24);
  position: relative;
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
}
@media screen and (max-width: 600px) {
  .cs_container_1150_tarczynski_poranki .challenge .cs_poranek_row .cs_bullets .cs_bullets_box {
    padding: 25px 25px 25px 33px;
    font-size: 15px;
    line-height: 1.4;
  }
  .cs_container_1150_tarczynski_poranki .challenge .cs_poranek_row .cs_bullets .cs_bullets_box br {
    display: none;
  }
}
.cs_container_1150_tarczynski_poranki .challenge .cs_poranek_row .cs_bullets .cs_bullets_box img {
  position: absolute;
  left: -21px;
  right: auto;
  margin: auto;
  top: 0;
  bottom: 0;
  max-width: 45px;
}
.cs_container_1150_tarczynski_poranki .challenge .cs_poranek_row .cs_poranki_img {
  display: flex;
  gap: 10px;
  position: relative;
}
@media screen and (max-width: 1170px) {
  .cs_container_1150_tarczynski_poranki .challenge .cs_poranek_row .cs_poranki_img {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media screen and (max-width: 600px) {
  .cs_container_1150_tarczynski_poranki .challenge .cs_poranek_row .cs_poranki_img {
    gap: 20px;
  }
}
.cs_container_1150_tarczynski_poranki .challenge .cs_poranek_row .cs_poranki_img img {
  width: 100%;
  height: auto;
}
@media screen and (max-width: 1666px) {
  .cs_container_1150_tarczynski_poranki .challenge .cs_poranek_row .cs_poranki_img img {
    max-width: 270px;
  }
}
@media screen and (max-width: 1422px) {
  .cs_container_1150_tarczynski_poranki .challenge .cs_poranek_row .cs_poranki_img img {
    max-width: 250px;
  }
}
@media screen and (max-width: 1322px) {
  .cs_container_1150_tarczynski_poranki .challenge .cs_poranek_row .cs_poranki_img img {
    max-width: 200px;
  }
}
@media screen and (max-width: 600px) {
  .cs_container_1150_tarczynski_poranki .challenge .cs_poranek_row .cs_poranki_img img {
    width: 100%;
    max-width: 309px;
  }
}
.cs_container_1150_tarczynski_poranki .realization h2.title {
  font-size: 90px;
  color: #EC7102;
  padding-top: 50px;
}
@media screen and (max-width: 850px) {
  .cs_container_1150_tarczynski_poranki .realization h2.title {
    text-align: left;
    font-size: 60px;
    padding-top: 30px;
  }
}
.cs_container_1150_tarczynski_poranki .realization .realization-points {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 50px;
  grid-template-areas: ". .";
  font-family: "Poppins", sans-serif;
}
@media screen and (max-width: 966px) {
  .cs_container_1150_tarczynski_poranki .realization .realization-points {
    grid-template-columns: 1fr;
    grid-template-areas: ".";
  }
}
@media screen and (max-width: 600px) {
  .cs_container_1150_tarczynski_poranki .realization .realization-points {
    gap: 30px;
  }
}
.cs_container_1150_tarczynski_poranki .realization .realization-points .simple-flex {
  display: flex;
  flex-direction: column;
  gap: 50px;
}
@media screen and (max-width: 600px) {
  .cs_container_1150_tarczynski_poranki .realization .realization-points .simple-flex {
    gap: 30px;
  }
}
.cs_container_1150_tarczynski_poranki .realization .realization-points .baner_event img {
  width: 100%;
}
.cs_container_1150_tarczynski_poranki .realization .realization-points .points_column {
  display: flex;
  gap: 20px;
  flex-direction: column;
}
.cs_container_1150_tarczynski_poranki .realization .realization-points .points_column .point {
  display: flex;
  gap: 10px;
  align-items: flex-start;
  line-height: 1.35;
}
.cs_container_1150_tarczynski_poranki .realization .realization-points .points_column .point > div {
  padding-top: 8px;
}
@media screen and (max-width: 600px) {
  .cs_container_1150_tarczynski_poranki .realization .realization-points .points_column .point {
    gap: 5px;
    font-size: 14px;
  }
}
@media screen and (max-width: 600px) {
  .cs_container_1150_tarczynski_poranki .realization .realization-points .points_column .point .point-bullet {
    width: 52px;
    position: relative;
    left: -3px;
  }
}
.cs_container_1150_tarczynski_poranki .realization .realization-points .points_column .point .points-rows {
  display: flex;
  flex-direction: column;
}
.cs_container_1150_tarczynski_poranki .realization .realization-points .points_column .point .points-rows .hero {
  font-size: 20px;
  padding: 20px;
}
@media screen and (max-width: 600px) {
  .cs_container_1150_tarczynski_poranki .realization .realization-points .points_column .point .points-rows .hero {
    padding: 0px;
  }
}
.cs_container_1150_tarczynski_poranki .realization .realization-points .points_column .point .realization-points-points {
  display: flex;
  flex-direction: column;
  position: relative;
}
.cs_container_1150_tarczynski_poranki .realization .realization-points .points_column .point .realization-points-points .subpoints {
  display: flex;
  flex-direction: column;
  padding: 20px;
}
@media screen and (max-width: 600px) {
  .cs_container_1150_tarczynski_poranki .realization .realization-points .points_column .point .realization-points-points .subpoints {
    padding: 10px 0px;
  }
}
.cs_container_1150_tarczynski_poranki .realization .realization-points .points_column .point .realization-points-points .subpoints p {
  padding: 0;
  margin: 0 0 20px 0;
}
.cs_container_1150_tarczynski_poranki .carusele_poranki .carouselSlide img {
  width: calc(100% - 5px);
  height: auto;
  border: 1px solid black;
}
.cs_container_1150_tarczynski_poranki .carusele_poranki .carusele-btns {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 20px;
}
.cs_container_1150_tarczynski_poranki .carusele_poranki .carusele-btns button {
  position: relative;
  color: #000;
  width: 40px;
  height: 40px;
  padding: 0;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cs_container_1150_tarczynski_poranki .carusele_poranki .carusele-btns button:hover {
  color: #000;
}/*# sourceMappingURL=styles.css.map */