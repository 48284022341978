.content1150 {
  width: 100%;
  max-width: 1150px;
  margin: auto;
  font-family: "Poppins", sans-serif;
}
.content1150 .recommend {
  padding-bottom: 75px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: 50px;
  grid-template-areas: ".";
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
}
@media screen and (max-width: 1248px) {
  .content1150 .recommend {
    grid-template-columns: 1fr;
    grid-template-areas: ".";
  }
}
@media screen and (max-width: 1150px) {
  .content1150 .recommend {
    justify-items: center;
  }
}
@media screen and (max-width: 600px) {
  .content1150 .recommend {
    padding-bottom: 50px;
    gap: 30px;
  }
}
.content1150 .recommend .title {
  display: flex;
  font-family: "Antonio", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-size: 90px;
  text-transform: uppercase;
}
.content1150 .recommend .title span {
  white-space: nowrap;
}
@media screen and (max-width: 600px) {
  .content1150 .recommend .title span {
    font-size: 50px;
    white-space: unset;
    text-align: center;
    display: flex;
    gap: 15px;
    flex-direction: column;
    align-items: flex-start;
    line-height: 1;
  }
}
.content1150 .recommend .title .green {
  color: #00FFC4;
}
@media screen and (max-width: 1248px) {
  .content1150 .recommend .title {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 600px) {
  .content1150 .recommend .title {
    font-size: 50px;
    width: 100%;
    margin-bottom: 0;
  }
}
.content1150 .recommend .recommend_items {
  display: flex;
  gap: 120px;
}
@media screen and (max-width: 1150px) {
  .content1150 .recommend .recommend_items {
    gap: 20px;
  }
}
@media screen and (max-width: 600px) {
  .content1150 .recommend .recommend_items {
    flex-direction: column;
    gap: 30px;
  }
}
.content1150 .recommend .recommend_items hr.mobile-seperator {
  margin: 0;
  border-top: 2px solid #00ffc4;
  display: none;
}
@media screen and (max-width: 600px) {
  .content1150 .recommend .recommend_items hr.mobile-seperator {
    display: block;
  }
}
.content1150 .recommend .recommend_items .recommend_item {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
}
@media screen and (max-width: 1150px) {
  .content1150 .recommend .recommend_items .recommend_item {
    max-width: 400px;
  }
}
@media screen and (max-width: 600px) {
  .content1150 .recommend .recommend_items .recommend_item {
    max-width: unset;
    width: 100%;
  }
}
.content1150 .recommend .recommend_items .recommend_item .item_title {
  font-family: "Antonio", sans-serif;
  font-weight: 700;
  font-size: 20px;
  display: flex;
  align-items: center;
  gap: 15px;
}
.content1150 .recommend .recommend_items .recommend_item .item_title img {
  max-width: 86px;
}
.content1150 .recommend .recommend_items .recommend_item .item_title .item_name {
  display: flex;
  flex-direction: column;
}
.content1150 .recommend .recommend_items .recommend_item .item_title .item_name small {
  text-transform: uppercase;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  line-height: 1.1;
  margin-top: 5px;
}
.content1150 .recommend .recommend_items .recommend_item .item_txt {
  font-size: 14px;
  position: relative;
}
@media screen and (max-width: 600px) {
  .content1150 .recommend .recommend_items .recommend_item .item_txt {
    font-size: 13px;
    line-height: 1.25;
  }
}
.content1150 .recommend .recommend_items .recommend_item .item_txt img {
  position: absolute;
  left: -80px;
}
@media screen and (max-width: 1330px) {
  .content1150 .recommend .recommend_items .recommend_item .item_txt img {
    display: none;
  }
}
.content1150 .recommend .recommend_items .recommend_item .item_logo {
  opacity: 0.7;
}
.content1150 .recommend .recommend_items .recommend_item .item_logo img {
  position: relative;
  left: -24px;
}
.content1150 .recommend .recommend_stars {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
@media screen and (max-width: 600px) {
  .content1150 .recommend .recommend_stars {
    margin-top: 20px;
    align-items: center;
  }
}
.content1150 .recommend .recommend_stars .score {
  display: flex;
  align-items: flex-end;
}
@media screen and (max-width: 600px) {
  .content1150 .recommend .recommend_stars .score {
    display: flex;
    align-items: flex-end;
    padding-left: 5px;
  }
}
.content1150 .recommend .recommend_stars .score .score_label {
  font-family: "Antonio", sans-serif;
  font-size: 33px;
  font-weight: 300;
  position: relative;
  top: -11px;
}
@media screen and (max-width: 600px) {
  .content1150 .recommend .recommend_stars .score .score_label {
    font-size: 17px;
  }
}
.content1150 .recommend .recommend_stars .score .score_value {
  font-family: "Antonio", sans-serif;
  font-size: 50px;
  padding-left: 10px;
  font-weight: bold;
  position: relative;
  top: -8px;
}
@media screen and (max-width: 600px) {
  .content1150 .recommend .recommend_stars .score .score_value {
    font-size: 40px;
    top: -5px;
  }
}
.content1150 .recommend .recommend_stars .score .score_scope {
  font-family: "Antonio", sans-serif;
  font-size: 40px;
  font-weight: 100;
  position: relative;
  top: -10px;
  opacity: 0.5;
}
@media screen and (max-width: 600px) {
  .content1150 .recommend .recommend_stars .score .score_scope {
    font-size: 20px;
    position: relative;
    top: -8px;
  }
}
.content1150 .recommend .recommend_stars .score_stars {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 600px) {
  .content1150 .recommend .recommend_stars .score_stars {
    margin-top: -10px;
  }
}
.content1150 .recommend .recommend_stars .score_stars img {
  width: 100%;
  max-width: 300px;
}
@media screen and (max-width: 600px) {
  .content1150 .recommend .recommend_stars .score_stars img {
    width: 80%;
  }
}/*# sourceMappingURL=style.css.map */