.cs_baner_vw {
  background-image: url("./img/baner.png");
  background-position: center;
  min-height: calc(100vh - 125px);
  display: flex;
  align-items: flex-end;
  background-size: cover;
}
@media screen and (max-width: 600px) {
  .cs_baner_vw {
    background-image: url("./img/vw_mobile.jpg");
    background-position: right;
  }
}
.cs_baner_vw .cs_baner_grid {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: ". .";
  position: relative;
  min-height: 200px;
  height: auto;
  align-items: center;
}
@media screen and (max-width: 600px) {
  .cs_baner_vw .cs_baner_grid {
    display: block;
  }
}
@media screen and (max-width: 600px) {
  .cs_baner_vw .cs_baner_grid:nth-child(2) {
    display: none;
  }
}
.cs_baner_vw .cs_baner_grid .cs_baner_container {
  color: #fff;
  background: rgba(0, 0, 0, 0.65);
  min-height: 400px;
}
@media screen and (max-width: 1115px) {
  .cs_baner_vw .cs_baner_grid .cs_baner_container {
    background: transparent;
  }
}
@media screen and (max-width: 600px) {
  .cs_baner_vw .cs_baner_grid .cs_baner_container {
    display: inline;
  }
}
.cs_baner_vw .cs_baner_grid .cs_baner_container .cs_baner_content {
  position: absolute;
  width: 100%;
  max-width: 1150px;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: ". .";
}
@media screen and (max-width: 1400px) {
  .cs_baner_vw .cs_baner_grid .cs_baner_container .cs_baner_content {
    width: 100%;
    padding: 0 25px;
  }
}
@media screen and (max-width: 1115px) {
  .cs_baner_vw .cs_baner_grid .cs_baner_container .cs_baner_content {
    padding-left: 0;
  }
}
@media screen and (max-width: 600px) {
  .cs_baner_vw .cs_baner_grid .cs_baner_container .cs_baner_content {
    display: block;
    position: relative;
    padding: 0;
    margin: 0;
  }
}
.cs_baner_vw .cs_baner_grid .cs_baner_container .cs_baner_content .logo_mobile {
  position: relative;
  left: 25px;
  max-width: 60px;
  top: -10px;
  display: none;
}
@media screen and (max-width: 600px) {
  .cs_baner_vw .cs_baner_grid .cs_baner_container .cs_baner_content .logo_mobile {
    display: block;
  }
}
.cs_baner_vw .cs_baner_grid .cs_baner_container .cs_baner_content .cs_baner_content_txt {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
@media screen and (max-width: 1115px) {
  .cs_baner_vw .cs_baner_grid .cs_baner_container .cs_baner_content .cs_baner_content_txt {
    background: rgba(0, 0, 0, 0.65);
    padding: 25px;
  }
}
@media screen and (max-width: 600px) {
  .cs_baner_vw .cs_baner_grid .cs_baner_container .cs_baner_content .cs_baner_content_txt {
    margin-bottom: 0px;
  }
}
@media screen and (max-width: 800px) {
  .cs_baner_vw .cs_baner_grid .cs_baner_container .cs_baner_content .cs_baner_content_txt img {
    width: clamp(200px, 8vw, 500px);
  }
}
@media screen and (max-width: 600px) {
  .cs_baner_vw .cs_baner_grid .cs_baner_container .cs_baner_content .cs_baner_content_txt img {
    display: none;
  }
}
.cs_baner_vw .cs_baner_grid .cs_baner_container .cs_baner_content .cs_baner_content_txt h2 {
  font-size: 75px;
  margin: 0 0 30px 0;
  text-transform: uppercase;
  line-height: 1.16;
}
@media screen and (max-width: 800px) {
  .cs_baner_vw .cs_baner_grid .cs_baner_container .cs_baner_content .cs_baner_content_txt h2 {
    font-size: clamp(55px, 8vw, 70px);
  }
}
@media screen and (max-width: 600px) {
  .cs_baner_vw .cs_baner_grid .cs_baner_container .cs_baner_content .cs_baner_content_txt h2 {
    margin: 0;
    font-size: 50px;
  }
}
.cs_baner_vw .cs_baner_grid .cs_baner_container .cs_baner_content .cs_baner_content_txt h5 {
  font-size: 19px;
  margin: 10px 0 0 0;
  font-weight: 400;
  display: none;
}
@media screen and (max-width: 600px) {
  .cs_baner_vw .cs_baner_grid .cs_baner_container .cs_baner_content .cs_baner_content_txt h5 {
    display: block;
  }
}

.cs_black_space {
  background-color: black;
  height: 56px;
  width: 100%;
}

.cs_container_1150_vw {
  width: 100%;
  max-width: 1150px;
  margin: 0 auto;
  padding: 50px 0;
}
@media screen and (max-width: 1366px) {
  .cs_container_1150_vw {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.cs_container_1150_vw .challenge h2.title {
  text-transform: uppercase;
  font-size: 90px;
  margin-top: 20px;
}
@media screen and (max-width: 850px) {
  .cs_container_1150_vw .challenge h2.title {
    text-align: left;
    font-size: 60px;
    margin-top: 0;
  }
}
.cs_container_1150_vw .challenge .cs_bullets {
  display: flex;
  gap: 50px;
  width: 100%;
}
@media screen and (max-width: 1110px) {
  .cs_container_1150_vw .challenge .cs_bullets {
    flex-direction: column;
  }
}
@media screen and (max-width: 600px) {
  .cs_container_1150_vw .challenge .cs_bullets {
    gap: 20px;
  }
}
.cs_container_1150_vw .challenge .cs_bullets .cs_bullets_box {
  padding: 40px 40px 40px 52px;
  border-radius: 25px;
  box-shadow: inset 10px 10px 18px rgba(0, 0, 0, 0.24);
  position: relative;
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
}
@media screen and (max-width: 600px) {
  .cs_container_1150_vw .challenge .cs_bullets .cs_bullets_box {
    padding: 25px 25px 25px 33px;
    font-size: 15px;
    line-height: 1.4;
  }
  .cs_container_1150_vw .challenge .cs_bullets .cs_bullets_box br {
    display: none;
  }
}
.cs_container_1150_vw .challenge .cs_bullets .cs_bullets_box img {
  position: absolute;
  left: -21px;
  right: auto;
  margin: auto;
  top: 0;
  bottom: 0;
  max-width: 45px;
}
.cs_container_1150_vw .realization {
  margin-top: 100px;
  padding-bottom: 50px;
}
@media screen and (max-width: 600px) {
  .cs_container_1150_vw .realization {
    margin-top: 0px;
  }
}
.cs_container_1150_vw .realization .realization-points {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 50px;
  grid-template-areas: ". .";
  font-family: "Poppins", sans-serif;
}
@media screen and (max-width: 1166px) {
  .cs_container_1150_vw .realization .realization-points {
    grid-template-columns: 1fr;
    grid-template-areas: ".";
  }
}
@media screen and (max-width: 1150px) {
  .cs_container_1150_vw .realization .realization-points {
    align-items: end;
  }
}
@media screen and (max-width: 600px) {
  .cs_container_1150_vw .realization .realization-points {
    gap: 30px;
  }
}
.cs_container_1150_vw .realization .realization-points .simple-flex {
  display: flex;
  flex-direction: column;
  gap: 50px;
}
@media screen and (max-width: 600px) {
  .cs_container_1150_vw .realization .realization-points .simple-flex {
    gap: 30px;
  }
}
.cs_container_1150_vw .realization .realization-points .simple-flex img {
  width: 100%;
}
@media screen and (min-width: 1150px) {
  .cs_container_1150_vw .realization .realization-points .simple-flex img {
    width: 799px;
  }
}
.cs_container_1150_vw .realization .realization-points .point-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 30px;
}
.cs_container_1150_vw .realization .realization-points .point-column h2.title {
  font-size: 90px;
  color: #000;
  padding-top: 0px;
  margin: -25px 0 20px 0;
  text-transform: uppercase;
}
@media screen and (max-width: 850px) {
  .cs_container_1150_vw .realization .realization-points .point-column h2.title {
    text-align: left;
    font-size: 60px;
  }
}
.cs_container_1150_vw .realization .realization-points .point-column .point {
  display: flex;
  gap: 10px;
  align-items: flex-start;
  font-size: 18px;
  min-width: 380px;
}
@media screen and (max-width: 600px) {
  .cs_container_1150_vw .realization .realization-points .point-column .point {
    gap: 5px;
    font-size: 14px;
    min-width: unset;
  }
}
@media screen and (max-width: 600px) {
  .cs_container_1150_vw .realization .realization-points .point-column .point .point-bullet {
    width: 52px;
    position: relative;
    left: -3px;
  }
}
.cs_container_1150_vw .realization .realization-points .point-column .point .points-rows {
  display: flex;
  flex-direction: column;
}
.cs_container_1150_vw .realization .realization-points .point-column .point .points-rows .hero {
  font-size: 20px;
  padding: 20px;
}
@media screen and (max-width: 600px) {
  .cs_container_1150_vw .realization .realization-points .point-column .point .points-rows .hero {
    padding: 0px;
  }
}
.cs_container_1150_vw .realization .realization-points .point-column .point .realization-points-points {
  display: flex;
  flex-direction: column;
  position: relative;
}
.cs_container_1150_vw .realization .realization-points .point-column .point .realization-points-points .subpoints {
  display: flex;
  flex-direction: column;
  padding: 20px;
}
@media screen and (max-width: 600px) {
  .cs_container_1150_vw .realization .realization-points .point-column .point .realization-points-points .subpoints {
    padding: 10px 0px;
  }
}
.cs_container_1150_vw .realization .realization-points .point-column .point .realization-points-points .subpoints p {
  padding: 0;
  margin: 0 0 20px 0;
}

.strategy {
  width: 100%;
  height: 550px;
  background-image: url("./img/bg.jpg");
  background-size: cover;
  display: flex;
  padding: 50px 0;
  margin-top: 80px;
  position: relative;
}
@media screen and (max-width: 1366px) {
  .strategy {
    padding: 25px;
    background-position-x: center;
    background-size: auto;
    height: auto;
    min-height: 550px;
  }
}
.strategy .strategy_container {
  position: relative;
  max-width: 1150px;
  width: 100%;
  margin: auto;
  padding: 50px 0;
}
@media screen and (max-width: 1366px) {
  .strategy .strategy_container {
    padding: 25px;
  }
}
.strategy .strategy_container .cube {
  position: absolute;
  right: 0;
  top: -158px !important;
}
@media screen and (max-width: 1366px) {
  .strategy .strategy_container .cube {
    display: none;
  }
}
.strategy .strategy_container .strategy_title {
  display: flex;
  flex-direction: column;
  font-family: "Antonio", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-size: 90px;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 50px;
  color: #fff;
}
.strategy .strategy_container .strategy_title .green {
  color: #00FFC4;
}
@media screen and (max-width: 600px) {
  .strategy .strategy_container .strategy_title {
    font-size: 60px;
  }
}
.strategy .strategy_container .strategy-lead {
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  padding: 20px 50px;
}
@media screen and (max-width: 600px) {
  .strategy .strategy_container .strategy-lead {
    font-size: 17px;
    padding: 0 0 20px 0px;
  }
}/*# sourceMappingURL=styles.css.map */