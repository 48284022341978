.content1150 {
  width: 100%;
  max-width: 1150px;
  margin: auto;
  font-family: "Poppins", sans-serif;
}
@media screen and (max-width: 1470px) {
  .content1150 {
    width: 100%;
    padding: 0 25px;
  }
}
.content1150 .solutions {
  margin-bottom: 100px;
}
@media screen and (max-width: 600px) {
  .content1150 .solutions {
    margin-bottom: 50px;
  }
}
.content1150 .solutions .solutions_title {
  display: flex;
  flex-direction: column;
  font-family: "Antonio", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-size: 90px;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 50px;
}
.content1150 .solutions .solutions_title .green {
  color: #00FFC4;
}
@media screen and (max-width: 600px) {
  .content1150 .solutions .solutions_title {
    font-size: 50px;
    margin-bottom: 0px;
  }
}
.content1150 .solutions .solutions_option_item img {
  width: 45px;
}
.content1150 .solutions .solutions_option_1_columns {
  height: 100%;
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  gap: 0px 0px;
  grid-template-areas: "." ".";
}
.content1150 .solutions .solutions_option_1_columns .solutions_option_1_columns_grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: 40px;
  grid-template-areas: ".";
  padding: 0 0 0 25px;
}
@media screen and (max-width: 600px) {
  .content1150 .solutions .solutions_option_1_columns .solutions_option_1_columns_grid {
    padding: 0 0 0 15px;
  }
}
@media screen and (max-width: 1470px) {
  .content1150 .solutions .solutions_option_1_columns .solutions_option_1_columns_grid .solutions_option_item {
    min-height: unset !important;
  }
}
.content1150 .solutions .solutions_option_3_columns .solutions_option_3_columns_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 40px;
  grid-template-areas: ". . .";
  padding: 0 0 0 25px;
}
@media screen and (max-width: 600px) {
  .content1150 .solutions .solutions_option_3_columns .solutions_option_3_columns_grid {
    padding: 30px;
    border-radius: 25px;
    box-shadow: inset 8px 8px 14px rgba(0, 0, 0, 0.15);
    margin-left: 15px;
    gap: 20px;
  }
}
@media screen and (max-width: 1150px) {
  .content1150 .solutions .solutions_option_3_columns .solutions_option_3_columns_grid {
    grid-template-columns: 1fr;
    grid-template-areas: ".";
  }
}
@media screen and (max-width: 1150px) {
  .content1150 .solutions .solutions_option_3_columns .solutions_option_3_columns_grid .solutions_option {
    gap: 18px;
  }
}
@media screen and (max-width: 600px) {
  .content1150 .solutions .solutions_option_3_columns .solutions_option_3_columns_grid .solutions_option {
    box-shadow: none;
    padding: 0;
  }
}
@media screen and (max-width: 1150px) {
  .content1150 .solutions .solutions_option_3_columns .solutions_option_3_columns_grid .solutions_option_item {
    min-height: unset !important;
  }
}
@media screen and (max-width: 600px) {
  .content1150 .solutions .solutions_option_3_columns .solutions_option_3_columns_grid .solutions_option_item {
    min-height: 70px !important;
  }
}
.content1150 .solutions .solutions_options_columns {
  margin-top: 50px;
  display: grid;
  grid-template-columns: 28% 1fr;
  grid-template-rows: 1fr;
  gap: 40px;
  grid-template-areas: ". .";
  align-items: start;
}
@media screen and (max-width: 1470px) {
  .content1150 .solutions .solutions_options_columns {
    grid-template-columns: 100%;
    grid-template-areas: ".";
  }
}
@media screen and (max-width: 600px) {
  .content1150 .solutions .solutions_options_columns {
    gap: 30px;
    margin-top: 26px;
  }
}
.content1150 .solutions .solutions_options_columns .solutions_option {
  padding: 30px 20px 30px 28px;
  border-radius: 25px;
  box-shadow: inset 8px 8px 14px rgba(0, 0, 0, 0.15);
  justify-content: space-between;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 10px;
  grid-template-areas: "." "." ".";
}
@media screen and (max-width: 600px) {
  .content1150 .solutions .solutions_options_columns .solutions_option {
    gap: 30px;
  }
}
.content1150 .solutions .solutions_options_columns .solutions_option .solutions_option_item {
  position: relative;
}
.content1150 .solutions .solutions_options_columns .solutions_option .solutions_option_item .options_point {
  position: absolute;
  left: -53px;
  top: -10px;
}
.content1150 .solutions .solutions_options_columns .solutions_option .solutions_option_item .options_title {
  font-size: 19px;
  font-weight: 700;
  margin-bottom: 5px;
  line-height: 1.2;
  display: flex;
}
@media screen and (max-width: 380px) {
  .content1150 .solutions .solutions_options_columns .solutions_option .solutions_option_item .options_title {
    font-size: 15px;
  }
}
.content1150 .solutions .solutions_options_columns .solutions_option .solutions_option_item .options_text {
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  position: relative;
  color: #737373;
}
.content1150 .solutions .solutions_options_columns .solutions_option .solutions_option_item .options_text .rowfix {
  position: relative;
}
.content1150 .solutions .solutions_options_columns .solutions_option .solutions_option_item .options_text .rowfix button {
  margin-left: 3px;
  background: transparent;
  border: 0;
  padding: 0;
  position: absolute;
  color: #808080;
}
.content1150 .solutions .solutions_options_columns .solutions_bullets {
  position: relative;
  margin-bottom: 40px;
}
.content1150 .solutions .solutions_options_columns .solutions_bullets .solutions_bullets_main {
  position: relative;
  z-index: 0;
  background-color: #000;
  border-radius: 13px;
  min-height: 65px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 25px;
}
.content1150 .solutions .solutions_options_columns .solutions_bullets .solutions_bullets_main .blink {
  background-image: url("./img/reflec.png");
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 50%, 0% 100%);
          clip-path: polygon(0 0, 100% 0, 100% 50%, 0% 100%);
  width: 62px;
  height: 65px;
  position: absolute;
  left: 0;
  background-repeat: no-repeat;
  top: 0;
}
.content1150 .solutions .solutions_options_columns .solutions_bullets .solutions_bullets_main .solutions_bullets_main_content {
  display: flex;
  gap: 30px;
  justify-content: space-between;
  width: 100%;
}
.content1150 .solutions .solutions_options_columns .solutions_bullets .solutions_bullets_main .solutions_bullets_main_content .solutions_bullets_main_points {
  position: relative;
  z-index: 2;
  display: flex;
  gap: 10px;
  align-items: center;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: bold;
}
.content1150 .solutions .solutions_options_columns .solutions_bullets .solutions_bullets_main .solutions_bullets_main_content .solutions_bullets_main_points .bullet_title {
  line-height: 0.85;
  font-size: 20px;
  font-weight: 800;
  font-family: "Poppins", sans-serif;
}
.content1150 .solutions .solutions_options_columns .solutions_bullets .solutions_bullets_main .solutions_bullets_main_content .solutions_bullets_main_points .bullet_title small {
  font-size: 8px;
  display: inline-block;
  width: 100%;
  font-weight: 500;
}
.content1150 .solutions .solutions_options_columns .solutions_bullets .solutions_bullets_shadow {
  position: absolute;
  background: rgb(122, 34, 154);
  background: linear-gradient(0deg, rgb(122, 34, 154) 0%, rgb(255, 0, 93) 100%);
  width: 100%;
  min-height: 65px;
  border-radius: 13px;
  top: 7px;
  left: 9px;
  z-index: -1;
  box-shadow: 4px 9px 13px rgba(0, 0, 0, 0.2);
}

.expand-enter {
  opacity: 1;
  max-height: 100px;
  overflow: hidden;
  background: red;
}

.expand-enter-active {
  opacity: 1;
  max-height: 100px; /* dostosuj do swojego przypadku */
  transition: opacity 300ms, max-height 300ms ease-in-out;
  background: red;
}

.expand-exit {
  opacity: 1;
  max-height: 100px; /* dostosuj do swojego przypadku */
  overflow: hidden;
  background: red;
}

.expand-exit-active {
  opacity: 1;
  max-height: 100px;
  transition: opacity 300ms, max-height 300ms ease-in-out;
  background: red;
}/*# sourceMappingURL=style.css.map */