@charset "UTF-8";
html {
  scroll-behavior: smooth;
}

* {
  caret-color: transparent;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  max-width: 1920px;
  position: relative;
  margin: auto;
}

body {
  font-family: system-ui;
  margin: 0;
  overflow-x: hidden;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

::-moz-selection {
  background-color: #00FFC4; /* Kolor tła zaznaczonego tekstu */
  color: black; /* Kolor tekstu zaznaczonego */
}

::selection {
  background-color: #00FFC4; /* Kolor tła zaznaczonego tekstu */
  color: black; /* Kolor tekstu zaznaczonego */
}

body {
  margin: 0;
}
body h2 {
  font-family: "Antonio", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}
body h4 {
  font-family: "Antonio", sans-serif;
  font-weight: bold;
  font-style: normal;
}
body h5 {
  font-family: "Poppins", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
body p {
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
body .btn-normal.btn_withe-bg:hover {
  background-color: #000;
  color: #fff;
  border: 2px solid #000;
}
body .btn-dark.btn_withe-bg:hover {
  border: 2px solid #00FFC4;
}
body .btn {
  background: transparent;
  border: 2px solid #00FFC4;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 5px 22px;
  transition: all 0.1s ease;
  cursor: pointer;
  position: absolute;
  border-radius: 1px;
}
body .btn:hover {
  background: rgb(255, 255, 255);
  color: #000;
  border: 2px solid #fff;
}
body .page {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  top: 90px;
  transition: all ease 0.2s;
}
@media screen and (max-width: 600px) {
  body .page {
    top: 80px;
  }
}

.green {
  color: #00FFC4;
}/*# sourceMappingURL=app.css.map */