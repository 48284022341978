.cs_baner_lirene {
  background-image: url("./img/baner.png");
  background-position: center;
  width: 100%;
  min-height: calc(100vh - 125px);
  display: flex;
  background-size: cover;
}
@media screen and (max-width: 750px) {
  .cs_baner_lirene {
    background-image: url("./img/lirene_mobile.jpg");
    min-height: 82vh;
  }
}
.cs_baner_lirene .cs_baner_container {
  display: flex;
  width: 100%;
  max-width: 1150px;
  margin: auto;
  flex-direction: column;
  align-items: flex-start;
}
@media screen and (max-width: 750px) {
  .cs_baner_lirene .cs_baner_container {
    margin: 50px auto 0 auto;
  }
}
@media screen and (max-width: 1400px) {
  .cs_baner_lirene .cs_baner_container {
    width: 100%;
    padding: 0 25px 50px 25px;
    justify-content: flex-end;
  }
}
.cs_baner_lirene .cs_baner_container img {
  position: relative;
  left: 4px;
}
@media screen and (max-width: 750px) {
  .cs_baner_lirene .cs_baner_container img {
    width: 130px;
  }
}
.cs_baner_lirene .cs_baner_container h2 {
  font-size: clamp(50px, 6vw, 85px);
  margin: 10px 0 0 0;
  text-transform: uppercase;
  line-height: 1.16;
  text-shadow: 3px 3px #f8bbca;
}
@media screen and (max-width: 600px) {
  .cs_baner_lirene .cs_baner_container h2 {
    font-size: clamp(40px, 10vw, 85px);
  }
}
.cs_baner_lirene .cs_baner_container h5 {
  font-size: 31px;
  margin: 10px 0 0 0;
  font-weight: 400;
  font-size: clamp(25px, 2vw, 34px);
}
@media screen and (max-width: 600px) {
  .cs_baner_lirene .cs_baner_container h5 {
    font-size: clamp(20px, 5vw, 31px);
  }
}

.cs_black_space {
  background-color: black;
  height: 56px;
  width: 100%;
}

.cs_container_1150 {
  width: 100%;
  max-width: 1150px;
  margin: 0 auto;
  padding: 50px 0;
}
@media screen and (max-width: 1366px) {
  .cs_container_1150 {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.cs_container_1150 .challenge h2.title {
  font-size: 90px;
  margin-top: 20px;
}
@media screen and (max-width: 850px) {
  .cs_container_1150 .challenge h2.title {
    text-align: left;
    font-size: 60px;
    margin-top: 0;
  }
}
.cs_container_1150 .challenge .cs_bullets {
  display: flex;
  gap: 50px;
  width: 100%;
}
@media screen and (max-width: 1110px) {
  .cs_container_1150 .challenge .cs_bullets {
    flex-direction: column;
  }
}
@media screen and (max-width: 600px) {
  .cs_container_1150 .challenge .cs_bullets {
    gap: 20px;
  }
}
.cs_container_1150 .challenge .cs_bullets .cs_bullets_box {
  padding: 40px 40px 40px 52px;
  border-radius: 25px;
  box-shadow: inset 10px 10px 18px rgba(0, 0, 0, 0.24);
  position: relative;
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
}
@media screen and (max-width: 600px) {
  .cs_container_1150 .challenge .cs_bullets .cs_bullets_box {
    padding: 25px 25px 25px 33px;
    font-size: 15px;
    line-height: 1.4;
  }
  .cs_container_1150 .challenge .cs_bullets .cs_bullets_box br {
    display: none;
  }
}
.cs_container_1150 .challenge .cs_bullets .cs_bullets_box img {
  position: absolute;
  left: -21px;
  right: auto;
  margin: auto;
  top: 0;
  bottom: 0;
  max-width: 45px;
}
.cs_container_1150 .realization h2.title {
  font-size: 90px;
  color: #C73D5F;
  padding-top: 50px;
}
@media screen and (max-width: 850px) {
  .cs_container_1150 .realization h2.title {
    text-align: left;
    font-size: 60px;
    padding-top: 30px;
  }
}
.cs_container_1150 .realization .realization-points {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 50px;
  grid-template-areas: ". .";
  font-family: "Poppins", sans-serif;
}
@media screen and (max-width: 966px) {
  .cs_container_1150 .realization .realization-points {
    grid-template-columns: 1fr;
    grid-template-areas: ".";
  }
}
@media screen and (max-width: 600px) {
  .cs_container_1150 .realization .realization-points {
    gap: 30px;
  }
}
.cs_container_1150 .realization .realization-points .simple-flex {
  display: flex;
  flex-direction: column;
  gap: 50px;
}
@media screen and (max-width: 600px) {
  .cs_container_1150 .realization .realization-points .simple-flex {
    gap: 30px;
  }
}
.cs_container_1150 .realization .realization-points .point {
  display: flex;
  gap: 20px;
  align-items: flex-start;
}
@media screen and (max-width: 600px) {
  .cs_container_1150 .realization .realization-points .point {
    gap: 5px;
    font-size: 14px;
  }
}
@media screen and (max-width: 600px) {
  .cs_container_1150 .realization .realization-points .point .point-bullet {
    width: 52px;
    position: relative;
    left: -3px;
  }
}
.cs_container_1150 .realization .realization-points .point .points-rows {
  display: flex;
  flex-direction: column;
}
.cs_container_1150 .realization .realization-points .point .points-rows .hero {
  font-size: 20px;
  padding: 20px;
}
@media screen and (max-width: 600px) {
  .cs_container_1150 .realization .realization-points .point .points-rows .hero {
    padding: 0px;
  }
}
.cs_container_1150 .realization .realization-points .point .realization-points-points {
  display: flex;
  flex-direction: column;
  position: relative;
}
.cs_container_1150 .realization .realization-points .point .realization-points-points .subpoints {
  display: flex;
  flex-direction: column;
  padding: 20px;
}
@media screen and (max-width: 600px) {
  .cs_container_1150 .realization .realization-points .point .realization-points-points .subpoints {
    padding: 10px 0px;
  }
}
.cs_container_1150 .realization .realization-points .point .realization-points-points .subpoints p {
  padding: 0;
  margin: 0 0 20px 0;
  font-family: "Poppins", sans-serif !important;
}/*# sourceMappingURL=styles.css.map */