.contact {
  width: 100%;
  max-width: 1150px;
  margin: auto;
  height: auto;
  background-image: url("../../components/img/bg.jpg");
  margin-bottom: 100px;
  background-size: cover;
  display: flex;
  padding: 0;
  border-radius: 50px;
  position: relative;
}
@media screen and (max-width: 1450px) {
  .contact {
    max-width: unset;
    border-radius: 0px;
  }
}
@media screen and (max-width: 600px) {
  .contact {
    margin-bottom: 30px;
    padding-top: 30px;
  }
}
.contact .move_graph {
  position: absolute;
  right: 4vw;
  top: 92px;
  z-index: 9;
  pointer-events: none;
}
@media screen and (max-width: 600px) {
  .contact .move_graph {
    right: 25px;
    top: 120px;
  }
}
.contact .move_graph img {
  position: relative;
  width: 90%;
}
@media screen and (max-width: 600px) {
  .contact .move_graph img {
    width: 100px;
  }
}
.contact .move_graph2 {
  position: absolute;
  left: 4vw;
  z-index: 9;
  bottom: -94px;
  pointer-events: none;
}
@media screen and (max-width: 600px) {
  .contact .move_graph2 {
    left: 25px;
    bottom: -40px;
  }
}
.contact .move_graph2 img {
  position: relative;
  width: 90%;
}
@media screen and (max-width: 600px) {
  .contact .move_graph2 img {
    width: 80px;
  }
}
.contact .contact_container {
  position: relative;
  max-width: 1150px;
  width: 100%;
}
.contact .contact_container .contact_handle {
  width: 10px;
  height: 10px;
  position: absolute;
  top: -114px;
}
.contact .contact_container .contact_grid {
  display: grid !important;
  grid-template-columns: 1.3fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: ". .";
  padding: 50px;
}
@media screen and (max-width: 1450px) {
  .contact .contact_container .contact_grid {
    padding: 25px;
  }
}
@media screen and (max-width: 1150px) {
  .contact .contact_container .contact_grid {
    grid-template-columns: 1fr;
    grid-template-areas: ".";
  }
}
.contact .contact_container .contact_grid .contact_title {
  display: flex;
  flex-direction: column;
  font-family: "Antonio", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-size: 80px;
  text-transform: uppercase;
  line-height: 1.1;
  margin-bottom: 50px;
  color: #fff;
}
.contact .contact_container .contact_grid .contact_title .green {
  color: #00FFC4;
}
@media screen and (max-width: 1150px) {
  .contact .contact_container .contact_grid .contact_title {
    font-size: 50px;
  }
}
@media screen and (max-width: 600px) {
  .contact .contact_container .contact_grid .contact_title {
    margin-bottom: 30px;
  }
}
.contact .contact_container .contact_grid .contact_form {
  color: #fff;
  display: flex !important;
  flex-direction: column;
}
.contact .contact_container .contact_grid .contact_form input {
  background: transparent;
  color: #fff;
  border: 0;
  border-bottom: 2px solid transparent;
  -o-border-image: linear-gradient(to right, #FF38AB, #534BFF);
     border-image: linear-gradient(to right, #FF38AB, #534BFF);
  border-image-slice: 1;
  padding: 20px;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: bold;
  outline: 0;
  transition: all ease 0.2s;
}
.contact .contact_container .contact_grid .contact_form input::-moz-placeholder {
  color: #fff;
}
.contact .contact_container .contact_grid .contact_form input::placeholder {
  color: #fff;
}
@media screen and (min-width: 820px) and (max-width: 820px) and (min-height: 1180px) and (max-height: 1180px) {
  .contact .contact_container .contact_grid .contact_form input {
    max-width: unset;
  }
}
@media screen and (max-width: 600px) {
  .contact .contact_container .contact_grid .contact_form input {
    padding: 20px 0;
  }
}
.contact .contact_container .contact_grid .contact_form textarea {
  margin-top: 20px;
  background: transparent;
  color: #fff;
  border: 0;
  padding: 20px;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: bold;
  outline: 0;
  width: 100%;
  min-height: 120px;
  max-height: 250px;
  max-width: 456px;
  margin-bottom: 20px;
  resize: none;
}
.contact .contact_container .contact_grid .contact_form textarea::-moz-placeholder {
  color: #fff;
}
.contact .contact_container .contact_grid .contact_form textarea::placeholder {
  color: #fff;
}
@media screen and (max-width: 600px) {
  .contact .contact_container .contact_grid .contact_form textarea {
    padding: 20px 0;
  }
}
.contact .contact_container .contact_grid .contact_form textarea::-webkit-scrollbar {
  width: 4px;
  height: 12px;
  padding-bottom: 5px;
}
.contact .contact_container .contact_grid .contact_form textarea::-webkit-scrollbar-track {
  background: transparent;
}
.contact .contact_container .contact_grid .contact_form textarea::-webkit-scrollbar-thumb {
  background: #534BFF;
  border-radius: 6px;
  cursor: pointer;
}
.contact .contact_container .contact_grid .contact_form textarea::-webkit-scrollbar-thumb:hover {
  background: #FF38AB;
}
.contact .contact_container .contact_grid .contact_form .endTextarea {
  height: 2px;
  border: 0;
  border-bottom: 2px solid transparent;
  -o-border-image: linear-gradient(to right, #FF38AB, #534BFF);
     border-image: linear-gradient(to right, #FF38AB, #534BFF);
  border-image-slice: 1;
}
.contact .contact_container .contact_grid .contact_form .contact-checkbox-info {
  margin-top: 20px;
  font-family: "Poppins", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-size: 13px;
  transition: all ease 0.2s;
  padding-left: 20px;
}
.contact .contact_container .contact_grid .contact_form .contact-checkbox-info .green {
  color: #00FFC4;
}
.contact .contact_container .contact_grid .contact_form .checkboxes {
  min-height: 130px;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  flex-direction: row;
  margin-top: 25px;
  gap: 10px;
  padding-left: 20px;
}
@media screen and (max-width: 600px) {
  .contact .contact_container .contact_grid .contact_form .checkboxes {
    padding-left: 0px;
  }
}
.contact .contact_container .contact_grid .contact_form .checkboxes p {
  font-size: 10px;
  padding: 0 20px 0 0;
  font-family: "Open Sans", sans-serif;
  margin: -4px 0 0 0;
}
@media screen and (max-width: 600px) {
  .contact .contact_container .contact_grid .contact_form .checkboxes p {
    padding: 0;
  }
}
.contact .contact_container .contact_grid .contact_form .button_row {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0 20px;
  margin-top: 30px;
  gap: 60px;
}
@media screen and (max-width: 600px) {
  .contact .contact_container .contact_grid .contact_form .button_row {
    padding: 0;
    justify-content: flex-end !important;
    margin: 30px 0;
  }
}
.contact .contact_container .contact_grid .contact_form .button_row .btn {
  width: 200px;
  position: relative;
}
.contact .contact_container .contact_grid .contact_form .button_row .btn:disabled {
  opacity: 0.9;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: rgb(255, 255, 255);
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

.modal-container {
  background-color: rgba(0, 0, 0, 0.24);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  transition: all cubic-bezier(0.2, 0.68, 0.58, 1) 0.7s;
}
.modal-container .modal {
  width: 100%;
  max-width: 874px;
  height: -moz-min-content;
  height: min-content;
  min-height: 457px;
  background-color: #000;
  border: 2px solid #00FFC4;
  display: flex;
  gap: 15px;
  position: fixed;
  background: #000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  transition: all cubic-bezier(0.2, 0.68, 0.58, 1) 0.5s;
}
@media screen and (max-width: 921px) {
  .modal-container .modal {
    width: calc(100% - 40px);
  }
}
.modal-container .modal img {
  width: 131px;
}
.modal-container .modal h2 {
  font-size: 80px;
  padding: 0;
  margin: 20px 0 0 0;
}
@media screen and (max-width: 921px) {
  .modal-container .modal h2 {
    font-size: 50px;
  }
}
.modal-container .modal h3 {
  font-size: 40px;
  padding: 0;
  margin: 0;
  color: #fff;
  font-family: "Antonio", sans-serif;
  font-weight: bold;
  font-style: normal;
}
@media screen and (max-width: 921px) {
  .modal-container .modal h3 {
    font-size: 25px;
  }
}
.modal-container .modal button {
  position: absolute;
  top: 0;
  right: 0;
  color: #00FFC4;
  background-color: transparent;
  width: 40px;
  height: 40px;
  font-size: 22px;
  border: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.modal-container .modal.off {
  visibility: hidden;
  opacity: 0;
  top: -100px;
}
.modal-container .modal.on {
  visibility: visible;
  opacity: 1;
  top: 0 !important;
}

.modal-container.off {
  visibility: hidden;
  opacity: 0;
}

.modal-container.on {
  visibility: visible;
  opacity: 1;
}/*# sourceMappingURL=style.css.map */