.page-footer{
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  @media screen and (max-width: 850px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 0px;
    gap: 20px;
  }
  button{
    position: relative;
    &:hover{
      color: #000;
    }
  }
  .btn-normal{
    color: #000;
  }
  .btn-dark{
    background-color: #000;
  }
}