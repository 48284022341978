.effect {
  background-color: black;
  height: auto;
  min-height: 550px;
  padding-bottom: 50px;
}
@media screen and (max-width: 600px) {
  .effect {
    padding-bottom: 20px;
  }
}
.effect .effect-container {
  width: 100%;
  max-width: 1150px;
  margin: auto;
  color: #fff;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
}
.effect .effect-container h2 {
  font-size: 90px;
  margin: 0;
  padding: 0 0 80px 0;
  text-transform: uppercase;
}
@media screen and (max-width: 850px) {
  .effect .effect-container h2 {
    padding: 0 0 40px 0;
    text-align: center;
    font-size: 60px;
  }
}
@media screen and (max-width: 600px) {
  .effect .effect-container h2 {
    padding: 0 0 60px 0;
  }
}
.effect .effect-container .effects-points-row {
  display: flex;
  flex-direction: row;
  gap: 5vw;
  justify-content: center;
  align-items: flex-start;
}
@media screen and (max-width: 850px) {
  .effect .effect-container .effects-points-row {
    flex-direction: column;
    align-items: center;
    margin-bottom: 70px;
  }
}
@media screen and (max-width: 600px) {
  .effect .effect-container .effects-points-row {
    gap: 50px;
  }
}
.effect .effect-container .effects-points-row .effect-point {
  max-width: 290px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}
@media screen and (max-width: 600px) {
  .effect .effect-container .effects-points-row .effect-point {
    gap: 20px;
  }
}
.effect .effect-container .effects-points-row .effect-point img {
  max-height: 82px !important;
}
@media screen and (max-width: 600px) {
  .effect .effect-container .effects-points-row .effect-point img {
    max-height: unset;
    max-width: 78px !important;
  }
}
.effect .effect-container .effects-points-row .effect-point b {
  display: flex;
  flex-direction: column;
  font-size: 19px;
}
@media screen and (max-width: 850px) {
  .effect .effect-container .effects-points-row .effect-point b {
    font-size: 16px;
  }
}/*# sourceMappingURL=style.css.map */