.cs_baner_ikea {
  background-image: url("./img/baner.png");
  background-position: center top;
  width: 100%;
  min-height: calc(100vh - 125px);
  display: flex;
  background-size: cover;
}
@media screen and (max-width: 1520px) {
  .cs_baner_ikea {
    background-position: center top;
  }
}
@media screen and (max-width: 1200px) {
  .cs_baner_ikea {
    background-position: center top;
  }
}
@media screen and (max-width: 600px) {
  .cs_baner_ikea {
    background-image: url("./img/baner_mobile_2.jpg");
    background-position: center top;
  }
}
@media screen and (max-width: 445px) {
  .cs_baner_ikea {
    background-size: 138%;
    background-repeat: no-repeat;
    background-color: #059f87;
  }
}
.cs_baner_ikea .cs_baner_container {
  display: flex;
  width: 100%;
  max-width: 1150px;
  margin: auto;
  flex-direction: column;
  align-items: flex-start;
}
@media screen and (max-width: 1400px) {
  .cs_baner_ikea .cs_baner_container {
    width: 100%;
    padding: 25px;
    justify-content: flex-end;
    margin: 0;
  }
}
@media screen and (max-width: 600px) {
  .cs_baner_ikea .cs_baner_container img {
    max-width: 150px;
  }
}
.cs_baner_ikea .cs_baner_container h2 {
  font-size: 80px;
  margin: 10px 0 0 0;
  text-transform: uppercase;
  line-height: 1.16;
}
@media screen and (max-width: 600px) {
  .cs_baner_ikea .cs_baner_container h2 {
    font-size: clamp(55px, 8vw, 70px);
    text-shadow: 4px 2px #21ab9b;
    color: #fff;
  }
}
.cs_baner_ikea .cs_baner_container h5 {
  font-size: 31px;
  margin: 10px 0 0 0;
  font-weight: 400;
}
@media screen and (max-width: 1400px) {
  .cs_baner_ikea .cs_baner_container h5 {
    font-size: clamp(25px, 3vw, 31px);
    color: #fff;
    font-size: 19px;
  }
}

.cs_black_space {
  background-color: black;
  height: 56px;
  width: 100%;
}

.cs_container_1150_ikea {
  width: 100%;
  max-width: 1150px;
  margin: 0 auto;
  padding: 50px 0;
}
@media screen and (max-width: 1366px) {
  .cs_container_1150_ikea {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.cs_container_1150_ikea .challenge h2.title {
  font-size: 90px;
  margin-top: 20px;
}
@media screen and (max-width: 600px) {
  .cs_container_1150_ikea .challenge h2.title {
    text-align: left;
    font-size: 60px;
    margin-top: 0;
  }
}
.cs_container_1150_ikea .challenge .cs_poranek_row {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: ".";
  gap: 50px;
  position: relative;
  align-items: start;
}
@media screen and (max-width: 1170px) {
  .cs_container_1150_ikea .challenge .cs_poranek_row {
    grid-template-columns: 1fr;
    grid-template-areas: ".";
  }
}
.cs_container_1150_ikea .challenge .cs_poranek_row .cs_bullets {
  display: flex;
  gap: 50px;
  width: 100%;
}
@media screen and (max-width: 1110px) {
  .cs_container_1150_ikea .challenge .cs_poranek_row .cs_bullets {
    flex-direction: column;
  }
}
@media screen and (max-width: 600px) {
  .cs_container_1150_ikea .challenge .cs_poranek_row .cs_bullets {
    gap: 20px;
  }
}
.cs_container_1150_ikea .challenge .cs_poranek_row .cs_bullets .cs_bullets_box {
  padding: 40px 40px 40px 52px;
  border-radius: 25px;
  box-shadow: inset 10px 10px 18px rgba(0, 0, 0, 0.24);
  position: relative;
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
}
@media screen and (max-width: 600px) {
  .cs_container_1150_ikea .challenge .cs_poranek_row .cs_bullets .cs_bullets_box {
    padding: 25px 25px 25px 33px;
    font-size: 15px;
    line-height: 1.4;
  }
  .cs_container_1150_ikea .challenge .cs_poranek_row .cs_bullets .cs_bullets_box br {
    display: none;
  }
}
.cs_container_1150_ikea .challenge .cs_poranek_row .cs_bullets .cs_bullets_box img {
  position: absolute;
  left: -21px;
  right: auto;
  margin: auto;
  top: 0;
  bottom: 0;
  max-width: 45px;
}
.cs_container_1150_ikea .challenge .cs_poranek_row .cs_poranki_img {
  display: flex;
  gap: 10px;
  position: relative;
}
@media screen and (max-width: 1170px) {
  .cs_container_1150_ikea .challenge .cs_poranek_row .cs_poranki_img {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.cs_container_1150_ikea .challenge .cs_poranek_row .cs_poranki_img img {
  width: 100%;
  height: auto;
}
@media screen and (max-width: 1666px) {
  .cs_container_1150_ikea .challenge .cs_poranek_row .cs_poranki_img img {
    max-width: 270px;
  }
}
@media screen and (max-width: 1422px) {
  .cs_container_1150_ikea .challenge .cs_poranek_row .cs_poranki_img img {
    max-width: 250px;
  }
}
@media screen and (max-width: 1322px) {
  .cs_container_1150_ikea .challenge .cs_poranek_row .cs_poranki_img img {
    max-width: 200px;
  }
}
.cs_container_1150_ikea .realization {
  margin-top: 50px;
}
@media screen and (max-width: 600px) {
  .cs_container_1150_ikea .realization {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: -30vw;
  }
}
.cs_container_1150_ikea .realization .realization-points {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 50px;
  grid-template-areas: ". .";
  font-family: "Poppins", sans-serif;
  margin-bottom: -10vw;
}
@media screen and (max-width: 966px) {
  .cs_container_1150_ikea .realization .realization-points {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: -30vw;
  }
}
@media screen and (max-width: 600px) {
  .cs_container_1150_ikea .realization .realization-points {
    gap: 30px;
  }
}
.cs_container_1150_ikea .realization .realization-points .simple-flex {
  display: flex;
  flex-direction: column;
  gap: 50px;
}
@media screen and (max-width: 600px) {
  .cs_container_1150_ikea .realization .realization-points .simple-flex {
    gap: 30px;
  }
}
.cs_container_1150_ikea .realization .realization-points .baner_event img {
  width: 100%;
  position: relative;
  z-index: -1;
}
.cs_container_1150_ikea .realization .realization-points .points_column {
  display: flex;
  gap: 35px;
  flex-direction: column;
  font-size: 20px;
}
.cs_container_1150_ikea .realization .realization-points .points_column h2.title {
  font-size: 90px;
  color: #000;
  padding-top: 100px;
  margin: 0;
}
@media screen and (max-width: 600px) {
  .cs_container_1150_ikea .realization .realization-points .points_column h2.title {
    text-align: left;
    font-size: 60px;
    padding-top: 30px;
  }
}
.cs_container_1150_ikea .realization .realization-points .points_column .point {
  display: flex;
  gap: 20px;
  align-items: flex-start;
}
@media screen and (max-width: 600px) {
  .cs_container_1150_ikea .realization .realization-points .points_column .point {
    gap: 5px;
    font-size: 14px;
  }
}
@media screen and (max-width: 600px) {
  .cs_container_1150_ikea .realization .realization-points .points_column .point .point-bullet {
    width: 52px;
    position: relative;
    left: -3px;
  }
}
.cs_container_1150_ikea .realization .realization-points .points_column .point .points-rows {
  display: flex;
  flex-direction: column;
}
.cs_container_1150_ikea .realization .realization-points .points_column .point .points-rows .hero {
  font-size: 20px;
  padding: 20px;
}
@media screen and (max-width: 600px) {
  .cs_container_1150_ikea .realization .realization-points .points_column .point .points-rows .hero {
    padding: 0px;
  }
}
.cs_container_1150_ikea .realization .realization-points .points_column .point .realization-points-points {
  display: flex;
  flex-direction: column;
  position: relative;
}
.cs_container_1150_ikea .realization .realization-points .points_column .point .realization-points-points .subpoints {
  display: flex;
  flex-direction: column;
  padding: 20px;
}
@media screen and (max-width: 600px) {
  .cs_container_1150_ikea .realization .realization-points .points_column .point .realization-points-points .subpoints {
    padding: 10px 0px;
  }
}
.cs_container_1150_ikea .realization .realization-points .points_column .point .realization-points-points .subpoints p {
  padding: 0;
  margin: 0 0 20px 0;
}
.cs_container_1150_ikea .carusele_poranki .carouselSlide img {
  width: calc(100% - 5px);
  height: auto;
  padding-right: 5px;
}
.cs_container_1150_ikea .carusele_poranki .carusele-btns {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 20px;
}
.cs_container_1150_ikea .carusele_poranki .carusele-btns button {
  position: relative;
  color: #000;
  width: 40px;
  height: 40px;
  padding: 0;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cs_container_1150_ikea .carusele_poranki .carusele-btns button:hover {
  color: #000;
}/*# sourceMappingURL=styles.css.map */