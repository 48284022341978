.products {
  width: 100%;
  height: auto;
  background-image: url("../../components/img/bg.jpg");
  margin-bottom: 80px;
  background-size: cover;
  display: flex;
  padding: 50px 0;
  position: relative;
}
@media screen and (max-width: 1366px) {
  .products {
    padding-bottom: 30px;
    margin-bottom: 30px;
  }
}
.products .product_container {
  position: relative;
  max-width: 1150px;
  width: 100%;
  margin: 0 auto;
  padding: 0 0 50px 0;
}
@media screen and (max-width: 1366px) {
  .products .product_container {
    padding: 25px;
  }
}
.products .product_container .cube {
  position: absolute;
  right: 0;
  top: -125px;
}
@media screen and (max-width: 1366px) {
  .products .product_container .cube {
    display: none;
  }
}
.products .product_container .product_title {
  display: flex;
  flex-direction: column;
  font-family: "Antonio", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-size: 90px;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 50px;
  color: #fff;
}
.products .product_container .product_title .green {
  color: #00FFC4;
}
@media screen and (max-width: 600px) {
  .products .product_container .product_title {
    font-size: 50px;
    margin-top: -20px;
    margin-bottom: 20px;
  }
}
.products .product_container .product_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 50px;
  grid-template-areas: ". ." ". .";
}
@media screen and (max-width: 600px) {
  .products .product_container .product_grid {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 20px;
    grid-template-areas: ".";
  }
}
.products .product_container .product_grid .product_item .product_item_icon {
  margin-bottom: -5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 1.2;
  gap: 20px;
}
.products .product_container .product_grid .product_item .product_item_icon b {
  display: inline-block;
  width: 100%;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 25px;
  color: #fff;
}
@media screen and (max-width: 600px) {
  .products .product_container .product_grid .product_item .product_item_icon b {
    line-height: 28px;
    padding: 0;
  }
}
.products .product_container .product_grid .product_item .product_item_txt {
  color: #fff;
  border-radius: 12px;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
}
@media screen and (max-width: 600px) {
  .products .product_container .product_grid .product_item .product_item_txt {
    background-color: transparent;
    padding: 0px;
    font-size: 15px;
  }
}
.products .product_container .product_grid .product_item .product_item_txt b {
  display: inline-block;
  width: 100%;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 25px;
  line-height: 1.1;
  margin-bottom: 10px;
}
@media screen and (max-width: 600px) {
  .products .product_container .product_grid .product_item .product_item_txt b {
    line-height: 28px;
    padding: 10px 0;
  }
}/*# sourceMappingURL=style.css.map */