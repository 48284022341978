.social{
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 2px !important;

  a{
    color: #fff;
    font-size: 23px !important;
    transition: all ease .55s;
    &:hover{
      color: #00FFC4;
    }
  }
}

.sticky-navbar-nav{
  .social{
    a{
      font-size: 20px !important;
    }
  }
}