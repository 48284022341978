@media screen and (max-width: 600px) {
  .content1150_casestudies.mobile {
    padding: 0;
  }
}
.content1150_casestudies.mobile .casestudies {
  padding-bottom: 0;
}
@media screen and (max-width: 600px) {
  .content1150_casestudies.mobile .casestudies .casestudies_lead {
    padding: 0 25px;
    font-size: 28px;
    font-weight: 500;
  }
}
@media screen and (max-width: 600px) {
  .content1150_casestudies.mobile .casestudies .casestudies_bullets .casestudies_bullets_main {
    background-color: #000;
    border-radius: 0;
    padding: 10px 25px 20px 25px;
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 600px) {
  .content1150_casestudies.mobile .casestudies .casestudies_bullets .casestudies_bullets_main .blink {
    display: none;
  }
}
.content1150_casestudies.mobile .casestudies .casestudies_bullets .casestudies_bullets_main .casestudies_bullets_main_points {
  align-items: flex-start !important;
}
.content1150_casestudies.mobile .casestudies .casestudies_bullets .casestudies_bullets_main .casestudies_bullets_main_points .bullet_title {
  font-size: 28px;
  line-height: 1.2;
  padding: 0;
}
@media screen and (max-width: 600px) {
  .content1150_casestudies.mobile .casestudies .casestudies_bullets .casestudies_bullets_shadow {
    display: none;
  }
}

.content1150_casestudies {
  width: 100%;
  max-width: 1150px;
  margin: auto;
  font-family: "Poppins", sans-serif;
}
@media screen and (max-width: 1470px) {
  .content1150_casestudies {
    width: 100%;
    padding: 0 25px;
  }
}
.content1150_casestudies .casestudies {
  padding-bottom: 80px;
  position: relative;
}
.content1150_casestudies .casestudies .casestudies_title {
  font-family: "Antonio", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-size: 90px;
  text-transform: uppercase;
  margin-bottom: 5px;
  display: flex;
  margin-top: 2.5cm;
  gap: 5px;
}
@media screen and (max-width: 600px) {
  .content1150_casestudies .casestudies .casestudies_title {
    font-size: 50px;
    gap: 0px;
    margin-top: 15px;
  }
}
.content1150_casestudies .casestudies .casestudies_title .cs_holder {
  width: 10px;
  height: 10px;
  position: absolute;
  background: transparent;
  top: 70px;
}
@media screen and (max-width: 600px) {
  .content1150_casestudies .casestudies .casestudies_title img {
    max-width: 64px;
    position: relative;
    top: 3px;
  }
}
.content1150_casestudies .casestudies .casestudies_title .casestudies_icon {
  max-width: 104px;
  position: relative;
  top: 14px;
}
@media screen and (max-width: 600px) {
  .content1150_casestudies .casestudies .casestudies_title .casestudies_icon {
    max-width: 62px;
    top: 6px;
    left: -1px;
  }
}
.content1150_casestudies .casestudies .info {
  font-size: 25px;
  margin-top: -10px;
}
@media screen and (max-width: 600px) {
  .content1150_casestudies .casestudies .info {
    font-size: 20px;
    line-height: 25px;
    margin-top: 5px;
  }
}
.content1150_casestudies .casestudies .casestudies_lead {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 50px;
  display: flex;
  flex-direction: column;
  line-height: 1.3;
  margin-bottom: 15px;
}
@media screen and (max-width: 1024px) {
  .content1150_casestudies .casestudies .casestudies_lead {
    font-size: 30px;
  }
}
.content1150_casestudies .casestudies .samolot {
  top: 0px;
  right: 0;
  position: absolute;
  pointer-events: none;
}
.content1150_casestudies .casestudies .samolot img {
  width: 95px;
}
@media screen and (max-width: 600px) {
  .content1150_casestudies .casestudies .samolot img {
    max-width: 120px;
  }
}
@media screen and (max-width: 600px) {
  .content1150_casestudies .casestudies .samolot {
    top: -126px;
    right: 4px;
    left: auto;
    position: absolute;
    pointer-events: none;
  }
}
.content1150_casestudies .casestudies .casestudies_bullets {
  position: relative;
}
.content1150_casestudies .casestudies .casestudies_bullets .casestudies_bullets_main {
  position: relative;
  z-index: 0;
  background-color: #000;
  border-radius: 13px;
  min-height: 74px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
}
.content1150_casestudies .casestudies .casestudies_bullets .casestudies_bullets_main .blink {
  background-image: url("./img/reflec.png");
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 50%, 0% 100%);
          clip-path: polygon(0 0, 100% 0, 100% 50%, 0% 100%);
  width: 62px;
  height: 67px;
  position: absolute;
  left: 0;
  background-repeat: no-repeat;
  top: 0;
}
.content1150_casestudies .casestudies .casestudies_bullets .casestudies_bullets_main .casestudies_bullets_main_content {
  display: flex;
  gap: 30px;
  justify-content: space-between;
  width: 100%;
  text-decoration: none;
  color: #fff;
}
@media screen and (max-width: 1024px) {
  .content1150_casestudies .casestudies .casestudies_bullets .casestudies_bullets_main .casestudies_bullets_main_content {
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 20px 0;
    gap: 50px;
  }
}
.content1150_casestudies .casestudies .casestudies_bullets .casestudies_bullets_main .casestudies_bullets_main_content .casestudies_bullets_main_points {
  position: relative;
  z-index: 2;
  display: flex;
  gap: 10px;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: bold;
  align-items: center !important;
  justify-content: space-between;
  width: 100%;
}
@media screen and (max-width: 1024px) {
  .content1150_casestudies .casestudies .casestudies_bullets .casestudies_bullets_main .casestudies_bullets_main_content .casestudies_bullets_main_points {
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .content1150_casestudies .casestudies .casestudies_bullets .casestudies_bullets_main .casestudies_bullets_main_content .casestudies_bullets_main_points {
    flex-direction: column;
    align-items: flex-end !important;
    gap: 15px;
  }
}
.content1150_casestudies .casestudies .casestudies_bullets .casestudies_bullets_main .casestudies_bullets_main_content .casestudies_bullets_main_points .casestudies_bullets_main_points_row {
  display: flex;
  align-items: center;
  gap: 17px;
}
@media screen and (max-width: 600px) {
  .content1150_casestudies .casestudies .casestudies_bullets .casestudies_bullets_main .casestudies_bullets_main_content .casestudies_bullets_main_points .casestudies_bullets_main_points_row {
    width: 100%;
  }
}
.content1150_casestudies .casestudies .casestudies_bullets .casestudies_bullets_main .casestudies_bullets_main_content .casestudies_bullets_main_points .bullet_title {
  line-height: 0.85;
  font-size: clamp(20px, 3vw, 41px);
  font-family: "Poppins", sans-serif;
}
.content1150_casestudies .casestudies .casestudies_bullets .casestudies_bullets_main .casestudies_bullets_main_content .casestudies_bullets_main_points .bullet_title .green {
  color: #00ffc4;
}
@media screen and (max-width: 1024px) {
  .content1150_casestudies .casestudies .casestudies_bullets .casestudies_bullets_main .casestudies_bullets_main_content .casestudies_bullets_main_points .bullet_title {
    font-size: 28px !important;
    line-height: 1.2;
  }
}
.content1150_casestudies .casestudies .casestudies_bullets .casestudies_bullets_shadow {
  position: absolute;
  background: rgb(122, 34, 154);
  background: linear-gradient(0deg, rgb(122, 34, 154) 0%, rgb(255, 0, 93) 100%);
  width: 100%;
  min-height: 74px;
  border-radius: 13px;
  top: 7px;
  left: 9px;
  z-index: -1;
  box-shadow: 4px 9px 13px rgba(0, 0, 0, 0.2);
}
.content1150_casestudies .casestudies .casestudies_grid {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 10px;
  grid-template-areas: ". . ." ". . .";
}
@media screen and (max-width: 1110px) {
  .content1150_casestudies .casestudies .casestudies_grid {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: ". .";
  }
}
@media screen and (max-width: 600px) {
  .content1150_casestudies .casestudies .casestudies_grid {
    grid-template-columns: 1fr;
    grid-template-areas: ".";
  }
}
.content1150_casestudies .casestudies .casestudies_grid .casesturies_item:hover .item_content {
  background-color: #d7d7d7;
}
.content1150_casestudies .casestudies .casestudies_grid .casesturies_item {
  cursor: pointer;
  transition: all ease-in-out 0.25s;
}
@media screen and (max-width: 600px) {
  .content1150_casestudies .casestudies .casestudies_grid .casesturies_item:active {
    opacity: 0.5;
  }
}
@media screen and (max-width: 600px) {
  .content1150_casestudies .casestudies .casestudies_grid .casesturies_item {
    padding: 0px;
    display: flex;
    gap: 5px;
    max-height: 115px;
  }
}
.content1150_casestudies .casestudies .casestudies_grid .casesturies_item .item_img img {
  position: relative;
  width: 100%;
  height: auto;
  cursor: pointer;
  transition: all ease-in-out 0.25s;
}
.content1150_casestudies .casestudies .casestudies_grid .casesturies_item .item_img img:hover {
  opacity: 0.7;
}
@media screen and (max-width: 600px) {
  .content1150_casestudies .casestudies .casestudies_grid .casesturies_item .item_img img {
    width: auto;
    height: 115px;
  }
}
.content1150_casestudies .casestudies .casestudies_grid .casesturies_item .item_content {
  background-color: #EDEDED;
  padding: 10px 20px 15px 20px;
  position: relative;
  transition: all ease-in-out 0.25s;
}
.content1150_casestudies .casestudies .casestudies_grid .casesturies_item .item_content:hover {
  background-color: #d7d7d7;
}
@media screen and (max-width: 600px) {
  .content1150_casestudies .casestudies .casestudies_grid .casesturies_item .item_content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 10px 10px 10px 14px;
  }
}
.content1150_casestudies .casestudies .casestudies_grid .casesturies_item .item_content .item_content_title {
  font-family: "Antonio", sans-serif;
  font-weight: 700;
  font-size: 35px;
  text-transform: uppercase;
  margin-bottom: 5px;
}
@media screen and (max-width: 600px) {
  .content1150_casestudies .casestudies .casestudies_grid .casesturies_item .item_content .item_content_title {
    font-size: 31px;
    text-transform: none;
    letter-spacing: -0.8px;
  }
}
.content1150_casestudies .casestudies .casestudies_grid .casesturies_item .item_content .item_content_txt {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  white-space: pre-wrap;
  color: #737373;
}
@media screen and (max-width: 750px) {
  .content1150_casestudies .casestudies .casestudies_grid .casesturies_item .item_content .item_content_txt {
    font-size: 12px;
    min-height: 34px;
  }
}
@media screen and (max-width: 600px) {
  .content1150_casestudies .casestudies .casestudies_grid .casesturies_item .item_content .item_content_txt {
    display: none;
  }
}
.content1150_casestudies .casestudies .casestudies_grid .casesturies_item .item_content .item_content_btn {
  position: absolute;
  top: 12px;
  right: 14px;
}
@media screen and (max-width: 600px) {
  .content1150_casestudies .casestudies .casestudies_grid .casesturies_item .item_content .item_content_btn {
    top: 0;
    right: 0;
    position: relative;
  }
}
.content1150_casestudies .casestudies .casestudies_grid .casesturies_item .item_content .item_content_btn button {
  background-color: transparent;
  border: 0;
  padding: 0;
  transition: all ease 0.2s;
  cursor: pointer;
}
@media screen and (max-width: 600px) {
  .content1150_casestudies .casestudies .casestudies_grid .casesturies_item .item_content .item_content_btn button img {
    width: 30px;
  }
}
.content1150_casestudies .casestudies .casestudies_grid .casesturies_item .item_content .item_content_btn button:hover {
  opacity: 0.7;
}/*# sourceMappingURL=style.css.map */