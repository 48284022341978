.page-footer {
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
}
@media screen and (max-width: 850px) {
  .page-footer {
    flex-direction: column;
    align-items: center;
    margin-bottom: 0px;
    gap: 20px;
  }
}
.page-footer button {
  position: relative;
}
.page-footer button:hover {
  color: #000;
}
.page-footer .btn-normal {
  color: #000;
}
.page-footer .btn-dark {
  background-color: #000;
}/*# sourceMappingURL=style.css.map */