.team {
  width: 100%;
  height: auto;
  background-image: url("../../components/img/bg.jpg");
  margin-bottom: 40px;
  background-size: cover;
  display: flex;
  padding: 40px 0 80px 0;
}
@media screen and (max-width: 900px) {
  .team {
    padding-bottom: 0px;
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 730px) {
  .team {
    padding: 0;
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 820px) and (max-width: 820px) and (min-height: 1180px) and (max-height: 1180px) {
  .team {
    padding: 0 40px;
  }
}
.team .team_container {
  position: relative;
  max-width: 1150px;
  width: 100%;
  margin: 0 auto;
}
@media screen and (max-width: 1366px) {
  .team .team_container {
    padding: 0 25px;
  }
}
@media screen and (max-width: 730px) {
  .team .team_container {
    padding-bottom: 0;
    padding-top: 0;
  }
}
.team .team_container .team_title {
  display: flex;
  flex-direction: row;
  font-family: "Antonio", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-size: 80px;
  line-height: 1;
  color: #fff;
  gap: 18px;
  align-items: center;
}
@media screen and (max-width: 1200px) {
  .team .team_container .team_title {
    margin: 30px 0;
  }
}
@media screen and (max-width: 600px) {
  .team .team_container .team_title {
    font-size: 50px;
  }
}
.team .team_container .team_title img {
  margin-right: 10px;
  position: relative;
  top: 8px;
  width: 115px;
}
@media screen and (max-width: 1200px) {
  .team .team_container .team_title img {
    width: 100px;
    top: 1px;
  }
}
.team .team_container .team_title .title-text .title-text {
  display: flex;
  gap: 10px;
}
@media screen and (max-width: 1200px) {
  .team .team_container .team_title .title-text .title-text {
    position: relative;
    top: -7px;
  }
}
.team .team_container .team_title .title-text .green {
  color: #00FFC4;
}
.team .team_container .team_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 50px;
  grid-template-areas: ". . .";
  margin: 0px 0;
  height: auto;
}
@media screen and (max-width: 830px) {
  .team .team_container .team_grid {
    gap: 20px;
  }
}
@media screen and (max-width: 730px) {
  .team .team_container .team_grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    max-width: 100%;
  }
}
@media screen and (max-width: 730px) {
  .team .team_container .team_grid {
    gap: 0px;
  }
}
@media screen and (min-width: 820px) and (max-width: 820px) and (min-height: 1180px) and (max-height: 1180px) {
  .team .team_container .team_grid {
    display: grid;
    grid-template-columns: 1fr 1fr !important;
    grid-template-areas: ". ." !important;
    padding: 0 15px;
  }
}
@media screen and (max-width: 730px) {
  .team .team_container .team_grid .team_item.rev {
    flex-direction: row-reverse;
  }
}
@media screen and (max-width: 730px) {
  .team .team_container .team_grid .team_item.rev .team_item_content {
    display: flex;
    align-items: flex-end;
    text-align: right;
  }
}
@media screen and (max-width: 730px) {
  .team .team_container .team_grid .team_item.rev .team_item_content .icon {
    left: 9px;
  }
}
.team .team_container .team_grid .team_item {
  display: flex;
  flex-direction: column;
  color: #fff;
  position: relative;
}
@media screen and (max-width: 900px) {
  .team .team_container .team_grid .team_item {
    max-width: 400px;
    width: 100%;
  }
}
@media screen and (max-width: 730px) {
  .team .team_container .team_grid .team_item {
    max-width: unset;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: flex-start;
    padding: 0 20px;
  }
}
.team .team_container .team_grid .team_item .photo {
  position: relative;
  display: flex;
}
.team .team_container .team_grid .team_item .photo .pic {
  z-index: 1;
  left: 0;
  right: 0;
  margin: auto;
  max-height: 280px;
}
@media screen and (min-width: 730px) and (max-width: 950px) {
  .team .team_container .team_grid .team_item .photo .pic {
    width: 100%;
    height: auto;
  }
}
@media screen and (max-width: 730px) {
  .team .team_container .team_grid .team_item .photo .pic {
    width: 50vw;
    max-height: unset;
    bottom: 15px;
  }
}
.team .team_container .team_grid .team_item .photo .shape {
  position: absolute;
  z-index: 0;
  max-height: 360px;
  left: 0;
  right: 0;
  margin: auto;
}
@media screen and (max-width: 730px) {
  .team .team_container .team_grid .team_item .photo .shape {
    width: 100%;
    height: 80%;
    bottom: 30px;
  }
}
.team .team_container .team_grid .team_item .team_item_content {
  padding-left: 65px;
  margin-top: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
@media screen and (max-width: 1150px) {
  .team .team_container .team_grid .team_item .team_item_content {
    display: grid;
  }
}
@media screen and (min-width: 730px) and (max-width: 950px) {
  .team .team_container .team_grid .team_item .team_item_content {
    padding-left: 0px;
  }
}
@media screen and (max-width: 730px) {
  .team .team_container .team_grid .team_item .team_item_content {
    min-height: unset;
    display: flex;
    flex-direction: column;
    gap: 0;
    padding-left: 0;
    align-items: flex-start;
  }
}
.team .team_container .team_grid .team_item .team_item_content .icon {
  position: absolute;
  width: 71px;
  left: -9px;
  top: 0px;
}
@media screen and (min-width: 730px) and (max-width: 950px) {
  .team .team_container .team_grid .team_item .team_item_content .icon {
    display: none;
  }
}
@media screen and (max-width: 730px) {
  .team .team_container .team_grid .team_item .team_item_content .icon {
    position: relative;
    left: -6px;
    top: 6px;
    width: 58px;
  }
}
.team .team_container .team_grid .team_item .team_item_content .item_title {
  font-size: 27px;
  font-family: "Antonio", sans-serif;
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: #00FFC4;
  max-height: 125px !important;
}
@media screen and (min-width: 600px) and (max-width: 1150px) {
  .team .team_container .team_grid .team_item .team_item_content .item_title {
    min-height: 125px;
  }
}
@media screen and (max-width: 730px) {
  .team .team_container .team_grid .team_item .team_item_content .item_title {
    font-size: 25px;
  }
}
.team .team_container .team_grid .team_item .team_item_content .item_title small {
  font-size: 16px;
  text-transform: uppercase;
  font-family: "Open Sans", sans-serif;
  color: #fff;
}
@media screen and (max-width: 1160px) {
  .team .team_container .team_grid .team_item .team_item_content .item_title small {
    font-size: 14px;
  }
}
@media screen and (max-width: 730px) {
  .team .team_container .team_grid .team_item .team_item_content .item_title small {
    font-size: 11px;
  }
}
.team .team_container .team_grid .team_item .team_item_content .team-grid {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  min-height: 230px;
}
@media screen and (min-width: 820px) and (max-width: 820px) and (min-height: 1180px) and (max-height: 1180px) {
  .team .team_container .team_grid .team_item .team_item_content .team-grid {
    min-height: unset;
    height: auto;
  }
}
@media screen and (max-width: 1340px) {
  .team .team_container .team_grid .team_item .team_item_content .team-grid {
    min-height: 345px;
  }
}
@media screen and (max-width: 730px) {
  .team .team_container .team_grid .team_item .team_item_content .team-grid {
    display: none;
  }
}
.team .team_container .team_grid .team_item .team_item_content .item_lead {
  font-size: 15px;
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
}
@media screen and (max-width: 1160px) {
  .team .team_container .team_grid .team_item .team_item_content .item_lead {
    font-size: 14px;
  }
}
.team .team_container .team_grid .team_item .team_item_content .item_text {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
}
.team .team_container .team_grid .team_item .team_item_content .item_btn {
  position: relative;
  margin-top: 15px;
  height: 39px;
  margin-top: auto;
}
@media screen and (max-width: 1200px) {
  .team .team_container .team_grid .team_item .team_item_content .item_btn {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 730px) {
  .team .team_container .team_grid .team_item .team_item_content .item_btn {
    display: none;
  }
}
.team .team_container .team_grid .team_item .team_item_content .item_btn button {
  display: flex;
  align-items: center;
  gap: 10px;
}
.team .team_container .team_grid .team-grid-mobile {
  display: none;
}
@media screen and (max-width: 730px) {
  .team .team_container .team_grid .team-grid-mobile {
    display: flex;
    color: #fff;
    flex-direction: column;
    padding-top: 20px;
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
.team .team_container .team_grid .team-grid-mobile .item_lead {
  font-size: 15px;
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
  padding-bottom: 10px;
}
@media screen and (max-width: 730px) {
  .team .team_container .team_grid .team-grid-mobile .item_lead {
    padding-bottom: 0px;
  }
}
@media screen and (max-width: 730px) {
  .team .team_container .team_grid .team-grid-mobile .item_lead br {
    display: none;
  }
}
.team .team_container .team_grid .team-grid-mobile .item_text {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
}
@media screen and (max-width: 730px) {
  .team .team_container .team_grid .team-grid-mobile .item_text br {
    display: none;
  }
}
.team .team_container .team_grid .team-grid-mobile .item_btn {
  position: relative;
  margin-top: 15px;
  height: 39px;
  margin-top: 20px;
  margin-bottom: 0;
}
.team .team_container .team_grid .team-grid-mobile .item_btn button {
  display: flex;
  align-items: center;
  gap: 10px;
}/*# sourceMappingURL=style.css.map */