.team{
  width: 100%;
  height: auto;
  background-image: url('../../components/img/bg.jpg');
  margin-bottom: 40px;
  background-size: cover;
  display: flex;
  padding: 40px 0 80px 0;
  @media screen and (max-width: 900px) {
    padding-bottom: 0px;
    margin-bottom: 50px;
  }
  @media screen and (max-width: 730px) {
    padding: 0;
    margin-bottom: 30px;
  }
  @media screen and (min-width: 820px) and (max-width: 820px) and (min-height: 1180px) and (max-height: 1180px) {
    padding: 0 40px;
  }


  .team_container{
    position: relative;
    max-width: 1150px;
    width: 100%;
    margin: 0 auto;

    @media screen and (max-width: 1366px) {
      padding: 0 25px;
    }


    @media screen and (max-width: 730px) {
      padding-bottom: 0;
      padding-top: 0;
    }

    .team_title{
      display: flex;
      flex-direction: row;
      font-family: "Antonio", sans-serif;
      font-optical-sizing: auto;
      font-weight: 700;
      font-style: normal;
      font-size: 80px;
      line-height: 1;
      color: #fff;
      gap: 18px;
      align-items: center;

      @media screen and (max-width: 1200px) {
        margin: 30px 0;

      }
      @media screen and (max-width: 600px) {
        font-size: 50px;
      }

      img{
        margin-right: 10px;
        position: relative;
        top: 8px;
        width: 115px;
        @media screen and (max-width: 1200px) {
          width: 100px;
          top: 1px;
        }
      }

      .title-text{
        .title-text {
          display: flex;
          gap: 10px;
          @media screen and (max-width: 1200px) {
            position: relative;
            top: -7px;
          }
        }
        .green{
          color: #00FFC4;
        }
      }
    }

    .team_grid{
      display: grid; 
      grid-template-columns: 1fr 1fr 1fr; 
      grid-template-rows: 1fr; 
      gap: 50px; 
      grid-template-areas: 
        ". . ."; 
      margin: 0px 0;
      height: auto;

      @media screen and (max-width: 830px) {
        gap: 20px;   
      }

      @media screen and (max-width: 730px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 50px;   
        max-width: 100%;
      }

      @media screen and (max-width: 730px) {
        gap: 00px;   
      }

      @media screen and (min-width: 820px) and (max-width: 820px) and (min-height: 1180px) and (max-height: 1180px) {
        display: grid;
        grid-template-columns: 1fr 1fr !important;
        grid-template-areas: 
          ". ." !important; 
        padding: 0 15px;
      }


      .team_item.rev{
        @media screen and (max-width: 730px) {
          flex-direction: row-reverse;
        }
        .team_item_content{
          @media screen and (max-width: 730px) {
            display: flex;
            align-items: flex-end;
            text-align: right;
          }
          .icon{
            @media screen and (max-width: 730px){
              left: 9px;
            }
          }  
        }
      }

      .team_item{
        display: flex;
        flex-direction: column;
        color: #fff;
        position: relative;


        @media screen and (max-width: 900px) {
          max-width: 400px;
          width: 100%;
        }
        @media screen and (max-width: 730px) {
          max-width: unset;
          width: 100%;
          display: flex;
          flex-direction: row;
          gap: 20px;
          align-items: flex-start;
          padding: 0 20px;
        }

        .photo{
          position: relative;
          display: flex;
          .pic{
            z-index: 1;
            left: 0;
            right: 0;
            margin: auto;
            max-height: 280px;
            @media screen and (min-width: 730px) and (max-width: 950px){
              width: 100%;
              height: auto;
            }
            @media screen and (max-width: 730px){
              width: 50vw;
              max-height: unset;
              bottom: 15px;
            }
          }
          .shape{
            position: absolute;
            z-index: 0;
            max-height: 360px;
            left: 0;
            right: 0;
            margin: auto;
            @media screen and (max-width: 730px){
              width: 100%;
              height: 80%;
              bottom: 30px;
            }
          }
        }

        .team_item_content{
          padding-left: 65px;
          margin-top: 20px;
          position: relative;
          display: flex;
          flex-direction: column;
          gap: 15px;

          @media screen and (max-width: 1150px){
            display: grid;
          }
          @media screen and (min-width: 730px) and (max-width: 950px){
            padding-left: 0px;
          }
          @media screen and (max-width: 730px){
            min-height: unset;
            display: flex;
            flex-direction: column;
            gap: 0;
            padding-left: 0;
            align-items: flex-start;
          }

          .icon{
            position: absolute;
            width: 71px;
            left: -9px;
            top: 0px;
            @media screen and (min-width: 730px) and (max-width: 950px){
              display: none;
            }
            @media screen and (max-width: 730px){
              position: relative;
              left: -6px;
              top: 6px;
              width: 58px;
            }
          }
          .item_title{
            font-size: 27px;
            font-family: "Antonio", sans-serif;
            display: flex;
            flex-direction: column;
            gap: 5px;
            color: #00FFC4;
            max-height: 125px !important;

            @media screen and (min-width: 600px) and (max-width: 1150px){
              min-height: 125px;
            }

            @media screen and (max-width: 730px){
              font-size: 25px;
            }
            small{
              font-size: 16px;
              text-transform: uppercase;
              font-family: "Open Sans", sans-serif;
              color: #fff;
              @media screen and (max-width: 1160px) {
                font-size: 14px;
              }
              @media screen and (max-width: 730px){
                font-size: 11px;
              }
            }
          }
          .team-grid{
            display: flex;
            flex-direction: column;
            gap: 10px;
            height: 100%;
            min-height: 230px;
            @media screen and (min-width: 820px) and (max-width: 820px) and (min-height: 1180px) and (max-height: 1180px) {
              min-height: unset;
              height: auto;
            }
            @media screen and (max-width: 1340px) {
              min-height: 345px;
            }

            @media screen and (max-width: 730px) {
              display: none;
            }
          }
          .item_lead{
            font-size: 15px;
            font-weight: bold;
            font-family: "Open Sans", sans-serif;
            @media screen and (max-width: 1160px) {
              font-size: 14px;
            }
          }
          .item_text{
            font-size: 14px;
            font-family: "Open Sans", sans-serif;
            font-weight: 300;
            @media screen and (max-width: 730px) {
              // display: none;
            }
          }
          .item_btn{
            position: relative;
            margin-top: 15px;
            height: 39px;
            margin-top: auto;

            @media screen and (max-width: 1200px) {
              margin-bottom: 50px;
            }
            @media screen and (max-width: 730px) {
              display: none;
            }
            button{
              display: flex;
              align-items: center;
              gap: 10px;
            }
          }          
        }




      }

      .team-grid-mobile{
        display: none;
        
        @media screen and (max-width: 730px){
          display: flex;
          color: #fff;
          flex-direction: column;
          padding-top: 20px;
          margin-bottom: 20px;
          padding-left: 20px;
          padding-right: 20px;
        }


        .item_lead{
          font-size: 15px;
          font-weight: bold;
          font-family: "Open Sans", sans-serif;
          padding-bottom: 10px;
          @media screen and (max-width: 730px) {
            padding-bottom: 0px;
          }   
          br{
            @media screen and (max-width: 730px) {
              display: none;
            }              
          }

        }
        .item_text{
          font-size: 14px;
          font-family: "Open Sans", sans-serif;
          font-weight: 300;
          br{
            @media screen and (max-width: 730px) {
              display: none;
            }              
          }
        }
        .item_btn{
          position: relative;
          margin-top: 15px;
          height: 39px;
          margin-top: 20px;
          margin-bottom: 0;
          button{
            display: flex;
            align-items: center;
            gap: 10px;
          }
        }

      }

    }
  }
}